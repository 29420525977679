import React, { Component } from "react";
import Script from "react-load-script";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import utils from "../../helpers/utils";
import mixConstants from "../../constants/mix-constants";
import axios from "axios";

const G_API_JS_URL = "https://apis.google.com/js/api.js";
const FILE_URL = "https://www.googleapis.com/drive/v3/files";
const DISCOVERY_DOC =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
const AUTH_SCOPE = "https://www.googleapis.com/auth/drive.readonly";
const DEFAULT_EXPORT_TYPE_MAP = {
  document: "application/pdf",
  drawing: "image/png",
  presentation: "application/pdf",
  script: "application/vnd.google-apps.script+json",
  spreadsheet:
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};
const EVENTS = {
  START_REMOTE_PULL: "START_REMOTE_PULL",
  CANCEL: "CANCEL",
  ERROR: "ERROR",
  SELECTED_FILES: "SELECTED_FILES",
  SELECTED_FILE: "SELECTED_FILES",
  UNSUPPORTED_FILE: "UNSUPPORTED_FILE",
  GOOGLE_DOCS_NOT_ALLOWED: "GOOGLE_DOCS_NOT_ALLOWED",
  UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE: "UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE",
  GOOGLE_DOCS_CONVERTED: "GOOGLE_DOCS_CONVERTED",
  GOOGLE_DOCS_CONVERSION_FAILED: "GOOGLE_DOCS_CONVERSION_FAILED",
};
const ENTER_KEY_CODE = 13;
let gdrivePicker = null;

export default class GDrive extends Component {
  constructor(props) {
    super(props);

    this.state = {
      init: true,
    };
  }

  getMimeType = (file) => {
    const { exportMimeTypeOverrides } = this.props;
    const typeSplit = file.mimeType.split(".");
    const type = typeSplit[typeSplit.length - 1].toLowerCase();

    return exportMimeTypeOverrides[type] || DEFAULT_EXPORT_TYPE_MAP[type];
  };

  convertGoogleDocToPDF = async (documentId, fetchOptions) => {
    try {
      const exportUrl = `${FILE_URL}/${documentId}/export?mimeType=application/pdf`;
      const response = await fetch(exportUrl, fetchOptions);
      if (!response.ok) {
        throw new Error("Google Docs export failed");
      }
      const pdfBlob = await response.blob();
      return pdfBlob;
    } catch (error) {
      console.error("Error converting Google Document to PDF:", error);
      return null;
    }
  };

  downloadFiles = async (data) => {
    const { onEvent, downloadSelectedFiles, exportAsBlobs } = this.props;

    if (data.action === window.google.picker.Action.CANCEL) {
      gdrivePicker = null;
      return void onEvent(EVENTS.CANCEL);
    }

    if (data.action === window.google.picker.Action.ERROR) {
      gdrivePicker = null;
      return void onEvent(EVENTS.ERROR);
    }

    if (data.action !== window.google.picker.Action.PICKED) {
      gdrivePicker = null;
      return;
    }

    const { accessToken } = this.state;
    const docs = data[window.google.picker.Response.DOCUMENTS];

    if (!exportAsBlobs) {
      gdrivePicker = null;
      return void onEvent(EVENTS.SELECTED_FILES, {
        accessToken,
        files: docs,
      });
    }

    const fetchOptions = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    if (docs && docs.length > 0) {
      for (let i = 0; i < docs.length; i++) {
        const doc = docs[i];
        const docId = docs[i].id;
        const docFileType = docs[i].type;
        const docFileName = docs[i].name;

        if (docFileType === "document") {
          try {
            const pdfBlob = this.convertGoogleDocToPDF(docId, fetchOptions);
            if (pdfBlob) {
              onEvent(EVENTS.GOOGLE_DOCS_CONVERTED, { file: doc });
              const pdfDoc = {
                ...doc,
                type: "pdf",
                name: `${docFileName}.pdf`,
              };
              docs[i] = pdfDoc;
              if (downloadSelectedFiles) {
                saveAs(pdfBlob, pdfDoc.name);
              }
            } else {
              onEvent(EVENTS.GOOGLE_DOCS_CONVERSION_FAILED, { file: doc });
            }
          } catch (error) {
            console.error("Error converting document:", error);
            onEvent(EVENTS.GOOGLE_DOCS_CONVERSION_FAILED, { file: doc });
          }
        }
      }
      const doc = docs[0];
      const fileName = doc.name;

      const sizeBytes = doc.sizeBytes;
      const sizeMB = utils.bytesToMB(sizeBytes);

      const ext = fileName.split(".").pop();
      const isSupportedFile = utils.isSupportedFile(ext);

      if (!isSupportedFile) {
        return void onEvent(EVENTS.UNSUPPORTED_FILE, {
          file: doc,
        });
      } else if (sizeMB > mixConstants.limitations.UPLOAD_FILE_SIZE) {
        return void onEvent(EVENTS.UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE, {
          file: doc,
        });
      }
    }

    gdrivePicker = null;
    onEvent(EVENTS.START_REMOTE_PULL, { filesInfo: docs });

    const blobs = (
      await Promise.all(
        docs.map(async (file) => {
          const isGoogleDoc = file.type.toLowerCase() === "pdf";

          const url = isGoogleDoc
            ? `${FILE_URL}/${file.id}/export?mimeType=application/pdf`
            : `${FILE_URL}/${file.id}?alt=media`;

          let blob = await fetch(url, fetchOptions).then((res) => res.blob());

          if (file.mimeType === "image/heif") {
            const response = await utils.convertGdriveHeicIntoJpeg(
              blob,
              file.name
            );
            if (!response) {
              return void onEvent(EVENTS.GOOGLE_DOCS_CONVERSION_FAILED, {
                file: file,
              });
            }
            blob = response.convertedBlob;
            file = response.jpegFile;
          }
          onEvent(EVENTS.SELECTED_FILE, {
            accessToken,
            file: blob,
            fileName: file.name,
            fileInfo: file,
          });

          if (downloadSelectedFiles) {
            saveAs(blob, file.name);
          }
          return blob;
        }),
      )
    ).filter(Boolean);

    gdrivePicker = null;
    onEvent(EVENTS.SELECTED_FILES, {
      accessToken,
      files: blobs,
    });
  };

  createPicker = () => {
    const { accessToken, signedIn, pickerInitialised } = this.state;
    const { multiSelect, allowSharedDrives, allowedMimeTypes, origin, apiKey } =
      this.props;

    if (!signedIn || !pickerInitialised) {
      return;
    }

    const defaultView = new window.google.picker.DocsView()
      .setIncludeFolders(true)
      .setOwnedByMe(true);

    if (allowedMimeTypes) {
      defaultView.setMimeTypes(allowedMimeTypes.join(","));
    }

    let picker = gdrivePicker;
    if (picker === null) {
      picker = new window.google.picker.PickerBuilder()
        .addView(defaultView)
        .setOAuthToken(accessToken)
        .setDeveloperKey(apiKey)
        .setCallback(this.downloadFiles);
      gdrivePicker = picker;
    } else {
      return;
    }

    if (multiSelect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
    }

    if (origin) {
      picker.setOrigin(origin);
    }

    if (allowSharedDrives) {
      const sharedDriveView =
        new window.google.picker.DocsView().setEnableDrives(true);

      if (allowedMimeTypes) {
        sharedDriveView.setMimeTypes(allowedMimeTypes.join(","));
      }

      picker.addView(sharedDriveView);
    } else {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);
    }

    picker.build().setVisible(true);
  };

  handleSelectFiles = () => {
    const { signedIn } = this.state;

    if (signedIn) {
      return void this.createPicker();
    }

    window.gapi.auth2.getAuthInstance().signIn();
  };

  updateSignInStatus = (newSignedIn) => {
    const { signedIn, init } = this.state;

    if (!init && newSignedIn && !signedIn) {
      this.setState(
        {
          signedIn: true,
        },
        () => {
          if (window && window.gapi) {
            window.gapi.load("auth2", this.initAuth);
          }
          setTimeout(() => {
            this.createPicker();
          }, 1000);
        },
      );

      return; //void this.setState({ signedIn: true }, this.createPicker());
    }

    const accessToken = newSignedIn
      ? window.gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse()
          .access_token
      : void 0;

    this.setState({
      signedIn: newSignedIn,
      init: false,
      accessToken,
    });
  };

  initClient = () => {
    const { apiKey, clientId } = this.props;

    window.gapi.client.init({
      apiKey,
      clientId,
      discoveryDocs: [DISCOVERY_DOC],
      scope: AUTH_SCOPE,
    });
  };

  initAuth = async () => {
    const { clientId } = this.props;

    await window.gapi.auth2.init({
      client_id: clientId,
      scope: AUTH_SCOPE,
      immediate: false,
    });

    window.gapi.auth2
      .getAuthInstance()
      .isSignedIn.listen(this.updateSignInStatus);

    this.updateSignInStatus(
      window.gapi.auth2.getAuthInstance().isSignedIn.get(),
    );
  };

  initPicker = () => {
    this.setState({ pickerInitialised: true });
  };

  onScriptLoad = () => {
    if (window && window.gapi) {
      window.gapi.load("auth2", this.initAuth);
      window.gapi.load("client", this.initClient);
      window.gapi.load("picker", this.initPicker);
    }
  };

  handleKeyPress({ keyCode }) {
    if (keyCode !== ENTER_KEY_CODE) {
      return;
    }

    this.handleSelectFiles();
  }

  render() {
    const { children, injectOnClick } = this.props;

    if (!injectOnClick) {
      return (
        <div
          onKeyPress={this.handleKeyPress}
          role='button'
          tabIndex={0}
          onClick={this.handleSelectFiles}
        >
          <Script url={G_API_JS_URL} onLoad={this.onScriptLoad} />
          {children}
        </div>
      );
    }

    return (
      <>
        <Script url={G_API_JS_URL} onLoad={this.onScriptLoad} />
        {React.cloneElement(children, {
          onClick: this.handleSelectFiles,
        })}
      </>
    );
  }
}

GDrive.propTypes = {
  children: PropTypes.node.isRequired,
  clientId: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  exportMimeTypeOverrides: {
    document: PropTypes.string,
    drawing: PropTypes.string,
    presentation: PropTypes.string,
    script: PropTypes.string,
    spreadsheet: PropTypes.string,
  },
  origin: PropTypes.string,
  onEvent: PropTypes.func.isRequired,
  multiSelect: PropTypes.bool,
  injectOnClick: PropTypes.bool,
  allowSharedDrives: PropTypes.bool,
  allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  exportAsBlobs: PropTypes.bool,
  downloadSelectedFiles: PropTypes.bool,
};

GDrive.defaultProps = {
  multiSelect: true,
  injectOnClick: true,
  allowSharedDrives: true,
  exportAsBlobs: true,
  downloadSelectedFiles: false,
  exportMimeTypeOverrides: {},
};
