import config from "./api-config";
import authHeader from "../helpers/auth-header";
import authApi from "./auth-api";
import loggedInUserHelper from "../helpers/logged-in-user-helper";
import axios from "axios";

const footPrintsApi = {
  fetchCurrentUserFootPrints,
  fetchFootPrint,
  uploadFootprint,
  createFootPrint,
  updateFootPrint,
  fetchSearchedFootPrints,
  deleteFootPrint,
};

/**
 *  Fetch current user foot prints
 */

async function fetchCurrentUserFootPrints(page, pageSize, classesOrGroupsId) {
  const header = authHeader();
  const requestOptions = {
    method: "GET",
    headers: header,
  };

  const userId = loggedInUserHelper.id();
  let url = ``;

  if (!classesOrGroupsId) {
    url = `${config.BaseURL}/api/post/?user_id=${userId}&page=${page}&page_size=${pageSize}`;
  } else {
    url = `${config.BaseURL}/api/post/?user_id=${userId}&class_ids=${classesOrGroupsId}&page=${page}&page_size=${pageSize}`;
  }
  const response = await fetch(url, requestOptions);
  const footPrints = await handleResponse(response);
  return footPrints;
}

/**
 *  Fetch foot print
 */

async function fetchFootPrint(footprintId) {
  const header = authHeader();
  const requestOptions = {
    method: "GET",
    headers: header,
  };

  const url = `${config.BaseURL}/api/post/${footprintId}`;

  const response = await fetch(url, requestOptions);
  const footPrint = await handleResponse(response);
  return footPrint;
}

/**
 *  Upload foot print
 */

async function uploadFootprint(footprintFile, fileId, userIndex, callback) {
  const formData = new FormData();

  if (footprintFile !== "") {
    formData.append(
      "faith_footprint",
      footprintFile.file,
      footprintFile.fileName
    );
  }
  const header = authHeader();

  let callBackResponse = {
    uploadPercentage: null,
    error: null,
    data: null,
    fileId: fileId,
    userIndex: userIndex,
  };

  await axios({
    baseURL: config.BaseURL,
    url: "/api/faith_footprint/",
    headers: header,
    method: "post",
    data: formData,
    onUploadProgress: (progress) => {
      const { loaded, total } = progress;
      const percentageProgress = Math.floor((loaded / total) * 100);

      callBackResponse.uploadPercentage = percentageProgress;
      callback(callBackResponse);
    },
  })
    .then(function (response) {
      if (response && response.error) {
        callBackResponse.error = response.error;
        callback(callBackResponse);
      }
      if (response && response.data) {
        callBackResponse.data = response.data;
        callback(callBackResponse);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

/**
 *  Create foot print
 */

async function createFootPrint(footPrint) {
  const header = authHeader();

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(footPrint),
  };

  const url = `${config.BaseURL}/api/post/`;
  const response = await fetch(url, requestOptions);
  const footPrintObj = await handleResponse(response);

  return footPrintObj;
}

/**
 *  Update foot print
 */

async function updateFootPrint(footPrintToUpdate, footPrintId) {
  const header = authHeader();

  const requestOptions = {
    method: "PATCH",
    headers: header,
    body: JSON.stringify(footPrintToUpdate),
  };

  const url = `${config.BaseURL}/api/post/${footPrintId}/`;
  const response = await fetch(url, requestOptions);
  const updatedFootPrint = await handleResponse(response);

  return updatedFootPrint;
}

/**
 *  Fetch searched foot prints
 */

async function fetchSearchedFootPrints(searchQuery, page, pageSize) {
  const header = authHeader();
  const requestOptions = {
    method: "GET",
    headers: header,
  };

  const url = `${config.BaseURL}/api/post/?query=${searchQuery}&page=${page}&page_size=${pageSize}`;

  const response = await fetch(url, requestOptions);
  const footPrints = await handleResponse(response);
  return footPrints;
}

/**
 *  Delete foot print
 */

async function deleteFootPrint(footPrintId) {
  const header = authHeader();

  const requestOptions = {
    method: "DELETE",
    headers: header,
  };

  const url = `${config.BaseURL}/api/post/${footPrintId}/`;
  const response = await fetch(url, requestOptions);
  const deletedFootPrint = await handleResponse(response);

  return deletedFootPrint;
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        authApi.logout();
      }
      // const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }
    return data;
  });
}

export default footPrintsApi;
