import React, { Component } from "react";
import LoaderView from "../common/loader-view";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";
import utils from "../../helpers/utils";
import Fade from "@material-ui/core/Fade";
import mixConstants from "../../constants/mix-constants";

class StudentsListView extends Component {
  // component lifecycle methods

  // helper methods

  renderStudents(students) {
    if (students.length > 0) {
      const mappedStudents = students.map((student, i) => {
        const name = student.name ? student.name : "";
        const footprintsCount = student.footprints;
        let profilePicture = "";
        if (student.profile_picture) {
          //profilePicture = apiConfig.BaseURL + student.profile_picture;
          profilePicture = student.profile_picture;
        }

        return (
          <Fade
            in={true}
            timeout={mixConstants.constantValues.TRANSITION_DURATION}
            key={i}
          >
            <li>
              <div className="userImage-name">
                <span className="user-avatar">
                  {profilePicture !== "" ? (
                    <img src={profilePicture} alt="profile-pic" />
                  ) : (
                    <NameInitialsAvatar
                      name={name}
                      backgroundColor={
                        student.bg_color
                          ? student.bg_color
                          : utils.userAvatarBackgroundColor()
                      }
                    />
                  )}
                </span>
                <span className="user-details">
                  <strong className="user-name"> {name} </strong>
                </span>
              </div>
              <div className="footprints">
                <span className="footprints-text">footprints</span>
                <span className="footprints-numbers">{footprintsCount}</span>
              </div>
            </li>
          </Fade>
        );
      });
      return mappedStudents;
    } else {
      return <div className="empty-record"> No students found </div>;
    }
  }

  // buttons action methods

  // render

  render() {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
      students,
    } = this.props;

    return (
      <div className="users-container">
        <h1 className="container-title"> Students </h1>
        <ul className="users-info">
          {fetchingContributors ? (
            <div className="loader-new-post">
              <LoaderView width={50} height={50} />
            </div>
          ) : contributors && contributorsFetched && students ? (
            this.renderStudents(students)
          ) : failedToFetchContributors ? (
            "Failed to fetch students"
          ) : (
            ""
          )}
        </ul>
      </div>
    );
  }
}

export default StudentsListView;
