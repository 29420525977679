import React, { Component } from "react";
import { Link } from "react-router-dom";
import IosImg from "../assets/images/iOS.png";
import playstoreImg from "../assets/images/playstore.png";
import { ReactComponent as Logo } from "../assets/images/app-logo.svg";

class LandingPage extends Component {
  // component lifecycle methods

  // helper methods

  // buttons action methods

  // render

  render() {
    return (
      <div className='landing-page-bg'>
        <div className='login-header'>
          <Logo />
          {/*   <a href="/login" className="btn btn-success">
            Login
          </a> */}
        </div>
        <div className='container'>
          <div className='main-content'>
            <h1 className='mb-4'>About FaithJourney</h1>
            <p className='intro'>
              Faith Journey is a web and mobile application that focuses on
              documenting and reflecting on a child's faith journey. It is about
              capturing faith formation moments, which we call faith footprints,
              with pictures and videos. These faith footprints are then put into
              a secure digital album. Faith Journey is not considered to be
              social media. Faith Journey also allows teachers, youth pastors,
              parents, and students to reflect on the faith footprints. These
              contributors to a student’s faith journey will gather faith
              footprints from their camera roll, instant pictures or videos, or
              Google Drive to be shared only with the student and his/her
              parents.
            </p>

            <div className='d-flex'>
              <p className='action-btn mb-5'>
                <a href='/login' className='btn btn-success'>
                  Login
                </a>
              </p>

              <p className='action-btn mb-5 ml-5'>
                <a
                  target='_blank'
                  href='https://myfaithjourney.com/helpform'
                  className='btn btn-success need-help-button'
                >
                  Need Help?
                </a>
              </p>
            </div>

            <h2>
              Please download our mobile apps on the Apple iOS store and the
              Android store
            </h2>
            <p className='img-app'>
              <a
                href='https://apps.apple.com/us/app/faithjourney/id1544431979'
                target='_blank'
              >
                <img src={IosImg} />{" "}
              </a>
            </p>
            <p>
              <a
                href='https://play.google.com/store/apps/details?id=com.faithjourney'
                target='_blank'
              >
                <img src={playstoreImg} />{" "}
              </a>
            </p>
          </div>
        </div>
        <div className='clearfix bottom-row'>
          <div className='col-md-12'>
            <hr className='row' />
            <p>
              Questions? Please contact Kent at{" "}
              <a href='tel:+1616-328-7808;'>616-328-7808</a> or
              <a className='pl-1' href='mailto:kentezell@myfaithjourney.com;'>
                kentezell@myfaithjourney.com
              </a>
            </p>
          </div>
          <footer>
            <ul>
              <li>&copy;2021</li>
              <li>
                <Link to='/privacy-policy'>Privacy Policy</Link>
              </li>
            </ul>
          </footer>
        </div>
      </div>
    );
  }
}

export default LandingPage;
