import Spinner from "./spinner";
import LoaderView from "./loader-view";
import utils from "../../helpers/utils";
import React, { Component } from "react";
import reflectionsApi from "../../apis/reflections-api";
import FootprintReflectionsList from "./footprint-reflections-list";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";

class FootprintReflectionsSection extends Component {
  constructor() {
    super();

    this.student = null;

    this.state = {
      footPrint: null,
      student: null,

      reflectionIconClicked: false,

      fetchingReflections: false,
      reflectionsToShow: [],
      totalReflections: [],

      postingReflection: false,

      newReflection: "",

      isEditing: false,
      reflectionToUpdate: null,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  // component lifecycle methods

  componentDidMount() {
    const { student } = this.props;

    if (student && student.reflections) {
      this.student = student;

      this.setState({
        totalReflections: student.reflections,
        reflectionsToShow: student.reflections.slice(-2),
        reflectionIconClicked: true,
      });

      // this.setReflections(student.reflections);

      // this.setState({
      //   reflectionIconClicked: true,
      // });
    }
  }

  componentDidUpdate() {
    const { footPrint, student } = this.props;

    if (this.student !== null) {
      if (
        footPrint &&
        student &&
        student.student_id &&
        this.student.student_id !== student.student_id
      ) {
        this.setState({
          totalReflections: [],
          reflectionsToShow: [],
        });

        this.student = student;
        this.fetchReflections(footPrint.id, student.student_id);
      }
    }
  }

  // helper methods

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  }

  fetchReflections(postId, studentId) {
    this.setState({
      fetchingReflections: true,
    });

    reflectionsApi.fetchReflections(postId, studentId).then(
      (reflections) => {
        this.setState({
          fetchingReflections: false,
          reflectionsToShow:
            reflections && reflections.length > 0 ? reflections.slice(-2) : [],

          // reflectionsToShow:
          //   reflections && reflections.length > 0 ? reflections : [],
          totalReflections: reflections ? reflections : [],
        });
      },
      (error) => {
        this.setState({
          fetchingReflections: false,
          reflectionsToShow: [],
          totalReflections: [],
        });
      },
    );
  }

  postReflection(reflectionToPost) {
    reflectionsApi.postReflection(reflectionToPost).then(
      (reflection) => {
        let totalReflections = [...this.state.totalReflections];
        totalReflections.push(reflection);

        this.setState({
          postingReflection: false,
          newReflection: "",
        });
        this.setReflections(totalReflections);
      },
      (error) => {
        this.setState({
          postingReflection: false,
        });
      },
    );
  }

  updateReflection(newReflection, reflectionId) {
    reflectionsApi.editReflection(newReflection, reflectionId).then(
      (updatedReflection) => {
        const index = this.state.totalReflections.findIndex(
          (r) => r.id === reflectionId,
        );
        if (index >= 0) {
          let totalReflections = [...this.state.totalReflections];
          totalReflections[index] = updatedReflection;

          this.setState({
            isEditing: false,
            reflectionToUpdate: null,
            postingReflection: false,
            newReflection: "",
          });
          this.setReflections(totalReflections);
        }
      },
      (error) => {
        this.setState({
          postingReflection: false,
        });
      },
    );
  }

  setReflections(totalReflections) {
    this.setState({
      totalReflections: totalReflections,
      reflectionsToShow:
        this.state.reflectionsToShow.length ===
        this.state.totalReflections.length
          ? totalReflections
          : totalReflections.slice(-2),
    });
  }

  // buttons action methods

  handleKeyDown(e) {
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      this.state.newReflection.trim() !== ""
    ) {
      this.postNewReflectionButtonClicked();
    }
  }

  reflectionIconClicked() {
    this.setState({
      reflectionIconClicked: !this.state.reflectionIconClicked,
    });

    if (!this.state.reflectionIconClicked) {
      const { footPrint, student } = this.props;

      if (footPrint && footPrint.id && student && student.student_id) {
        this.student = student;
        this.fetchReflections(footPrint.id, student.student_id);
      }
    }
  }

  viewMoreOrLessReflectionsButtonClicked() {
    const { footPrint, student } = this.props;

    if (student.reflection_count > this.state.totalReflections.length) {
      if (footPrint && footPrint.id && student && student.student_id) {
        this.student = student;
        this.fetchReflections(footPrint.id, student.student_id);
      }
      return;
    }

    if (this.state.reflectionsToShow.length > 2) {
      this.setState({
        reflectionsToShow: this.state.totalReflections.slice(-2),
      });
    } else {
      this.setState({
        reflectionsToShow: this.state.totalReflections,
      });
    }
  }

  postNewReflectionButtonClicked() {
    const { footPrint, student } = this.props;

    if (footPrint && footPrint.id && student && student.student_id) {
      const { newReflection, isEditing, reflectionToUpdate } = this.state;

      this.setState({
        postingReflection: true,
      });

      const reflection = {
        reflection: newReflection,
        post: footPrint.id,
        created_by: loggedInUserHelper.id(),
        student: student.student_id,
      };

      if (isEditing && reflectionToUpdate && reflectionToUpdate.id) {
        this.updateReflection(newReflection, reflectionToUpdate.id);
      } else {
        this.postReflection(reflection);
      }
    }
  }

  editReflectionClicked(reflection) {
    this.setState({
      newReflection: reflection.reflection,
      isEditing: true,
      reflectionToUpdate: reflection,
    });
  }

  cancelEditButtonClicked() {
    this.setState({
      newReflection: "",
      isEditing: false,
      reflectionToUpdate: null,
    });
  }

  deleteReflectionClicked(reflection) {
    if (reflection.id) {
      reflectionsApi.deleteReflection(reflection.id).then(
        (status) => {
          const { totalReflections } = this.state;
          const filteredReflections = totalReflections.filter(
            (reflectionObj) => {
              return reflectionObj.id !== reflection.id;
            },
          );
          this.setReflections(filteredReflections);
        },
        (error) => {
          console.log("");
        },
      );
    }
  }

  // render

  render() {
    const {
      reflectionIconClicked,

      fetchingReflections,
      reflectionsToShow,
      totalReflections,
      newReflection,
      postingReflection,
      isEditing,
    } = this.state;

    const { student } = this.props;

    let reflectionsCount =
      totalReflections.length > 0 ? totalReflections.length : "";

    return (
      <div>
        <div className='total-likes clearfix'>
          <ul>
            <li
              key={utils.generateId()}
              onClick={() => this.reflectionIconClicked()}
            >
              <span className='icon comments'>
                <i className='fa fa-commenting-o mr-1' aria-hidden='true'></i>
              </span>
              <span className='total'>{reflectionsCount}</span>
            </li>
          </ul>
        </div>
        {reflectionIconClicked ? (
          <>
            <div className='post-form comment-form mt-1'>
              <div className='input-group mb-2 mr-sm-2'>
                <textarea
                  className='form-control '
                  placeholder='Add a Reflection'
                  name='newReflection'
                  value={newReflection}
                  onKeyDown={this.handleKeyDown}
                  onChange={this.handleInputChange}
                  disabled={postingReflection}
                  maxLength='4000'
                  row='3'
                  cols='4'
                />

                <div className='input-group-prepend'>
                  <div className='input-group-text'>
                    {postingReflection ? (
                      <Spinner />
                    ) : (
                      <button
                        className='submit-comment'
                        type='button'
                        onClick={() => this.postNewReflectionButtonClicked()}
                        disabled={newReflection.trim() === ""}
                      >
                        <i className='fa fa-paper-plane' aria-hidden='true'></i>
                      </button>
                    )}
                    {isEditing ? (
                      <button
                        className='cancel-comment'
                        type='button'
                        onClick={() => this.cancelEditButtonClicked()}
                        disabled={postingReflection}
                      >
                        <i className='fa fa-times' aria-hidden='true'></i>
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {fetchingReflections ? (
              // && !reflectionIconClicked
              <div className='loader-new-post'>
                <LoaderView width={50} height={50} />
              </div>
            ) : (
              <>
                <FootprintReflectionsList
                  reflections={reflectionsToShow}
                  postingReflection={postingReflection || isEditing}
                  onEdit={(reflection) =>
                    this.editReflectionClicked(reflection)
                  }
                  onDelete={(reflection) =>
                    this.deleteReflectionClicked(reflection)
                  }
                />
                {totalReflections.length > 2 ? (
                  // student.reflection_count > totalReflections.length
                  <>
                    <div className='comment-list'>
                      <i className='fa fa-commenting-o' aria-hidden='true'></i>
                      <span
                        className='btn btn-link'
                        onClick={() =>
                          this.viewMoreOrLessReflectionsButtonClicked()
                        }
                      >
                        {reflectionsToShow.length > 2
                          ? student.reflection_count > totalReflections.length
                            ? "View More Reflections"
                            : "View Less Reflections"
                          : "View More Reflections"}
                      </span>
                    </div>
                    {fetchingReflections && reflectionIconClicked ? (
                      <div className='loader-new-post  rgt-mini-loader'>
                        <LoaderView width={50} height={50} />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default FootprintReflectionsSection;
