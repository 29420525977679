import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import authActions from "../redux/actions/auth-actions";
import Spinner from "../components/common/spinner";
import utils from "../helpers/utils";
import loggedInUserHelper from "../helpers/logged-in-user-helper";
import { ReactComponent as Logo } from "../assets/images/app-logo.svg";

class PasswordResetPage extends Component {
  constructor() {
    super();

    const email = loggedInUserHelper.email();

    this.state = {
      email: email,
      submitted: false,
      errorMessage: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // component lifecycle methods

  componentWillUnmount() {
    if (this.props.clearPasswordReset) {
      this.props.clearPasswordReset();
    }
  }

  // helper methods

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // buttons action methods

  handleSubmit(e) {
    e.preventDefault();

    const { email } = this.state;

    if (!utils.isValidEmail(email)) {
      this.setState({
        errorMessage: "Please enter a valid email address.",
      });
      return;
    }
    this.setState({ submitted: true, errorMessage: null });

    if (email) {
      if (this.props.passwordReset) {
        this.props.passwordReset(email);
      }
    }
  }

  refreshPage() {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  // render

  render() {
    const {
      passwordResetInProgress,
      passwordResetLinkSent,
      passwordResetFailedMessage,
    } = this.props;

    const { email, errorMessage } = this.state;
    const isUserLoggedIn = loggedInUserHelper.isLoggedIn();

    return (
      <div className="container-fluid">
        {!isUserLoggedIn ? (
          <div className="row no-gutters">
            <div className="col-md-12">
              <div className="login-header">
                <Logo />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="row no-gutters">
          <div className="col-md-12">
            <div
              className="main-theme "
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
            >
              <div className="forgot-pass-wrap card">
                {passwordResetLinkSent ? (
                  <div className="return-to-login">
                    <div className="alert alert-success" role="alert">
                      <i className="fa fa-check" aria-hidden="true"></i>
                      We have sent a password reset link to the email address
                      registered with this account containing further
                      instructions to reset your password.
                    </div>
                    {isUserLoggedIn ? (
                      <Link to="/">
                        <div
                          className="form-group action-button pt-4"
                          onClick={() => this.refreshPage()}
                        >
                          <button className="btn btn-success">
                            Return to home
                          </button>
                        </div>
                      </Link>
                    ) : (
                      <Link to="/login">
                        <div className="form-group action-button pt-4">
                          <button className="btn btn-success">
                            Return to login
                          </button>
                        </div>
                      </Link>
                    )}
                  </div>
                ) : (
                  <div className="request-form">
                    <h1> Request a password reset </h1>
                    <form className="user-form" onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label className="user-input-label" htmlFor="email">
                          Email Address*
                        </label>
                        <input
                          type="text"
                          className="form-control user-input-field"
                          name="email"
                          value={email}
                          disabled={isUserLoggedIn || passwordResetInProgress}
                          placeholder="Enter your email"
                          onChange={this.handleChange}
                          //disabled={passwordResetInProgress}
                        />
                        <span className="field-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.036"
                            height="14.5"
                            viewBox="0 0 19.036 14.5"
                          >
                            <defs>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html: ".a{fill:#97a1b2;}",
                                }}
                              />
                            </defs>
                            <g transform="translate(0 -61)">
                              <g transform="translate(0 61)">
                                <path
                                  className="a"
                                  d="M17.363,61H1.673A1.676,1.676,0,0,0,0,62.673V73.827A1.676,1.676,0,0,0,1.673,75.5h15.69a1.676,1.676,0,0,0,1.673-1.673V62.673A1.676,1.676,0,0,0,17.363,61Zm-.231,1.115L9.553,69.694,1.91,62.115ZM1.115,73.6V62.9l5.372,5.326Zm.789.789L7.279,69.01l1.883,1.867a.558.558,0,0,0,.787,0l1.836-1.836,5.346,5.346ZM17.921,73.6,12.575,68.25,17.921,62.9Z"
                                  transform="translate(0 -61)"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="form-error">
                        <p>
                          {" "}
                          {passwordResetFailedMessage
                            ? passwordResetFailedMessage
                            : errorMessage
                            ? errorMessage
                            : ""}{" "}
                        </p>
                      </div>
                      <div className="form-group action-button pt-4">
                        <button
                          className="btn btn-success"
                          disabled={passwordResetInProgress}
                        >
                          Reset Password
                        </button>
                        {passwordResetInProgress ? <Spinner /> : ""}
                      </div>
                    </form>
                  </div>
                )}
              </div>
              {!isUserLoggedIn ? (
                <div className="app-footer">
                  <ul className="links-list">
                    {/* <li key={utils.generateId()}>
                      <a href="#help">Help</a>
                    </li>
                    <li key={utils.generateId()}>
                      <a href="#about-faithjourney">About faithjourney</a>
                    </li> */}
                    <li key={utils.generateId()}>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    passwordResetInProgress,
    passwordResetLinkSent,
    passwordResetFailedMessage,
  } = state.passwordReset;

  return {
    passwordResetInProgress,
    passwordResetLinkSent,
    passwordResetFailedMessage,
  };
}

const actionCreators = {
  passwordReset: authActions.passwordReset,
  clearPasswordReset: authActions.clearPasswordReset,
};

export default connect(mapStateToProps, actionCreators)(PasswordResetPage);
