import React, { Component } from "react";
import { connect } from "react-redux";
import userActions from "../../redux/actions/user-actions";
import { Link } from "react-router-dom";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import jQueryHelper from "../../helpers/jquery-helper";
import utils from "../../helpers/utils";
import history from "../../helpers/history";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";
import LoaderView from "../../components/common/loader-view";
import mixConstants from "../../constants/mix-constants";
import Fade from "@material-ui/core/Fade";
import persistence from "../../helpers/persistence";
//import Moment from "moment";

class UserProfilePage extends Component {
  constructor() {
    super();

    this.state = {
      profileBGColor: "",
      userRole: "",
      profilePicUrl: "",
      about: "",
      firstName: "",
      lastName: "",
      fullName: "",
      username: "",
      userId: "",
      email: "",
      classId: "",
      className: "",
      birthday: "",
      phoneNumber: "",
      city: "",
      state: "",
      country: "",
      address: "",
      hobbies: [],
      activities: [],

      schoolInfo: null,
      churchInfo: null,
      churches: null,
      schools: null,
      parents: null,
    };
  }

  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingUserProfile,
      userProfileFetched,
      userProfile,
      failedToFetchUserProfile,

      match,
    } = nextProps;

    let userId = 0;

    if (match && match.params && match.params.userId) {
      userId = parseInt(match.params.userId, 10);

      if (userId !== prevState.userId) {
        UserProfilePage.fetchUserProfile(userId, nextProps);
      }
    }

    let profileBGColor = "";
    let userRole = "";
    let profilePicUrl = "";
    let about = "";
    let firstName = "";
    let lastName = "";
    let fullName = "";
    let username = "";
    let phoneNumber = "";
    let email = "";
    let dob = "";
    let city = "";
    let state = "";
    let country = "";
    let address = "";
    let hobbies = [];
    let activities = [];
    let classId = "";
    let className = "";

    let schoolInfo = null;
    let churchInfo = null;
    let churches = [];
    let schools = [];
    let parents = [];

    if (userProfile) {
      const basicInfo = userProfile.basic_info;
      churches =
        userProfile.churchs || userProfile.churches || userProfile.church;
      schools = userProfile.schools || userProfile.school;
      let school = null;

      if (schools && schools.length > 0) {
        school = schools[0];
      }
      parents = userProfile.parents || userProfile.parent;

      if (churches && churches.length > 0) {
        churchInfo = churches[0].church;
      }

      if (basicInfo) {
        email = basicInfo.email;
        userId = basicInfo.id;
        firstName = basicInfo.first_name;
        lastName = basicInfo.last_name;
        username = basicInfo.username;

        const profile = basicInfo.profile;

        if (basicInfo.role && basicInfo.role.length > 0) {
          userRole = basicInfo.role[0].role_name;
          userRole = utils.capitalize(userRole);
        }
        if (profile.bg_color) {
          profileBGColor = profile.bg_color;
        }
        if (profile.profile_picture) {
          profilePicUrl = profile.profile_picture;
        }
        if (profile.about) {
          about = profile.about;
        } else {
          about = "-";
        }
        if (profile.fullname) {
          fullName = profile.fullname;
        }
        if (profile.username) {
          username = profile.username;
        }
        if (profile.phone_number) {
          phoneNumber = profile.phone_number;
        }
        if (profile.dob) {
          dob = profile.dob;
        }
        if (profile.city) {
          city = profile.city;
        }
        if (profile.state) {
          state = profile.state;
        }
        if (profile.country) {
          country = profile.country;
        }
        if (profile.address) {
          address = profile.address;
        }
        if (profile.hobbies) {
          hobbies = profile.hobbies.map((hobby) => {
            return {
              id: utils.generateId(),
              text: hobby,
            };
          });
        }
        if (profile.activities) {
          activities = profile.activities.map((activity) => {
            return {
              id: utils.generateId(),
              text: activity,
            };
          });
        }
      }

      if (school) {
        if (school.school) {
          let name = "";
          let address = "";
          let id = "";
          let email = "";
          let phone = "";

          if (school.school.id) {
            id = school.school.id;
          }
          if (school.school.school_name) {
            name = school.school.school_name;
          }
          if (school.school.address) {
            address = school.school.address;
          }
          if (school.school.phone_number) {
            phone = school.school.phone_number;
          }
          if (school.school.email) {
            email = school.school.email;
          }
          schoolInfo = {
            id: id,
            name: name,
            address: address,
            phone: phone,
            email: email,
          };
        }

        if (school.classes && school.classes.length > 0) {
          const classInfo = school.classes[0];
          if (classInfo.class_name) {
            classId = classInfo.class_id;
            className = classInfo.class_name;
          }
        }
      }
    }

    return {
      fetchingUserProfile: fetchingUserProfile,
      userProfileFetched: userProfileFetched,
      userProfile: userProfile,
      failedToFetchUserProfile: failedToFetchUserProfile,

      profileBGColor: profileBGColor,
      userRole: userRole,
      profilePicUrl: profilePicUrl,
      about: about,
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
      username: username,
      phoneNumber: phoneNumber,
      email: email,
      userId: userId,
      dob: dob,
      city: city,
      state: state,
      country: country,
      address: address,
      classId: classId,
      className: className,
      hobbies: hobbies,
      activities: activities,

      schoolInfo: schoolInfo,
      churchInfo: churchInfo,
      churches: churches,
      schools: schools,
      parents: parents,
    };
  }

  componentDidMount() {
    this.loadJQuery();
  }

  //helper methods

  loadJQuery() {
    jQueryHelper.loadSideBarArrowButtonHideShowJQuery();
  }

  static fetchUserProfile(userId, props) {
    if (props.fetchUserProfile) {
      props.fetchUserProfile(userId);
    }
  }

  getUserSchoolClassesNames() {
    const { schools, churches } = this.state;
    let schoolsOrChurches = [];
    if (schools && schools.length > 0) {
      schoolsOrChurches = schools;
    } else if (churches && churches.length > 0) {
      schoolsOrChurches = churches;
    }

    if (schoolsOrChurches && schoolsOrChurches.length > 0) {
      let schoolsOrChurch = schoolsOrChurches;

      if (schoolsOrChurch && schoolsOrChurch.length > 0) {
        const classNames = schoolsOrChurch.map((obj) =>
          obj.classes.map((classObj) => classObj.class_name),
        );
        const allClassNames = classNames.flat().join(", ");
        return allClassNames;
      }
    }
    return "-";
  }

  renderParentsInformation() {
    const { parents } = this.state;

    if (parents && parents.length > 0) {
      const mappedParents = parents.map((parent, i) => {
        // const dob = parent.profile.dob;
        // const formattedDob = dob ? Moment(dob).format("ll") : "-";

        return (
          <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4'>
            <div className='other-information user-profile-details '>
              <h4 className='row-title'>
                {" "}
                Parent Information {parents.length > 1 ? i + 1 : ""}
              </h4>
              <ul className='user-profile-data'>
                <li key={utils.generateId()}>
                  {" "}
                  <span className='data-title'> Username : </span>{" "}
                  <span className='data'>
                    {parent.username ? parent.username : ""}
                  </span>{" "}
                </li>
                <li key={utils.generateId()}>
                  {" "}
                  <span className='data-title'> Name : </span>{" "}
                  <span className='data'>
                    {parent.profile.fullname ? parent.profile.fullname : ""}
                  </span>
                </li>
                {/* <li key={utils.generateId()}>
                  {" "}
                  <span className="data-title"> Birthday : </span>{" "}
                  <span className="data">{formattedDob}</span>{" "}
                </li> */}
                {/* <li key={utils.generateId()}>
                  {" "}
                  <span className="data-title"> Address : </span>{" "}
                  <span className="data">
                    {parent.profile.address ? parent.profile.address : ""}
                  </span>
                </li> */}
                <li key={utils.generateId()}>
                  {" "}
                  <span className='data-title'> Email : </span>{" "}
                  <span className='data'>
                    {parent.email ? parent.email : ""}
                  </span>{" "}
                </li>
                <li key={utils.generateId()}>
                  {" "}
                  <span className='data-title'> Phone : </span>{" "}
                  <span className='data'>
                    {parent.profile.phone_number
                      ? parent.profile.phone_number
                      : ""}
                  </span>{" "}
                </li>
              </ul>
            </div>
          </div>
        );
      });
      return mappedParents;
    } else {
      return "";
    }
  }

  renderSchoolInformation() {
    const { schools } = this.state;

    if (schools && schools.length > 0) {
      const mappedSchools = schools.map((schoolObj) => {
        if (schoolObj.school) {
          const school = schoolObj.school;

          return (
            <div
              className='col-sm-12 col-md-4 col-lg-4 col-xl-4'
              key={utils.generateId()}
            >
              <div className='other-information user-profile-details '>
                <h4 className='row-title'> School Information </h4>
                <ul className='user-profile-data'>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> ID : </span>{" "}
                    <span className='data'> {school.id ? school.id : ""} </span>{" "}
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Name : </span>{" "}
                    <span className='data'>
                      {school.school_name ? school.school_name : ""}
                    </span>
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Address : </span>{" "}
                    <span className='data'>
                      {school.address ? school.address : ""}
                    </span>
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Email : </span>{" "}
                    <span className='data'>
                      {school.email ? school.email : ""}
                    </span>{" "}
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Phone : </span>{" "}
                    <span className='data'>
                      {school.phone_number ? school.phone_number : ""}
                    </span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          );
        } else {
          return "";
        }
      });
      return mappedSchools;
    } else {
      return "";
    }
  }

  renderChurchInformation() {
    const { churches } = this.state;

    if (churches && churches.length > 0) {
      const mappedChurches = churches.map((churchObj) => {
        if (churchObj.church) {
          const church = churchObj.church;

          return (
            <div
              className='col-sm-12 col-md-4 col-lg-4 col-xl-4'
              key={utils.generateId()}
            >
              <div className='other-information user-profile-details '>
                <h4 className='row-title'> Church Information </h4>
                <ul className='user-profile-data'>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> ID : </span>{" "}
                    <span className='data'> {church.id ? church.id : ""} </span>{" "}
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Name : </span>{" "}
                    <span className='data'>
                      {church.church_name ? church.church_name : ""}
                    </span>
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Address : </span>{" "}
                    <span className='data'>
                      {church.address ? church.address : ""}
                    </span>
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Email : </span>{" "}
                    <span className='data'>
                      {church.email ? church.email : ""}
                    </span>{" "}
                  </li>
                  <li key={utils.generateId()}>
                    {" "}
                    <span className='data-title'> Phone : </span>{" "}
                    <span className='data'>
                      {church.phone_number ? church.phone_number : ""}
                    </span>{" "}
                  </li>
                </ul>
              </div>
            </div>
          );
        } else {
          return "";
        }
      });
      return mappedChurches;
    } else {
      return "";
    }
  }

  renderHobbies(hobbies) {
    if (hobbies.length > 0) {
      return hobbies.map((hobby) => {
        return <li key={hobby.id}>{hobby.text}</li>;
      });
    } else {
      return "-";
    }
  }

  renderActivities(activities) {
    if (activities.length > 0) {
      return activities.map((activity) => {
        return <li key={activity.id}>{activity.text}</li>;
      });
    } else {
      return "-";
    }
  }

  getProfileDataToEdit() {
    const profileDataToEdit = {
      userId: this.state.userId,
      profilePicUrl: this.state.profilePicUrl,
      about: this.state.about,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phoneNumber: this.state.phoneNumber,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      address: this.state.address,
      hobbies: this.state.hobbies,
      activities: this.state.activities,
    };

    persistence.setCurrentUserProfileDataToEdit(profileDataToEdit);

    return profileDataToEdit;
  }

  isStudentProfile(roleName) {
    return (
      loggedInUserHelper.isStudent() ||
      roleName.toLowerCase() ===
        mixConstants.usersRoleName.STUDENT.toLowerCase()
    );
  }

  isTeacherProfile(roleName) {
    return (
      loggedInUserHelper.isTeacher() ||
      roleName.toLowerCase() ===
        mixConstants.usersRoleName.TEACHER.toLowerCase()
    );
  }

  isPastorProfile(roleName) {
    return (
      loggedInUserHelper.isPastor() ||
      roleName.toLowerCase() === mixConstants.usersRoleName.PASTOR.toLowerCase()
    );
  }

  isParentProfile(roleName) {
    return (
      loggedInUserHelper.isParents() ||
      roleName.toLowerCase() ===
        mixConstants.usersRoleName.PARENTS.toLowerCase()
    );
  }

  // buttons action methods

  backButtonClicked() {
    history.goBack();
  }

  renderBackButton() {
    return (
      <div className='back-button p-0'>
        <svg
          onClick={() => this.backButtonClicked()}
          xmlns='http://www.w3.org/2000/svg'
          width='15.6'
          height={13}
          viewBox='0 0 15.6 13'
        >
          <defs>
            <style dangerouslySetInnerHTML={{ __html: ".a{fill:#fff;}" }} />
          </defs>
          <path
            className='a'
            d='M22.95,17.85H9.983l4.965-4.729a.65.65,0,1,0-.9-.942l-5.671,5.4a1.3,1.3,0,0,0,.011,1.85l5.659,5.39a.65.65,0,1,0,.9-.941L9.962,19.15H22.95a.65.65,0,0,0,0-1.3Z'
            transform='translate(-8 -12)'
          />
        </svg>{" "}
      </div>
    );
  }

  // render

  render() {
    //const { userProfileUpdated } = this.props;
    const {
      fetchingUserProfile,
      failedToFetchUserProfile,
      userProfileFetched,
      userRole,
      phoneNumber,
      email,
      profileBGColor,
      // dob,
    } = this.state;

    const isParent = this.isParentProfile(userRole);
    //const isStudent = this.isStudentProfile(userRole);
    const isCurrentUserProfile = loggedInUserHelper.id() === this.state.userId;
    const profileTitle = userRole + " Profile";
    //const formattedDob = dob ? Moment(dob).format("ll") : "-";

    let phoneNumberLink = "";
    if (phoneNumber.startsWith("+")) {
      phoneNumberLink = "tel:" + phoneNumber;
    } else {
      phoneNumberLink = "tel:+" + phoneNumber;
    }
    const emailLink = "mailto:" + email;

    if (!userProfileFetched && failedToFetchUserProfile) {
      return (
        <div className='content'>
          {this.renderBackButton()}
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-lg-12 col-xl-6'></div>
              <p>User not found</p>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='content'>
        {!isCurrentUserProfile && !fetchingUserProfile
          ? this.renderBackButton()
          : ""}

        {fetchingUserProfile ? (
          <div className='class-loader' style={{ height: "100vh" }}>
            {" "}
            <LoaderView width={100} height={100} />
          </div>
        ) : (
          <>
            <Fade
              in={true}
              timeout={mixConstants.constantValues.TRANSITION_DURATION - 400}
            >
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-md-6 col-lg-6 col-xl-6'>
                    <h2 className='page-title mt-3'> {profileTitle.trim()} </h2>
                  </div>
                  <div className='col-md-6 col-lg-6 col-xl-6 mb-3'>
                    <div className='edit-profile-btn'>
                      {isCurrentUserProfile ? (
                        <Link
                          className='btn blue-button'
                          to={{
                            pathname: "/edit-profile",
                            profileDataToEdit: this.getProfileDataToEdit(),
                          }}
                        >
                          {" "}
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='14.122'
                            height='14.698'
                            viewBox='0 0 14.122 14.698'
                          >
                            <defs>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html:
                                    ".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}",
                                }}
                              />
                            </defs>
                            <g transform='translate(-7.588 0.25)'>
                              <g transform='translate(7.838 0)'>
                                <path
                                  className='a'
                                  d='M26.359,11.359h.077l3.7-.806a.364.364,0,0,0,.192-.1L36.6,4.183a2.418,2.418,0,0,0,.71-1.727A2.494,2.494,0,0,0,36.6.71,2.417,2.417,0,0,0,34.878,0,2.36,2.36,0,0,0,33.151.73L26.9,7a.345.345,0,0,0-.115.173l-.806,3.7a.422.422,0,0,0,.115.365A.384.384,0,0,0,26.359,11.359ZM34.878.768a1.669,1.669,0,0,1,1.669,1.669q0,.01,0,.019a1.612,1.612,0,0,1-.48,1.17l-2.36-2.379A1.65,1.65,0,0,1,34.878.768ZM33.17,1.8l2.36,2.36L30.062,9.613,27.7,7.272ZM27.395,8.04l1.9,1.9-2.437.537Z'
                                  transform='translate(-25.304 0)'
                                />
                                <path
                                  className='a'
                                  d='M21.076,365.717H8.222a.384.384,0,1,0,0,.767H21.076a.384.384,0,1,0,0-.767Z'
                                  transform='translate(-7.838 -352.287)'
                                />
                              </g>
                            </g>
                          </svg>
                          Edit Profile{" "}
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Fade>

            <Fade
              in={true}
              timeout={mixConstants.constantValues.TRANSITION_DURATION - 200}
            >
              <div className='container-fluid profile-container'>
                <div className='row'>
                  {/* todo */}
                  <div className='media col-sm-12'>
                    {!isParent ? (
                      <div className='media-left mr-3'>
                        <div className='school-details '>
                          <h6 className='school-label'>
                            {" "}
                            {this.state.schoolInfo
                              ? "School Name"
                              : this.state.churchInfo
                              ? "Church Name"
                              : ""}{" "}
                          </h6>
                          <h1
                            className='school-name'
                            style={{ whiteSpace: "inherit" }}
                          >
                            {" "}
                            {this.state.schoolInfo
                              ? this.state.schoolInfo.name
                              : this.state.churchInfo
                              ? this.state.churchInfo.church_name
                              : ""}{" "}
                          </h1>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className='media-body'>
                      <div className='user-profile-details user-info p-0'>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                            <div className='user-location'>
                              <div key={utils.generateId()} className=''>
                                <div className='media'>
                                  <div className='user-avatar'>
                                    {this.state.profilePicUrl !== "" ? (
                                      <img
                                        className='align-self-center mr-2'
                                        alt='profile-pic'
                                        src={this.state.profilePicUrl}
                                      />
                                    ) : (
                                      <NameInitialsAvatar
                                        name={this.state.fullName}
                                        backgroundColor={profileBGColor}
                                      />
                                    )}
                                  </div>
                                  <div className='media-body'>
                                    <h4 className='mt-0 user-details'>
                                      <strong className='user-name text-uppercase'>
                                        {" "}
                                        {this.state.fullName}{" "}
                                      </strong>
                                    </h4>
                                    {/* {!isStudent && !isParent ? (
                                      <div className="location mt-0">
                                        <i
                                          className="fa fa-map-marker mr-1"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="location-text">
                                          {utils.capitalize(this.state.city)}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                            <ul className='contact-details mb-2 mt-1'>
                              <li key={utils.generateId()}>
                                {" "}
                                {/* <a href="tel:+1 123 456 7890"> */}
                                <a href={phoneNumberLink}>
                                  <span className='icon'>
                                    {" "}
                                    <i
                                      className='fa fa-phone'
                                      aria-hidden='true'
                                    ></i>{" "}
                                  </span>{" "}
                                  <span className='contact-text'>
                                    {" "}
                                    {phoneNumber}{" "}
                                  </span>{" "}
                                </a>{" "}
                              </li>
                              <li key={utils.generateId()}>
                                {" "}
                                <a href={emailLink}>
                                  {" "}
                                  <span className='icon'>
                                    {" "}
                                    <i
                                      className='fa fa-envelope-o'
                                      aria-hidden='true'
                                    ></i>{" "}
                                  </span>{" "}
                                  <span className='contact-text'>{email}</span>{" "}
                                </a>{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-7 col-lg-7 col-xl-7'>
                            <div className='user-profile-details user-info p-0'>
                              <ul className='user-profile-data'>
                                <li key={utils.generateId()}>
                                  {" "}
                                  <span className='data-title'>
                                    {" "}
                                    Username :{" "}
                                  </span>{" "}
                                  <span className='data'>
                                    {" "}
                                    {this.state.username}{" "}
                                  </span>{" "}
                                </li>
                                {/* {!isStudent && !isParent ? (
                                  <li key={utils.generateId()}>
                                    {" "}
                                    <span className="data-title">
                                      {" "}
                                      Birthday :{" "}
                                    </span>{" "}
                                    <span className="data">
                                      {" "}
                                      {formattedDob}{" "}
                                    </span>{" "}
                                  </li>
                                ) : (
                                  ""
                                )} */}
                                {/* <li key={utils.generateId()}>
                                  {" "}
                                  <span className="data-title">
                                    {" "}
                                    Address :{" "}
                                  </span>{" "}
                                  <span className="data">
                                    {this.state.address} ---
                                  </span>{" "}
                                </li> */}
                                {!isParent ? (
                                  <li key={utils.generateId()}>
                                    {" "}
                                    <span className='data-title'>
                                      {" "}
                                      Grade :{" "}
                                    </span>{" "}
                                    <span className='data'>
                                      {this.getUserSchoolClassesNames()}
                                    </span>{" "}
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5'>
                            <div className='user-profile-details interest-details'>
                              {!isParent ? (
                                <div className='user-interest mt-2'>
                                  <h6> Hobbies : </h6>
                                  <ul className='hobbies-list'>
                                    {this.renderHobbies(this.state.hobbies)}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                              {!isParent ? (
                                <div className='user-interest'>
                                  <h6> Activities : </h6>
                                  <ul className='activities-list'>
                                    {this.renderActivities(
                                      this.state.activities,
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>

            <Fade
              in={true}
              timeout={mixConstants.constantValues.TRANSITION_DURATION}
            >
              <div className='container-fluid profile-container'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='about-profile'>
                      <h4 className='row-title'> about : </h4>
                      <p className='about-description'>{this.state.about}</p>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  {this.renderParentsInformation()}
                  {this.renderSchoolInformation()}
                  {this.renderChurchInformation()}
                </div>
              </div>
            </Fade>
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    fetchingUserProfile,
    userProfileFetched,
    userProfile,
    failedToFetchUserProfile,
  } = state.fetchUserProfile;

  const { userProfileUpdated, updatedUserProfile } = state.updateUserProfile;

  return {
    fetchingUserProfile,
    userProfileFetched,
    userProfile,
    failedToFetchUserProfile,

    userProfileUpdated,
    updatedUserProfile,
  };
}

const actionCreators = {
  fetchUserProfile: userActions.fetchUserProfile,
};

export default connect(mapStateToProps, actionCreators)(UserProfilePage);
