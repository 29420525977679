import $ from "jquery";
import ReactPlayer from "react-player";
import utils from "../../helpers/utils";
import React, { Component } from "react";
import persistence from "../../helpers/persistence";
import VisibilitySensor from "react-visibility-sensor";
import OwlCarousel from "react-owl-carousel-autoheight";
import FootprintsMediaGalleryModalView from "./footprints-media-gallery-modal-view";
/* import OwlCarousel from "react-owl-carousel"; */
// import { saveAs } from "file-saver";

class FootprintMediaCarousel extends Component {
  constructor() {
    super();

    this.fullScreenMode = false;
    this.carouselCurrentIndex = 0;

    this.state = {
      update: false,
      footPrintsToShowInGallery: null,
      startPositionInGallery: 0,
    };

    this.updateCount = 0;
    this.userDidScrollPage = this.userDidScrollPage.bind(this);
    this.handleResize = this.handleResize.bind(this);
  }

  // component life cycle methods

  componentDidMount() {
    window.addEventListener("scroll", this.userDidScrollPage);
    window.addEventListener("resize", this.handleResize);
    // window.addEventListener("load", this.reloadCarousel);

    this.loadjQuery();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.userDidScrollPage);
    window.removeEventListener("resize", this.handleResize);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.props.student !== nextProps.student) {
  //     return true;
  //   }
  //   return false;
  // }

  // helper methods

  loadjQuery() {
    // this.fullScreenMode =
    //   document.fullScreen ||
    //   document.mozFullScreen ||
    //   document.webkitIsFullScreen;

    var ref = this;

    $(document).on(
      "mozfullscreenchange webkitfullscreenchange fullscreenchange msfullscreenchange",
      function () {
        // this.fullScreenMode = !this.fullScreenMode;
        // if (!this.fullScreenMode) {
        //   ref.handleResize();
        // }
        if (document.fullscreenElement) {
          // in full screen mode
          this.fullScreenMode = true;
          ref.handleResize();
        } else {
          this.fullScreenMode = false;
          // leaving full screen mode
        }
      },
    );
  }

  userDidScrollPage() {
    if (window.pageYOffset !== 0) {
      persistence.setScrollPosition(window.pageYOffset);
    }
  }

  handleResize() {
    const scrollToPosition = persistence.getScrollPosition();
    if (scrollToPosition) {
      window.scrollTo(0, scrollToPosition);
    }
  }

  reloadCarousel() {
    var $carousel = $(".post-image .owl-carousel");
    $carousel.trigger("refresh.owl.carousel");

    setTimeout(() => {
      if (!this.state.update) {
        this.setState({
          update: true,
        });
      }
    }, 50);
  }

  showGallery(footPrints, startPosition) {
    const { isFromMediaGalleryModalView } = this.props;

    if (!isFromMediaGalleryModalView) {
      this.setState({
        footPrintsToShowInGallery: footPrints,
        startPositionInGallery: startPosition,
      });
    }
  }

  hideGallery() {
    this.setState({
      footPrintsToShowInGallery: null,
      startPositionInGallery: this.state.startPositionInGallery,
    });
  }

  expandVideoButtonClicked(footPrints, startPosition) {
    this.showGallery(footPrints, startPosition);
  }

  viewDocument = (docUrl, fileType, ext) => {
    let url = ``;

    if (fileType === "pdf" || fileType === "text") {
      url = `${docUrl}`;
    } else {
      // url = `https://docs.google.com/gview?url=${docUrl}`;
      url = `https://view.officeapps.live.com/op/embed.aspx?src=${docUrl}`;
    }
    window.open(url, "_blank");
  };

  handleCarouselChange(e) {
    if (e.page.index !== this.carouselCurrentIndex) {
      this.handlePause();
    }
    this.carouselCurrentIndex = e.page.index;
  }

  handleChangeOfPlayerVisibility = (isVisible) => {
    if (isVisible) {
    } else {
      this.handlePause();
    }
  };

  handlePlay = () => {
    // $(".video-player video").trigger("play");
  };

  handlePause = () => {
    $(".video-player video").trigger("pause");
  };

  renderCarouselFootPrintsContainers = (footPrints) => {
    const { isFromMediaGalleryModalView } = this.props;

    const containers = footPrints.map((footPrint, i) => {
      // footPrint.faith_footprint (actual image/file)
      // footPrint.thumbnail (reduced size image/file)
      const footPrintUrl = footPrint.faith_footprint
        ? footPrint.faith_footprint
        : null;
      const footPrintThumbnail = footPrint.thumbnail
        ? footPrint.thumbnail
        : null;
      let footPrintExtension = footPrint.file_extension
        ? footPrint.file_extension
        : null;

      if (footPrintUrl === null) {
        return "";
      }

      let isProcessing;
      if (
        footPrint.is_processing == true ||
        footPrint.is_processing == null ||
        footPrint.is_processing == undefined
      ) {
        isProcessing = true;
      } else {
        isProcessing = false;
      }

      if (footPrintExtension === null) {
        let actualFootPrintUrl = footPrintUrl.split("?");
        if (actualFootPrintUrl.length > 0) {
          actualFootPrintUrl = actualFootPrintUrl[0];
        }

        footPrintExtension = actualFootPrintUrl.split(".").pop();
      }

      const fileType = utils.fileType(footPrintExtension);
      const isSupportedFile = utils.isSupportedFile(footPrintExtension);
      const slideNo = "Slide " + (i + 1);
      let fileIcon = utils.fileIconImage(fileType, footPrintExtension);

      if (!isSupportedFile) {
        return (
          <p key={i}> {`Unsupported file type (.${footPrintExtension})`} </p>
        );
      } else {
        return (
          <div className='carosel-item' key={i}>
            {fileType === "image" ? (
              <div
                // ref={this.carouselDiv}
                className='single-image'
                // style={{
                //   height: footPrintHeight ? `${footPrintHeight}px` : "100%",
                // }}
                onClick={() => this.showGallery(footPrints, i)}
              >
                <img
                  src={
                    footPrintExtension === "gif"
                      ? footPrintUrl
                      : footPrintThumbnail
                      ? footPrintThumbnail
                      : footPrintUrl
                  }
                  alt={slideNo}
                  className='post-img-holder'
                  onLoad={() => this.reloadCarousel()}
                />
              </div>
            ) : fileType === "video" ? (
              <VisibilitySensor
                onChange={this.handleChangeOfPlayerVisibility}
                partialVisibility={true}
              >
                <div className='relative'>
                  {isProcessing && (
                    <div className='no-video-content'>
                      We are still processing the video. Please check back
                      later.
                    </div>
                  )}
                  <ReactPlayer
                    // Disable download button
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload nofullscreen ",
                          disablePictureInPicture: true,
                          preload: "metadata", // "none",
                          poster: `${footPrintThumbnail}`,
                        },
                      },
                    }}
                    // Disable right click
                    onContextMenu={(e) => e.preventDefault()}
                    url={footPrintUrl}
                    controls={true}
                    playsinline={true}
                    playing={false}
                    pip={false}
                    light={false}
                    className='video-player'
                    width='100%'
                    height='100%'
                    onReady={() => this.reloadCarousel()}
                  />
                  {!isFromMediaGalleryModalView && (
                    <button
                      className='video-player-expand'
                      type='button'
                      onClick={() =>
                        this.expandVideoButtonClicked(footPrints, i)
                      }
                    >
                      <i className='fa fa-expand' aria-hidden='true'></i>
                    </button>
                  )}
                  {isFromMediaGalleryModalView && (
                    <div className='cover-last'> ok</div>
                  )}
                </div>
              </VisibilitySensor>
            ) : fileType === "pdf" ? (
              <object
                width='100%'
                height='500px'
                data={footPrintUrl}
                type='application/pdf'
              ></object>
            ) : (
              <>
                <div className='document-placeholder files-icon-holder'>
                  <div className='large-icon'>
                    <img
                      src={fileIcon}
                      className='img-responsive'
                      alt='file-icon'
                      onLoad={() => this.reloadCarousel()}
                    />
                    <div className='col-12 ftr'>
                      <button
                        type='button'
                        className='btn btn-primary active ml-4 btn-sm'
                        aria-pressed='true'
                        onClick={() =>
                          this.viewDocument(
                            footPrintUrl,
                            fileType,
                            footPrintExtension,
                          )
                        }
                      >
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      }
    });
    return containers;
  };

  // render

  render() {
    const { footPrints, isFromMediaGalleryModalView, startPosition } =
      this.props;
    const { footPrintsToShowInGallery, startPositionInGallery } = this.state;

    return (
      <>
        <OwlCarousel
          className='owl-theme'
          loop={false}
          items={1}
          margin={12}
          startPosition={
            isFromMediaGalleryModalView ? startPosition : startPositionInGallery
          }
          dots={footPrints.length > 1 ? true : false}
          nav={false}
          center={true}
          autoHeight={true}
          onChanged={(e) => this.handleCarouselChange(e)}
        >
          {this.renderCarouselFootPrintsContainers(footPrints)}
        </OwlCarousel>
        {footPrintsToShowInGallery ? (
          <FootprintsMediaGalleryModalView
            footPrints={footPrintsToShowInGallery}
            startPosition={startPositionInGallery}
            onClose={() => this.hideGallery()}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default FootprintMediaCarousel;
