import footPrintsApi from "../../apis/footprints-api";
import footPrintsConstants from "../constants/footprints-constants";

const footPrintsActions = {
  fetchCurrentUserFootPrints,
  clearFetchCurrentUserFootPrints,

  fetchSearchedFootPrints,
  clearFetchSearchedFootPrints,

  searchFootPrint,
};

/**
 *  Fetch user foot prints
 */

function fetchCurrentUserFootPrints(page, pageSize, classesOrGroupsId) {
  return (dispatch) => {
    dispatch(request("Fetching..."));
    footPrintsApi
      .fetchCurrentUserFootPrints(page, pageSize, classesOrGroupsId)
      .then(
        (response) => {
          dispatch(success(response));
        },
        (error) => {
          var errorString = "";
          if (error.detail && error.detail !== "") {
            errorString = error.detail;
          }
          if (error.non_field_errors && error.non_field_errors.length > 0) {
            errorString = errorString + "\n" + error.non_field_errors[0];
          }
          if (errorString === "") {
            errorString = "Failed to fetch foot prints";
          }
          dispatch(failure(errorString));
        }
      );
  };

  function request(message) {
    return {
      type: footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_REQUEST,
      message,
    };
  }
  function success(response) {
    return {
      type: footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_SUCCESS,
      response,
    };
  }
  function failure(error) {
    return {
      type: footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_FAILURE,
      error,
    };
  }
}

/**
 *  Clear fetch user foot prints
 */

function clearFetchCurrentUserFootPrints() {
  return { type: footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_CLEAR };
}

/**
 *  Fetch searched foot prints
 */

function fetchSearchedFootPrints(searchQuery, page, pageSize) {
  return (dispatch) => {
    dispatch(request("Fetching..."));
    footPrintsApi.fetchSearchedFootPrints(searchQuery, page, pageSize).then(
      (response) => {
        dispatch(success(response));
      },
      (error) => {
        var errorString = "";
        if (error.detail && error.detail !== "") {
          errorString = error.detail;
        }
        if (error.non_field_errors && error.non_field_errors.length > 0) {
          errorString = errorString + "\n" + error.non_field_errors[0];
        }
        if (errorString === "") {
          errorString = "Failed to fetch foot prints";
        }
        dispatch(failure(errorString));
      }
    );
  };

  function request(message) {
    return {
      type: footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_REQUEST,
      message,
    };
  }
  function success(response) {
    return {
      type: footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_SUCCESS,
      response,
    };
  }
  function failure(error) {
    return {
      type: footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_FAILURE,
      error,
    };
  }
}

/**
 *  Clear searched foot prints
 */

function clearFetchSearchedFootPrints() {
  return { type: footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_CLEAR };
}

/**
 *  Search foot print
 */

function searchFootPrint(searchQuery) {
  return (dispatch) => {
    dispatch(request(searchQuery));
  };

  function request(searchQuery) {
    return {
      type: footPrintsConstants.SEARCH_FOOTPRINT,
      searchQuery,
    };
  }
}

export default footPrintsActions;
