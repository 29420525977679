import React from "react";
import utils from "../../helpers/utils";

export default function NameInitialsAvatar(props) {
  const name = props.name;
  const size = props.size ? props.size : 300;
  const fontSize = props.fontSize ? props.fontSize : 0.4;
  const fontColor = props.fontColor ? props.fontColor : "ffffff";
  const rounded = props.rounded ? props.rounded : true;
  const backgroundColor = props.backgroundColor
    ? props.backgroundColor
    : utils.randomColor();

  return (
    <img
      alt=""
      // className="img-circle user-avatar"
      className="align-self-center img-circle"
      style={{ background: "#" + backgroundColor }}
      src={`https://ui-avatars.com/api/?rounded=${rounded}&name=${name}&size=${size}&font-size=${fontSize}&background=${backgroundColor}&color=${fontColor}`}
    />
  );
}

//https://ui-avatars.com/
