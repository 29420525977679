import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import loggedInUserHelper from "./helpers/logged-in-user-helper";
//import PrivateRoute from "./components/common/private-route";
import LoginPage from "./pages/login-page";
import LandingPage from "./pages/landing-page";
import PasswordResetPage from "./pages/password-reset-page";
import DashboardHeader from "./pages/side-bar-and-header/dashboard-header";
import SideBar from "./pages/side-bar-and-header/side-bar";
import DashboardPage from "./pages/dashboard/dashboard-page";
import UserProfilePage from "./pages/profile/user-profile-page";
import EditUserProfilePage from "./pages/profile/edit-user-profile-page";
import UserClassesGroupsPage from "./pages/dashboard/user-classes-groups-page";
import SearchedFootPrintsResultsPage from "./pages/dashboard/searched-footprints-results-page";
import FootPrintPage from "./pages/dashboard/footprint-page";
import PasswordChangePage from "./pages/password-change-page";
import PrivacyPolicyPage from "./pages/privacy-policy";

const RedirectToLandingPage = () => {
  return <Redirect to="/" />;
};

const RedirectToLoginPage = (props) => {
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    />
  );
};

const unAuthenticatedRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/login" component={LoginPage} />
      <Route path="/password-reset" component={PasswordResetPage} />
      <Route path="/password-change" component={PasswordChangePage} />
      <Route path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route component={RedirectToLoginPage} />
    </Switch>
  );
};

const schoolPrincipalRoutes = () => {
  return teacherRoutes();
};

const churchPrincipalRoutes = () => {
  return pastorRoutes();
};

const teacherRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={DashboardPage} />
      <Route path="/footprints" component={DashboardPage} />
      <Route path="/profile/:userId" component={UserProfilePage} />
      <Route path="/edit-profile" component={EditUserProfilePage} />
      <Route path="/classes" component={UserClassesGroupsPage} />
      <Route path="/student/:userId" component={UserProfilePage} />
      <Route path="/password-reset" component={PasswordResetPage} />
      <Route path="/password-change" component={PasswordChangePage} />
      <Route
        path="/search/:searchQuery"
        component={SearchedFootPrintsResultsPage}
      />
      <Route path="/footprint/:footprintId" component={FootPrintPage} />
      <Route component={RedirectToLandingPage} />
    </Switch>
  );
};

const studentRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={DashboardPage} />
      <Route path="/footprints" component={DashboardPage} />
      <Route path="/profile/:userId" component={UserProfilePage} />
      <Route path="/edit-profile" component={EditUserProfilePage} />
      <Route path="/password-reset" component={PasswordResetPage} />
      <Route path="/password-change" component={PasswordChangePage} />
      <Route
        path="/search/:searchQuery"
        component={SearchedFootPrintsResultsPage}
      />
      <Route path="/footprint/:footprintId" component={FootPrintPage} />
      <Route component={RedirectToLandingPage} />
    </Switch>
  );
};

const pastorRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={DashboardPage} />
      <Route path="/footprints" component={DashboardPage} />
      <Route path="/profile/:userId" component={UserProfilePage} />
      <Route path="/edit-profile" component={EditUserProfilePage} />
      <Route path="/groups" component={UserClassesGroupsPage} />
      <Route path="/student/:userId" component={UserProfilePage} />
      <Route path="/password-reset" component={PasswordResetPage} />
      <Route path="/password-change" component={PasswordChangePage} />
      <Route
        path="/search/:searchQuery"
        component={SearchedFootPrintsResultsPage}
      />
      <Route path="/footprint/:footprintId" component={FootPrintPage} />
      <Route component={RedirectToLandingPage} />
    </Switch>
  );
};

const parentsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={DashboardPage} />
      <Route path="/footprints" component={DashboardPage} />
      <Route path="/profile/:userId" component={UserProfilePage} />
      <Route path="/edit-profile" component={EditUserProfilePage} />
      <Route path="/password-reset" component={PasswordResetPage} />
      <Route path="/password-change" component={PasswordChangePage} />
      <Route
        path="/search/:searchQuery"
        component={SearchedFootPrintsResultsPage}
      />
      <Route path="/footprint/:footprintId" component={FootPrintPage} />
      <Route component={RedirectToLandingPage} />
    </Switch>
  );
};

class Routes extends Component {
  render() {
    const isUserLoggedIn = loggedInUserHelper.isLoggedIn();
    const isSchoolPrincipal = loggedInUserHelper.isSchoolPrincipal();
    const isChurchPrincipal = loggedInUserHelper.isChurchPrincipal();
    const isTeacher = loggedInUserHelper.isTeacher();
    const isStudent = loggedInUserHelper.isStudent();
    const isPastor = loggedInUserHelper.isPastor();
    const isParents = loggedInUserHelper.isParents();

    return (
      <>
        {isUserLoggedIn ? (
          <div
            className={
              isStudent ? "student-theme main-theme" : "common-theme main-theme"
            }
          >
            <DashboardHeader />
            <SideBar />

            <div className="content-wrapper">
              {isSchoolPrincipal
                ? schoolPrincipalRoutes()
                : isChurchPrincipal
                  ? churchPrincipalRoutes()
                  : isTeacher
                    ? teacherRoutes()
                    : isStudent
                      ? studentRoutes()
                      : isPastor
                        ? pastorRoutes()
                        : isParents
                          ? parentsRoutes()
                          : ""}
            </div>
          </div>
        ) : (
          <div>{unAuthenticatedRoutes()}</div>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return { user };
}

export default connect(mapStateToProps)(Routes);
