import React, { Component } from "react";
import { connect } from "react-redux";
import userActions from "../../redux/actions/user-actions";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import SearchableClassesGroupsList from "../../components/common/searchable-classes-groups-list";
import jQueryHelper from "../../helpers/jquery-helper";
import SearchableStudentsList from "../../components/common/searchable-students-list";
import Fade from "@material-ui/core/Fade";
import LoaderView from "../../components/common/loader-view";
import mixConstants from "../../constants/mix-constants";

class UserClassesGroupsPage extends Component {
  constructor() {
    super();

    this.state = {
      fetchingCurrentUserContributors: null,
      currentUserContributorsFetched: null,
      currentUserContributors: null,
      failedToFetchCurrentUserContributors: null,
      allClassesGroups: [],

      selectedClassGroup: null,
    };
  }

  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingCurrentUserContributors,
      currentUserContributorsFetched,
      currentUserContributors,
      failedToFetchCurrentUserContributors,
    } = nextProps;

    let allClassesGroups = [];

    if (currentUserContributors) {
      if (
        loggedInUserHelper.isTeacher() ||
        loggedInUserHelper.isSchoolPrincipal()
      ) {
        if (
          currentUserContributors.school_classes &&
          currentUserContributors.school_classes.length > 0
        ) {
          allClassesGroups = currentUserContributors.school_classes;
        }
      } else if (
        loggedInUserHelper.isPastor() ||
        loggedInUserHelper.isChurchPrincipal()
      ) {
        if (
          currentUserContributors.church_classes &&
          currentUserContributors.church_classes.length > 0
        ) {
          allClassesGroups = currentUserContributors.church_classes;
        }
      }
    }
    return {
      fetchingCurrentUserContributors: fetchingCurrentUserContributors,
      currentUserContributorsFetched: currentUserContributorsFetched,
      currentUserContributors: currentUserContributors,
      failedToFetchCurrentUserContributors: failedToFetchCurrentUserContributors,
      allClassesGroups: allClassesGroups,
    };
  }

  componentDidMount() {
    this.loadJQuery();
    this.fetchCurrentUserContributors();
  }

  // helper methods

  loadJQuery() {
    jQueryHelper.loadSideBarArrowButtonHideShowJQuery();
  }

  fetchCurrentUserContributors() {
    if (this.props.fetchCurrentUserContributors) {
      this.props.fetchCurrentUserContributors();
    }
  }

  // buttons action methods

  userDidSelectClassGroup(classGroup) {
    this.setState({
      selectedClassGroup: classGroup,
    });
  }

  // render

  render() {
    const {
      fetchingCurrentUserContributors,
      currentUserContributorsFetched,
      currentUserContributors,
      failedToFetchCurrentUserContributors,
      allClassesGroups,
      selectedClassGroup,
    } = this.state;

    return (
      <div className="content">
        {fetchingCurrentUserContributors ? (
          <div className="class-loader" style={{ height: "100vh" }}>
            {" "}
            <LoaderView width={100} height={100} />
          </div>
        ) : (
          <>
            <Fade
              in={true}
              timeout={mixConstants.constantValues.TRANSITION_DURATION - 200}
            >
              <div className="container-fluid student-categories-container">
                <SearchableClassesGroupsList
                  fetchingContributors={fetchingCurrentUserContributors}
                  contributorsFetched={currentUserContributorsFetched}
                  contributors={currentUserContributors}
                  failedToFetchContributors={
                    failedToFetchCurrentUserContributors
                  }
                  allClassesGroups={allClassesGroups}
                  onSelectClassGroup={(classGroup) =>
                    this.userDidSelectClassGroup(classGroup)
                  }
                />
              </div>
            </Fade>

            {selectedClassGroup ? (
              <Fade
                in={true}
                timeout={mixConstants.constantValues.TRANSITION_DURATION - 200}
              >
                <div className="container-fluid student-categories-container">
                  <SearchableStudentsList classGroup={selectedClassGroup} />
                </div>
              </Fade>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,
  } = state.fetchCurrentUserContributors;

  return {
    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,
  };
}

const actionCreators = {
  fetchCurrentUserContributors: userActions.fetchCurrentUserContributors,
};

export default connect(mapStateToProps, actionCreators)(UserClassesGroupsPage);
