import React, { Component } from "react";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import utils from "../../helpers/utils";
import { Link } from "react-router-dom";
import ViewProfileIcon from "../../assets/images/view_profile.png";
import Tooltip from "@material-ui/core/Tooltip";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";
import Fade from "@material-ui/core/Fade";
import mixConstants from "../../constants/mix-constants";
// import ViewFootprintIcon from "../../assets/images/view_footprint.png";

class SearchableStudentsList extends Component {
  constructor() {
    super();

    this.state = {
      isSearching: false,
      filteredStudents: [],
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
  }

  // component lifecycle methods

  // helper methods

  handleSearchInputChange(e) {
    const { name, value } = e.target;

    if (name === "search") {
      if (value === "") {
        this.setState({
          isSearching: false,
          filteredStudents: [],
        });
      } else {
        let students = [];
        if (
          loggedInUserHelper.isTeacher() ||
          loggedInUserHelper.isSchoolPrincipal()
        ) {
          students = this.getSchoolStudentsFromProps();
        } else if (
          loggedInUserHelper.isPastor() ||
          loggedInUserHelper.isChurchPrincipal()
        ) {
          students = this.getChurchStudentsFromProps();
        }
        const filteredResults = students.filter((student) => {
          return student.name.toLowerCase().includes(value.toLowerCase());
        });
        this.setState({
          isSearching: true,
          filteredStudents: filteredResults,
        });
      }
    }
  }

  getSchoolStudentsFromProps() {
    if (this.props && this.props.classGroup) {
      if (this.props.classGroup.school_students) {
        return this.props.classGroup.school_students;
      }
    }
    return [];
  }

  getChurchStudentsFromProps() {
    if (this.props && this.props.classGroup) {
      if (this.props.classGroup.church_students) {
        return this.props.classGroup.church_students;
      }
    }
    return [];
  }

  renderStudents(students) {
    var mt7n = {
      marginTop: "-7px",
    };
    if (students.length > 0) {
      const mappedStudents = students.map((student, i) => {
        const id = student.id;
        const name = student.name;
        const roleName = utils.capitalize(student.role[0]);
        const footprintsCount = student.footprints;
        let profilePicture = "";
        if (student.profile_picture) {
          //profilePicture = apiConfig.BaseURL + student.profile_picture;
          profilePicture = student.profile_picture;
        }

        return (
          <Fade
            key={id}
            in={true}
            timeout={mixConstants.constantValues.TRANSITION_DURATION}
          >
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="student-lists std-search">
                <div className="userImage-name media">
                  <span className="user-avatar">
                    {profilePicture !== "" ? (
                      <img
                        src={profilePicture}
                        alt="profile-pic"
                        className="img-circle user-avatar"
                      />
                    ) : (
                      <NameInitialsAvatar
                        name={name}
                        size={300}
                        backgroundColor={
                          student.bg_color
                            ? student.bg_color
                            : utils.userAvatarBackgroundColor()
                        }
                      />
                    )}
                  </span>
                  <span className="user-details media-body">
                    <strong className="user-name one-line-txt"> {name} </strong>
                    <small className="user-class" style={mt7n}>
                      {" "}
                      {roleName}{" "}
                    </small>
                    <small className="footprints-numbers">
                      Footprints: {footprintsCount}
                    </small>
                  </span>
                </div>
                <div className="view-profile">
                  {/* <Tooltip title="View Footprint">
                    <img src={ViewFootprintIcon} />
                  </Tooltip> */}

                  <Link to={`/student/${id}`}>
                    <Tooltip title="View Profile" arrow>
                      <img src={ViewProfileIcon} alt="view-profile-icon" />
                    </Tooltip>
                  </Link>
                </div>
              </div>
            </div>
          </Fade>
        );
      });
      return mappedStudents;
    } else {
      return (
        <div className="empty-record">
          {" "}
          <p>No students found </p>
        </div>
      );
    }
  }

  // buttons action methods

  // render

  render() {
    const { classGroup } = this.props;
    const { isSearching, filteredStudents } = this.state;

    let students = [];
    let classGroupName = "";

    if (
      loggedInUserHelper.isTeacher() ||
      loggedInUserHelper.isSchoolPrincipal()
    ) {
      students = this.getSchoolStudentsFromProps();
    } else if (
      loggedInUserHelper.isPastor() ||
      loggedInUserHelper.isChurchPrincipal()
    ) {
      students = this.getChurchStudentsFromProps();
    }
    if (classGroup && classGroup.class_name) {
      classGroupName = classGroup.class_name;
    }

    return (
      <div id="search-std-div">
        <div className="row">
          <div className="col-md-12">
            <div className="form-inline app-search-field">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-navbar border-radius-10"
                  placeholder="Search Student"
                  aria-label="Search"
                  name="search"
                  onChange={this.handleSearchInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h2 className="searched-title"> {classGroupName} </h2>
          </div>
        </div>
        <div className="row scrollable-students-data">
          {this.renderStudents(isSearching ? filteredStudents : students)}
        </div>
      </div>
    );
  }
}

export default SearchableStudentsList;
