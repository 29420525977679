import React from "react";
// import Loader from "react-loader-spinner";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default function LoaderView(props) {
  return (
    <>
      <Loader
        type="ThreeDots"
        color="#99C926"
        height={props.width}
        width={props.height}
        // timeout={9000} //3 secs
      />
    </>
  );
}

/**
 *   Sample loaders
 * 
 * 
    <Loader
      type="Oval"
      color="#00BFFF"
      height={60}
      width={60}
      timeout={1000000}
    />

Audio 	        <Loader type="Audio" color="#00BFFF" height={80} width={80} />
Ball-Triangle 	<Loader type="BallTriangle" color="#00BFFF" height={80} width={80} />
Bars 	        <Loader type="Bars" color="#00BFFF" height={80} width={80} />
Circles 	    <Loader type="Circles" color="#00BFFF" height={80} width={80}/>
Grid 	        <Loader type="Grid" color="#00BFFF" height={80} width={80} />
Hearts 	        <Loader type="Hearts" color="#00BFFF" height={80} width={80} />
Oval 	        <Loader type="Oval" color="#00BFFF" height={80} width={80} />
Puff 	        <Loader type="Puff" color="#00BFFF" height={80} width={80} />
Rings 	        <Loader type="Rings" color="#00BFFF" height={80} width={80} />
TailSpin 	    <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />
ThreeDots 	    <Loader type="ThreeDots" color="#00BFFF" height={80} width={80} />
 */
