import authConstants from "../constants/auth-constants";

const initialState = {};

export default function passwordResetReducer(state = initialState, action) {
  switch (action.type) {
    case authConstants.PASSWORD_RESET_REQUEST:
      return {
        passwordResetInProgress: true,
        email: action.email,
      };
    case authConstants.PASSWORD_RESET_SUCCESS:
      return {
        passwordResetLinkSent: true,
        email: action.email,
      };
    case authConstants.PASSWORD_RESET_FAILURE:
      return {
        passwordResetLinkSent: false,
        passwordResetFailedMessage: action.error,
        email: action.email,
      };
    case authConstants.PASSWORD_RESET_CLEAR:
      return initialState;
    default:
      return state;
  }
}
