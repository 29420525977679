import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ITEM_HEIGHT = 48;

export default function FootPrintMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);

    if (props.onEdit && props.footPrint) {
      props.onEdit(props.footPrint);
    }
  };

  const handleDelete = () => {
    setAnchorEl(null);

    if (props.onDelete && props.footPrint) {
      props.onDelete(props.footPrint);
    }
  };

  return (
    <div>
      <IconButton
        size='small'
        aria-label='more'
        aria-controls='long-menu'
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='long-menu'
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem key={"edit"} onClick={handleEdit}>
          Edit
        </MenuItem>
        <MenuItem key={"delete"} onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
