import React, { Component } from "react";
import groupsClass from "../../assets/images/groups-class.png";
import groupsClassBlue from "../../assets/images/group-blue-icon.png";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import persistence from "../../helpers/persistence";

class SearchableClassesGroupsList extends Component {
  constructor() {
    super();

    this.state = {
      fetchingContributors: null,
      contributorsFetched: null,
      contributors: null,
      failedToFetchContributors: null,
      allClassesGroups: null,

      isSearching: false,
      filteredClassesGroups: [],

      selectedClassGroup: null,
    };

    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
  }

  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
      allClassesGroups,
    } = nextProps;

    return {
      fetchingContributors: fetchingContributors,
      contributorsFetched: contributorsFetched,
      contributors: contributors,
      failedToFetchContributors: failedToFetchContributors,
      allClassesGroups: allClassesGroups,
    };
  }

  componentDidMount() {
    const lastSelectedClassOrGroup = persistence.getLastSelectedClassOrGroup();
    if (lastSelectedClassOrGroup) {
      this.handleClassGroupSelection(lastSelectedClassOrGroup);
    } else {
      if (this.state.allClassesGroups.length > 0) {
        const firstClassGroup = this.state.allClassesGroups[0];
        this.handleClassGroupSelection(firstClassGroup);
      }
    }
  }

  // helper methods

  handleSearchInputChange(e) {
    const { name, value } = e.target;

    if (name === "search") {
      if (value === "") {
        this.setState({
          isSearching: false,
          filteredClassesGroups: [],
        });
      } else {
        const filteredResults = this.state.allClassesGroups.filter(
          (classGroup) => {
            return classGroup.class_name
              .toLowerCase()
              .includes(value.toLowerCase());
          }
        );
        this.setState({
          isSearching: true,
          filteredClassesGroups: filteredResults,
        });
      }
    }
  }

  renderClassesGroups(classesGroups) {
    if (classesGroups.length > 0) {
      const mappedClasses = classesGroups.map((classGroupObj, i) => {
        const class_id = classGroupObj.id;
        const class_name = classGroupObj.class_name;

        const cssClassName =
          this.state.selectedClassGroup &&
          this.state.selectedClassGroup.id === class_id
            ? "student-or-groups-list active-class"
            : "student-or-groups-list";

        return (
          <div
            className="col-sm-12 col-md-4 col-lg-4 col-xl-4"
            key={class_id}
            onClick={() => this.handleClassGroupSelection(classGroupObj)}
          >
            <div className={cssClassName}>
              <div className="media">
                <span className="icon  align-self-center">
                  <img
                    className="default-icon align-self-start mr-3 rounded-circle"
                    alt="class-group-icon"
                    src={groupsClass}
                  />
                  <img
                    className="hover-icon align-self-start mr-3 rounded-circle"
                    alt="class-group-icon"
                    src={groupsClassBlue}
                  />
                </span>{" "}
                <div className="media-body">
                  <h6 className="media-heading mt-2 mb-0">
                    <span className="title"> {class_name} </span>{" "}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        );
      });
      return mappedClasses;
    } else {
      return (
        <div className="col-12 text-center">
          {" "}
          {loggedInUserHelper.isTeacher() ||
          loggedInUserHelper.isSchoolPrincipal()
            ? "No classes found"
            : loggedInUserHelper.isPastor() ||
              loggedInUserHelper.isChurchPrincipal()
            ? "No groups found"
            : ""}{" "}
        </div>
      );
    }
  }

  // buttons action methods

  handleClassGroupSelection(selectedClassGroup) {
    let shouldSelect = false;

    if (this.state.selectedClassGroup) {
      if (selectedClassGroup.id !== this.state.selectedClassGroup.id) {
        shouldSelect = true;
      }
    } else {
      shouldSelect = true;
    }

    if (shouldSelect) {
      if (this.props.onSelectClassGroup) {
        this.props.onSelectClassGroup(selectedClassGroup);
      }
      this.setState({
        selectedClassGroup: selectedClassGroup,
      });
      persistence.setLastSelectedClassOrGroup(selectedClassGroup);
    }
  }

  // render

  render() {
    const {
      //fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
      allClassesGroups,
      isSearching,
      filteredClassesGroups,
    } = this.state;

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h2 className="page-title">
              {" "}
              {loggedInUserHelper.isTeacher() ||
              loggedInUserHelper.isSchoolPrincipal()
                ? "Your Classes"
                : loggedInUserHelper.isPastor() ||
                  loggedInUserHelper.isChurchPrincipal()
                ? "Your Groups"
                : ""}{" "}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-inline app-search-field">
              <div className="input-group">
                <input
                  type="search"
                  className="form-control form-control-navbar border-radius-10"
                  placeholder={
                    loggedInUserHelper.isTeacher() ||
                    loggedInUserHelper.isSchoolPrincipal()
                      ? "Search Class"
                      : loggedInUserHelper.isPastor() ||
                        loggedInUserHelper.isChurchPrincipal()
                      ? "Search Group"
                      : ""
                  }
                  onChange={this.handleSearchInputChange}
                  aria-label="Search"
                  name="search"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row scrollable-classes">
          {contributors && contributorsFetched
            ? this.renderClassesGroups(
                isSearching ? filteredClassesGroups : allClassesGroups
              )
            : failedToFetchContributors
            ? "Failed to fetch classes"
            : ""}
        </div>
      </div>
    );
  }
}

export default SearchableClassesGroupsList;
