import { combineReducers } from "redux";
import authentication from "./authentication-reducer";
import passwordResetReducer from "./password-reset-reducer";
import searchFootPrintReducer from "./search-footprint-reducer";
import fetchUserProfileReducer from "./fetch-user-profile-reducer";
import updateUserProfileReducer from "./update-user-profile-reducer";
import fetchSearchedFootPrintsReducer from "./fetch-searched-footprints-reducer";
import fetchCurrentUserFootPrintsReducer from "./fetch-current-user-footprints-reducer";
import fetchCurrentUserContributorsReducer from "./fetch-current-user-contributors-reducer";

const allReducers = combineReducers({
  authentication: authentication,
  passwordReset: passwordResetReducer,
  fetchCurrentUserFootPrints: fetchCurrentUserFootPrintsReducer,
  fetchCurrentUserContributors: fetchCurrentUserContributorsReducer,
  fetchUserProfile: fetchUserProfileReducer,
  updateUserProfile: updateUserProfileReducer,
  fetchSearchedFootPrints: fetchSearchedFootPrintsReducer,
  searchFootPrint: searchFootPrintReducer,
});

export default allReducers;
