import { useEffect, useState } from "react";
import FileItem from "./FileItem";

const FileList = ({ files, onDelete, disabled, moveFile, showMsg }) => {
  const [allFiles, setAllFiles] = useState([]);
  const moveLocalFile = (fromIndex, toIndex) => {
    if (!areAllFilesUploaded()) {
      showMsg("Files Uploading is in Progress");
      return;
    }
    const updatedFiles = [...allFiles];
    const [movedFile] = updatedFiles.splice(fromIndex, 1);
    updatedFiles.splice(toIndex, 0, movedFile);
    setAllFiles(updatedFiles);
    moveFile(updatedFiles);
  };
  const deleteFile = (file) => {
    onDelete(file);
    const filteredFiles = allFiles?.filter((f) => f.id !== file.id);
    setAllFiles(filteredFiles);
  };
  const areAllFilesUploaded = () => {
    let notYetUploadedCommonFiles = allFiles.filter((file) => {
      return file.uploaded === false;
    });
    return notYetUploadedCommonFiles.length === 0;
  };
  useEffect(() => {
    setAllFiles(files);
  }, [files]);
  return (
    <ul>
      {allFiles?.length ? (
        allFiles?.map((file, index) => (
          <FileItem
            index={index}
            file={file}
            moveFile={moveLocalFile}
            onDelete={() => deleteFile(file)}
            disabled={disabled}
          />
        ))
      ) : (
        <></>
      )}
    </ul>
  );
};
export default FileList;
