import React, { useEffect } from "react";
import styled from "styled-components";
import SnackbarBase from "@material-ui/core/Snackbar";
import SnackbarContentBase from "@material-ui/core/SnackbarContent";
import IconButtonBase from "@material-ui/core/IconButton";
import ErrorIconBase from "@material-ui/icons/Error";
import InfoIconBase from "@material-ui/icons/Info";
import CheckCircleIconBase from "@material-ui/icons/CheckCircle";
import CloseIconBase from "@material-ui/icons/Close";
import { amber, green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const Snackbar = styled(SnackbarBase)`
  /* >>> */
`;
const SnackbarContent = styled(SnackbarContentBase)`
  /* >>> */
  && {
    background: ${(props) =>
      props.status === "success" ? props.theme.success : props.theme.error};
    padding: 0 0.5rem;
  }
`;
const IconButton = styled(IconButtonBase)`
  /* >>> */
`;
const ErrorIcon = styled(ErrorIconBase)`
  /* >>> */
  margin: 0.6rem;
`;
const InfoIcon = styled(InfoIconBase)`
  /* >>> */
  margin: 0.6rem;
`;
const CheckCircleIcon = styled(CheckCircleIconBase)`
  /* >>> */
  margin: 0.6rem;
`;
const InfoWrapper = styled.span`
  display: flex;
  align-items: center;
`;
const CloseIcon = styled(CloseIconBase)`
  padding: 2px;
`;

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));

function SnackbarView({ status, msg, duration, hideClose, onClose }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  useEffect(() => {
    setOpen(true);
  }, [status]);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    onClose();
  }

  let className = classes.success;
  if (status === "success") {
    className = classes.success;
  } else if (status === "error") {
    className = classes.error;
  } else if (status === "info") {
    className = classes.info;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={duration ? duration : 3000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={className}
        status={status}
        contentprops={{
          "aria-describedby": "message-id",
        }}
        // prettier-ignore
        message={(
          <InfoWrapper id='message-id'>
            {status === 'success' ?
              <CheckCircleIcon /> :
              status === 'error' ?
              <ErrorIcon /> :
              <InfoIcon />
            }
            {msg || `Form submission status: ${status}`}
          </InfoWrapper>
        )}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleClose}
          >
            {hideClose ? "" : <CloseIcon />}
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
}
export default SnackbarView;
