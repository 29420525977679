import loggedInUserHelper from "../helpers/logged-in-user-helper";

export default function authHeader() {
  let token = loggedInUserHelper.token();

  if (token) {
    return {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };
  } else {
    return {};
  }
}
