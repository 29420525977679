import React from "react";
import { Router } from "react-router-dom";
import history from "./helpers/history";
import Routes from "./routes";
import authApi from "./apis/auth-api";
import loggedInUserHelper from "./helpers/logged-in-user-helper";
import mixConstants from "./constants/mix-constants";

import "./styles/Adminlte.min.css";
// import "./styles/all.min.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.css";
import "./styles/app.scss";
import "./styles/generic.css";
import "./styles/fj-style.css";
import "./styles/responsive.css";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// function App() {
//   return (
//     <Router history={history}>
//       <Routes />
//     </Router>
//   );
// }

// export default App;

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    this.warn = this.warn.bind(this);
    this.logout = this.logout.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    this.idleTimeOutDuration =
      mixConstants.constantValues.USER_IDLE_TIMEOUT_IN_MINUTES;

    for (var i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    // window.addEventListener("beforeunload", (ev) => {
    //   ev.preventDefault();
    //   return this.logout();
    // });

    this.setTimeout();
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.warnTimeout = setTimeout(
      this.warn,
      (this.idleTimeOutDuration - 1) * 60 * 1000
    );
    this.logoutTimeout = setTimeout(
      this.logout,
      this.idleTimeOutDuration * 60 * 1000
    );
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    //alert("You will be logged out automatically in 1 minute.");
  }

  logout() {
    if (loggedInUserHelper.isLoggedIn()) {
      authApi.logout();
      // this.destroy(); // Cleanup
    }
  }

  destroy() {
    this.clearTimeout();

    for (var i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  render() {
    return (
      <Router history={history}>
        <Routes />
      </Router>
    );
  }
}
