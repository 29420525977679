// dev-instance
// export default class ApiConfig {
//   static BaseURL = "http://192.168.101.163:8080"; // Kazim
//   // "http://3.131.160.225";
//   // "http://18.191.166.145";
//   // "http://192.168.101.139:8000";
//   // static BaseURL = "http://192.168.101.199:8000"; // sohaib's machine
// }

// production-instance
export default class ApiConfig {
  static BaseURL = "https://admin.faithjourney.app";
  // static BaseURL = "http://192.168.101.187:8080";
}
