import React, { Component } from "react";
import LoaderView from "../common/loader-view";
import Fade from "@material-ui/core/Fade";
import mixConstants from "../../constants/mix-constants";

class TeacherClassesCheckboxListView extends Component {
  constructor() {
    super();

    this.state = {
      fetchingContributors: null,
      contributorsFetched: null,
      contributors: null,
      failedToFetchContributors: null,

      classesList: [],
    };
  }
  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
      allClasses,
    } = nextProps;

    let classesList = prevState.classesList;

    if (
      contributorsFetched &&
      allClasses &&
      prevState.classesList.length === 0
    ) {
      classesList = allClasses;
    }

    return {
      fetchingContributors: fetchingContributors,
      contributorsFetched: contributorsFetched,
      contributors: contributors,
      failedToFetchContributors: failedToFetchContributors,

      classesList: classesList,
    };
  }

  // helper methods

  handleAllChecked = (event) => {
    let classesList = this.state.classesList;
    classesList.forEach(
      (classObj) => (classObj.isChecked = event.target.checked)
    );
    this.setState({ classesList: classesList });
  };

  classCheckboxSelectionDidChange = (i) => (event) => {
    this.setState((state, props) => {
      state.classesList[i].isChecked = !state.classesList[i].isChecked;
      return {
        classesList: state.classesList,
      };
    });
  };

  isAllChecked() {
    let selectedClasses = this.getSelectedClasses();
    if (selectedClasses.length > 0) {
      return selectedClasses.length === this.state.classesList.length;
    }
    return false;
  }

  getSelectedClasses() {
    if (this.state.classesList) {
      let selectedClasses = this.state.classesList.filter((classObj) => {
        return classObj.isChecked === true;
      });
      return selectedClasses;
    }
    return [];
  }

  renderClasses(classes) {
    if (classes.length > 0) {
      const mappedClasses = classes.map((classObj, i) => {
        const class_id = classObj.id;
        const class_name = classObj.name;
        const isChecked = classObj.isChecked;
        const cssClass = isChecked
          ? "custom-checkbox active-checkbox"
          : "custom-checkbox";
        return (
          <Fade
            in={true}
            timeout={mixConstants.constantValues.TRANSITION_DURATION}
            key={class_id}
          >
            <li>
              <label className={cssClass}>
                <input
                  type="checkbox"
                  value={class_name}
                  checked={isChecked}
                  onChange={this.classCheckboxSelectionDidChange(i)}
                />
                <span className="checkmark"></span>
                {class_name}
              </label>
            </li>
          </Fade>
        );
      });
      return mappedClasses;
    } else {
      return (
        <div className="empty-record" /**"col-12"**/> No classes found </div>
      );
    }
  }

  // buttons action methods

  applyFilterButtonClicked() {
    if (this.props.onApplyFilter) {
      const selectedClasses = this.getSelectedClasses();
      this.props.onApplyFilter(selectedClasses);
    }
  }

  // render

  render() {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
      classesList,
    } = this.state;

    return (
      <div className="radio-container">
        <div className="container-title">
          <label className="custom-checkbox">
            <input
              type="checkbox"
              onChange={this.handleAllChecked}
              value="checkedAll"
              checked={this.isAllChecked()}
            />
            <span className="checkmark"></span>
            Classes
          </label>
          {classesList && classesList.length > 0 ? (
            <button
              className="btn blue-button btn-shadow pull-right btn-md btn-mini"
              type="button"
              onClick={() => this.applyFilterButtonClicked()}
            >
              Apply
            </button>
          ) : (
            ""
          )}
        </div>

        <ul className="radio-container-list">
          {fetchingContributors ? (
            <div className="loader-new-post">
              <LoaderView width={50} height={50} />
            </div>
          ) : contributors && contributorsFetched ? (
            this.renderClasses(classesList ? classesList : [])
          ) : failedToFetchContributors ? (
            "Failed to fetch classes"
          ) : (
            ""
          )}
        </ul>
      </div>
    );
  }
}

export default TeacherClassesCheckboxListView;
