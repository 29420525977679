import React, { Component } from "react";
import FootprintMediaCarousel from "./footprint-media-carousel";
import $ from "jquery";

class FootprintsMediaGalleryModalView extends Component {
  // component lifecycle methods

  componentDidMount() {
    this.loadJQuery();
  }

  // helper methods

  loadJQuery() {
    $(".popup-trigger").click();
  }

  // buttons action methods

  closeModalViewButtonClicked() {
    if (this.props.onClose) {
      this.props.onClose();
      //$(".close").click();
    }
  }

  // render

  render() {
    const { footPrints, startPosition } = this.props;

    return (
      <div>
        <button
          type="button"
          className="btn btn-primary popup-trigger d-none"
          data-toggle="modal"
          data-target="#footPrintGallery"
        >
          Launch
        </button>

        <div
          className="modal fade quick-upload-container std-footprint-mdl"
          id="footPrintGallery"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="footPrintGalleryLabel"
          aria-hidden="true"
          data-keyboard="false"
          data-backdrop="static"
        >
          <div className="modal-dialog  modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => this.closeModalViewButtonClicked()}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="modal-body">
                <FootprintMediaCarousel
                  footPrints={footPrints}
                  isFromMediaGalleryModalView={true}
                  startPosition={startPosition}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FootprintsMediaGalleryModalView;
