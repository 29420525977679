import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoginImage from "../assets/images/login-image.png";
import LoginImage2 from "../assets/images/fj-banner-2.png";

import { ReactComponent as Logo } from "../assets/images/app-logo.svg";
import authActions from "../redux/actions/auth-actions";
import authApi from "../apis/auth-api";

class LoginPage extends Component {
  constructor(props) {
    super(props);

    // if (this.props.logout) {
    //   this.props.logout();
    // }

    authApi.logoutWithOutRefresh();

    this.state = {
      email: "",
      password: "",
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // component lifecycle methods

  // helper methods

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  // buttons action methods

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;

    if (email && password) {
      if (this.props.login) {
        let redirectToPath = "/";
        if (
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.from &&
          this.props.location.state.from.pathname &&
          this.props.location.state.from.pathname !== ""
        ) {
          redirectToPath = this.props.location.state.from.pathname;
        }
        this.props.login(email, password, redirectToPath);
      }
    }
  }

  // render

  render() {
    var loginLeftIamge = {
      height: "100vh",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "0 center",
      backgroundImage: `url(${LoginImage2})`,
    };
    const { loggingIn, loginResponseMessage } = this.props;
    const { email, password, submitted } = this.state;

    return (
      <div className='container-fluid login-wrapper'>
        <div className='row no-gutters'>
          <div className='col-md-12'>
            <div className='login-header'>
              <Logo />
            </div>
          </div>
        </div>
        <div className='row no-gutters align-items-center'>
          <div className='col-lg-7 col-md-12 col-xl-6 col-sm-12 col-xs-12'>
            <div className='right-section'>
              <div className='login-form'>
                <div className='welcome-user'>
                  <h1> Welcome to FaithJourney </h1>{" "}
                  <p>Login below to access your faithjourney account</p>
                </div>
                <form
                  className='user-form'
                  name='form'
                  onSubmit={this.handleSubmit}
                  disabled={loggingIn}
                >
                  <div
                    className={
                      "form-group" + (submitted && !email ? " has-error" : "")
                    }
                  >
                    <label className='user-input-label' htmlFor='email'>
                      Email Address/Username *
                    </label>
                    <input
                      type='text'
                      className='form-control user-input-field'
                      name='email'
                      placeholder='Username or Email'
                      value={email}
                      onChange={this.handleChange}
                      disabled={loggingIn}
                    />
                    <span className='field-icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='19.036'
                        height='14.5'
                        viewBox='0 0 19.036 14.5'
                      >
                        <defs>
                          <style
                            dangerouslySetInnerHTML={{
                              __html: ".a{fill:#97a1b2;}",
                            }}
                          />
                        </defs>
                        <g transform='translate(0 -61)'>
                          <g transform='translate(0 61)'>
                            <path
                              className='a'
                              d='M17.363,61H1.673A1.676,1.676,0,0,0,0,62.673V73.827A1.676,1.676,0,0,0,1.673,75.5h15.69a1.676,1.676,0,0,0,1.673-1.673V62.673A1.676,1.676,0,0,0,17.363,61Zm-.231,1.115L9.553,69.694,1.91,62.115ZM1.115,73.6V62.9l5.372,5.326Zm.789.789L7.279,69.01l1.883,1.867a.558.558,0,0,0,.787,0l1.836-1.836,5.346,5.346ZM17.921,73.6,12.575,68.25,17.921,62.9Z'
                              transform='translate(0 -61)'
                            />
                          </g>
                        </g>
                      </svg>
                    </span>
                    {submitted && !email && (
                      <div className='help-block'>
                        Please enter your email address
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "form-group" +
                      (submitted && !password ? " has-error" : "")
                    }
                  >
                    <label className='user-input-label' htmlFor='password'>
                      Password *
                    </label>
                    <input
                      type='password'
                      className='form-control user-input-field'
                      name='password'
                      placeholder='Password'
                      value={password}
                      onChange={this.handleChange}
                      disabled={loggingIn}
                    />
                    <span className='field-icon'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='15.714'
                        height={20}
                        viewBox='0 0 15.714 20'
                      >
                        <defs>
                          <style
                            dangerouslySetInnerHTML={{
                              __html: ".a{fill:#88c000;}",
                            }}
                          />
                        </defs>
                        <g transform='translate(-51.2)'>
                          <g transform='translate(51.2)'>
                            <g transform='translate(0)'>
                              <path
                                className='a'
                                d='M66.2,7.143h-.714V6.429a6.429,6.429,0,1,0-12.857,0v.714h-.714a.714.714,0,0,0-.714.714V19.286a.714.714,0,0,0,.714.714H66.2a.714.714,0,0,0,.714-.714V7.857A.714.714,0,0,0,66.2,7.143ZM54.057,6.429a5,5,0,1,1,10,0v.714h-10ZM65.486,18.571H52.629v-10H65.486Z'
                                transform='translate(-51.2)'
                              />
                            </g>
                          </g>
                          <g transform='translate(56.915 10.714)'>
                            <g transform='translate(0)'>
                              <path
                                className='a'
                                d='M191.918,257.428a2.141,2.141,0,1,0-2.735,2.727v1.56a.714.714,0,1,0,1.429,0v-1.56A2.138,2.138,0,0,0,191.918,257.428Zm-2.02,1.43a.714.714,0,1,1,.714-.714A.714.714,0,0,1,189.9,258.857Z'
                                transform='translate(-187.756 -256)'
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                    {submitted && !password && (
                      <div className='help-block'>
                        Please enter your password
                      </div>
                    )}
                  </div>
                  <div className='form-error'>
                    {" "}
                    <p>
                      {loginResponseMessage ? loginResponseMessage : ""}
                    </p>{" "}
                  </div>
                  <div className='form-group action-button pt-4'>
                    <button className='btn btn-success'>Login Now</button>
                    {loggingIn && (
                      <span className='spinner'>
                        {" "}
                        <img
                          src='data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=='
                          alt=''
                        />
                      </span>
                    )}

                    <span className='forgot-pass'>
                      <Link to='/password-reset' disabled={loggingIn}>
                        {" "}
                        Forgot Password?
                      </Link>{" "}
                      OR{" "}
                      <a
                        target='_blank'
                        href='https://myfaithjourney.com/helpform'
                      >
                        Need Help?
                      </a>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className='col-lg-5 col-md-12 col-xl-6
             col-sm-12 col-xs-12'
          >
            <div
              className='left-section d-none d-lg-block d-xl-block'
              style={loginLeftIamge}
            ></div>
          </div>
          <div className='col-xl-2'></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn, loggedIn, user, loginResponseMessage } =
    state.authentication;

  return { loggingIn, loggedIn, user, loginResponseMessage };
}

const actionCreators = {
  login: authActions.login,
  clearLogin: authActions.clearLogin,
  logout: authActions.logout,
};

export default connect(mapStateToProps, actionCreators)(LoginPage);
