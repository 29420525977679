const userConstants = {
  FETCH_CURRENT_USER_CONTRIBUTORS_REQUEST:
    "FETCH_CURRENT_USER_CONTRIBUTORS_REQUEST",
  FETCH_CURRENT_USER_CONTRIBUTORS_SUCCESS:
    "FETCH_CURRENT_USER_CONTRIBUTORS_SUCCESS",
  FETCH_CURRENT_USER_CONTRIBUTORS_FAILURE:
    "FETCH_CURRENT_USER_CONTRIBUTORS_FAILURE",
  FETCH_CURRENT_USER_CONTRIBUTORS_CLEAR:
    "FETCH_CURRENT_USER_CONTRIBUTORS_CLEAR",

  FETCH_USER_PROFILE_REQUEST: "FETCH_USER_PROFILE_REQUEST",
  FETCH_USER_PROFILE_SUCCESS: "FETCH_USER_PROFILE_SUCCESS",
  FETCH_USER_PROFILE_FAILURE: "FETCH_USER_PROFILE_FAILURE",
  FETCH_USER_PROFILE_CLEAR: "FETCH_USER_PROFILE_CLEAR",

  UPDATE_USER_PROFILE_REQUEST: "UPDATE_USER_PROFILE_REQUEST",
  UPDATE_USER_PROFILE_SUCCESS: "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILURE: "UPDATE_USER_PROFILE_FAILURE",
  UPDATE_USER_PROFILE_CLEAR: "UPDATE_USER_PROFILE_CLEAR",
};

export default userConstants;
