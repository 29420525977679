import React, { Component } from "react";
import FootPrintReflection from "./footprint-reflection";
import utils from "../../helpers/utils";

class FootprintReflectionsList extends Component {
  // component lifecycle methods

  // helper methods

  // buttons action methods

  editReflectionClicked(reflection) {
    if (this.props.onEdit) {
      this.props.onEdit(reflection);
    }
  }

  deleteReflectionClicked(reflection) {
    if (this.props.onDelete) {
      this.props.onDelete(reflection);
    }
  }

  // render

  render() {
    const { reflections, postingReflection } = this.props;

    return (
      <div className="mt-3">
        {reflections && reflections.length > 0
          ? reflections.map((reflection) => {
              return (
                <FootPrintReflection
                  key={utils.generateId()}
                  reflection={reflection}
                  postingReflection={postingReflection}
                  onEdit={(reflection) =>
                    this.editReflectionClicked(reflection)
                  }
                  onDelete={(reflection) =>
                    this.deleteReflectionClicked(reflection)
                  }
                />
              );
            })
          : ""}
      </div>
    );
  }
}

export default FootprintReflectionsList;
