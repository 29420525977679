import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";
import authApi from "../../apis/auth-api";
import $ from "jquery";

class SideBar extends Component {
  // component lifecycle methods

  componentDidMount() {
    this.loadJQuery();
  }

  // helper methods

  loadJQuery() {
    $(".sidebar-arrow svg").click(function () {
      $(".main-sidebar").toggleClass("sidebar-visible");
      $("body").toggleClass("sidebar-close");
      $(".content-wrapper").toggleClass("wide-wrapper");
    });

    var winSize = $(window).width();
    $(".main-sidebar .nav-link").click(function () {
      if (winSize < 992) {
        console.log("sidebar click", winSize);
        $(".main-sidebar").toggleClass("sidebar-open");
      }
    });
    if ($(".main-sidebar").height() < 504) {
      $(".main-sidebar").addClass("scrollable");
    }
    $(document).on(
      "click",
      ".sidebar-close .sidebar-navigation .nav-sidebar .nav-item .nav-link",
      function () {
        $(".main-sidebar").removeClass("sidebar-open");
        //$(".main-sidebar").removeClass("sidebar-visible");
        //$(".content-wrapper").removeClass("wide-wrapper");
      }
    );

    // close sidebar on mobile
    var widthTablet = $(window).width();

    if (widthTablet < 769) {
      $(".nav-sidebar>.nav-item").click(function () {
        $(this).addClass("active").siblings().removeClass("active");
        $(this).parents(".main-sidebar").removeClass("sidebar-open");
      });
    }
    $(".nav-sidebar>.nav-item").click(function () {
      $(this).addClass("active").siblings().removeClass("active");
    });
    $(".nav-sidebar>#link-logout").click(function () {
      $(this).parents(".main-sidebar").addClass("sidebar-open");
    });
  }

  // buttons action methods

  logoutButtonClicked = () => {
    authApi.logout();
  };

  // render

  render() {
    const profilePicUrl = loggedInUserHelper.profilePicUrl();
    const fullName = loggedInUserHelper.fullName();

    const isSchoolPrincipal = loggedInUserHelper.isSchoolPrincipal();
    const isChurchPrincipal = loggedInUserHelper.isChurchPrincipal();
    const isTeacher = loggedInUserHelper.isTeacher();
    const isPastor = loggedInUserHelper.isPastor();
    const currentUserId = loggedInUserHelper.id();
    const isUserLoggedIn = loggedInUserHelper.isLoggedIn();

    let footprintsMenuClassName = "nav-item";
    let profileMenuClassName = "nav-item";
    let passwordResetMenuClassName = "nav-item";
    let classesGroupsMenuClassName = "nav-item";

    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/login" ||
      window.location.pathname === "/footprints" ||
      window.location.pathname === "/edit-profile" ||
      window.location.pathname.includes("/search/")
    ) {
      footprintsMenuClassName = "nav-item active";
    } else if (window.location.pathname.includes("/profile/")) {
      profileMenuClassName = "nav-item active";
    } else if (window.location.pathname === "/password-change") {
      passwordResetMenuClassName = "nav-item active";
    } else if (
      window.location.pathname === "/classes" ||
      window.location.pathname === "/groups" ||
      window.location.pathname.includes("/student/")
    ) {
      classesGroupsMenuClassName = "nav-item active";
    }

    return (
      <aside className="main-sidebar">
        <span className="sidebar-arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.6"
            height={13}
            viewBox="0 0 15.6 13"
          >
            <defs>
              <style dangerouslySetInnerHTML={{ __html: ".a{fill:#fff;}" }} />
            </defs>
            <path
              className="a"
              d="M22.95,17.85H9.983l4.965-4.729a.65.65,0,1,0-.9-.942l-5.671,5.4a1.3,1.3,0,0,0,.011,1.85l5.659,5.39a.65.65,0,1,0,.9-.941L9.962,19.15H22.95a.65.65,0,0,0,0-1.3Z"
              transform="translate(-8 -12)"
            />
          </svg>{" "}
        </span>
        <div className="sidebar">
          <div className="user-details text-center">
            <div className="image">
              <span className="profile-avatar-cover">
                {profilePicUrl ? (
                  <img
                    src={profilePicUrl}
                    className="img-circle"
                    alt="profile-pic"
                  />
                ) : (
                  <NameInitialsAvatar
                    name={fullName}
                    backgroundColor={loggedInUserHelper.profileBackgroundColor()}
                  />
                )}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 129.36 126.54"
                  style={{ enableBackground: "new 0 0 129.36 126.54" }}
                  xmlSpace="preserve"
                >
                  <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                      __html: "\n                  .st0{fill:#010101;}\n",
                    }}
                  />
                  <g>
                    <g>
                      <path
                        className="st0"
                        d="M73.27,2.94c6.19,2.25,12.74,3.27,18.7,6.44c22.13,11.76,34.45,34.54,31.54,61.06    c-2.76,25.13-16.8,41.98-40.49,50.53c-9.8,3.54-20.03,3.66-30.22,1.52c-1.17-0.25-3.61,0.08-3.02-2.19    c0.55-2.12,2.46-0.88,3.78-0.62c35.3,6.88,60.27-18.26,65.77-41.46c7.6-32.07-10.43-63.32-41.5-70.84    C73.5,6.33,69.08,5.89,64.61,5.9C47.46,5.93,30.3,6.02,13.14,5.83c-3.4-0.04-4.39,0.72-4.34,4.26C9,27.42,8.88,44.74,8.9,62.07    c0,2.17,0.32,4.46-2.98,4.29c0-20.15,0.05-40.29-0.07-60.44C5.84,3.44,6.41,2.86,8.89,2.88C30.35,2.99,51.81,2.94,73.27,2.94z"
                      />
                    </g>
                  </g>
                </svg>
              </span>
            </div>
            <div className="info">
              <p> Welcome back, </p>
              <a href="#name" className="d-block">
                {fullName}
              </a>
            </div>
          </div>
          {/* Sidebar Menu */}
          <nav className="mt-2 sidebar-navigation">
            <ul
              className="nav nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className={footprintsMenuClassName}>
                <Link to="/footprints" className="nav-link">
                  {/* <i className="fa fa-newspaper-o" aria-hidden="true"></i> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    viewBox="0 0 18 18"
                  >
                    <defs>
                      <style
                        dangerouslySetInnerHTML={{ __html: ".a{fill:#fff;}" }}
                      />
                    </defs>
                    <g transform="translate(0 0)">
                      <path
                        className="a"
                        d="M6.937,6H1.312A1.314,1.314,0,0,1,0,4.688V1.313A1.314,1.314,0,0,1,1.312,0H6.937A1.314,1.314,0,0,1,8.25,1.313V4.688A1.314,1.314,0,0,1,6.937,6ZM1.312,1.125a.188.188,0,0,0-.187.187V4.688a.188.188,0,0,0,.187.188H6.937a.188.188,0,0,0,.188-.188V1.313a.188.188,0,0,0-.188-.187Zm0,0"
                      />
                      <path
                        className="a"
                        d="M6.937,223.832H1.312A1.314,1.314,0,0,1,0,222.52v-7.875a1.314,1.314,0,0,1,1.312-1.313H6.937a1.314,1.314,0,0,1,1.313,1.313v7.875A1.314,1.314,0,0,1,6.937,223.832Zm-5.625-9.375a.188.188,0,0,0-.187.188v7.875a.188.188,0,0,0,.187.187H6.937a.188.188,0,0,0,.188-.187v-7.875a.188.188,0,0,0-.188-.188Zm0,0"
                        transform="translate(0 -205.832)"
                      />
                      <path
                        className="a"
                        d="M284.27,347.332h-5.625a1.314,1.314,0,0,1-1.313-1.312v-3.375a1.314,1.314,0,0,1,1.313-1.313h5.625a1.314,1.314,0,0,1,1.312,1.313v3.375A1.314,1.314,0,0,1,284.27,347.332Zm-5.625-4.875a.188.188,0,0,0-.188.188v3.375a.188.188,0,0,0,.188.187h5.625a.188.188,0,0,0,.187-.187v-3.375a.188.188,0,0,0-.187-.188Zm0,0"
                        transform="translate(-267.582 -329.332)"
                      />
                      <path
                        className="a"
                        d="M284.27,10.5h-5.625a1.314,1.314,0,0,1-1.313-1.313V1.313A1.314,1.314,0,0,1,278.645,0h5.625a1.314,1.314,0,0,1,1.312,1.312V9.187A1.314,1.314,0,0,1,284.27,10.5Zm-5.625-9.375a.188.188,0,0,0-.188.187V9.187a.188.188,0,0,0,.188.188h5.625a.188.188,0,0,0,.187-.188V1.313a.188.188,0,0,0-.187-.187Zm0,0"
                        transform="translate(-267.582)"
                      />
                    </g>
                  </svg>
                  <p>Footprints</p>
                </Link>
              </li>
              {isTeacher || isSchoolPrincipal ? (
                <li className={classesGroupsMenuClassName}>
                  <Link to="/classes" className="nav-link">
                    <i className="fa fa-laptop" aria-hidden="true"></i>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.362"
                      height="19.362"
                      viewBox="0 0 19.362 19.362"
                    >
                      <defs>
                        <style
                          dangerouslySetInnerHTML={{
                            __html: ".a{fill:#b3d0fb;}",
                          }}
                        />
                      </defs>
                      <g transform="translate(-16 -16)">
                        <path
                          className="a"
                          d="M63.812,26.972A.969.969,0,0,0,64.78,26V16.968A.969.969,0,0,0,63.812,16H48.968a.969.969,0,0,0-.968.968V26a.969.969,0,0,0,.968.968Zm-.968-1.936h-8.39v-.323a.323.323,0,0,0-.323-.323H50.9a.323.323,0,0,0-.323.323v.323h-.645v-7.1H62.844ZM51.227,26.326V25.036h2.582v1.291ZM64.135,26a.323.323,0,0,1-.323.323H54.454v-.645h9.681ZM48.968,16.645H63.812a.323.323,0,0,1,.323.323v8.067h-.645V17.613a.323.323,0,0,0-.323-.323H49.613a.323.323,0,0,0-.323.323v7.422h-.645V16.968A.323.323,0,0,1,48.968,16.645ZM48.645,26v-.323h1.936v.645H48.968A.323.323,0,0,1,48.645,26Z"
                          transform="translate(-30.709)"
                        />
                        <path
                          className="a"
                          d="M35.039,312.323h-2.9a.323.323,0,0,0-.323-.323H26.649a.323.323,0,0,0-.323.323h-.968a.323.323,0,0,0-.323-.323H19.872a.323.323,0,0,0-.323.323H16.323a.323.323,0,0,0-.323.323v1.291a.323.323,0,0,0,.323.323h.645v4.84a.323.323,0,0,0,.323.323h1.291a.323.323,0,0,0,.323-.323v-4.84h.645v.968a.323.323,0,0,0,.323.323h.645v1.936a.969.969,0,0,0-.968.968v.645a.323.323,0,0,0,.323.323h5.163a.323.323,0,0,0,.323-.323v-.645a.969.969,0,0,0-.968-.968V315.55h.645a.323.323,0,0,0,.323-.323v-.968h.968v.968a.323.323,0,0,0,.323.323h.645v1.936a.969.969,0,0,0-.968.968v.645a.323.323,0,0,0,.323.323h5.163a.323.323,0,0,0,.323-.323v-.645a.969.969,0,0,0-.968-.968V315.55h.645a.323.323,0,0,0,.323-.323v-.968h.645v4.84a.323.323,0,0,0,.323.323h1.291a.323.323,0,0,0,.323-.323v-4.84h.323a.323.323,0,0,0,.323-.323v-1.291A.323.323,0,0,0,35.039,312.323Zm-16.78,6.454h-.645v-4.518h.645Zm-1.613-5.163v-.645h2.9v.645Zm8.067,4.84v.323H20.2v-.323a.323.323,0,0,1,.323-.323H24.39A.323.323,0,0,1,24.713,318.454Zm-.968-.968H21.163V315.55h2.582Zm.968-2.582H20.2v-2.259h4.518Zm.645-1.291v-.645h.968v.645Zm6.131,4.84v.323H26.972v-.323a.323.323,0,0,1,.323-.323h3.872A.323.323,0,0,1,31.489,318.454Zm-.968-.968H27.94V315.55h2.582Zm.968-2.582H26.972v-2.259h4.518Zm2.582,3.872h-.645v-4.518h.645Zm.645-5.163H32.135v-.645h2.582Z"
                          transform="translate(0 -284.06)"
                        />
                        <path
                          className="a"
                          d="M160.323,344.645h.645a.323.323,0,0,0,0-.645h-.645a.323.323,0,1,0,0,.645Z"
                          transform="translate(-138.191 -314.769)"
                        />
                        <path
                          className="a"
                          d="M328.323,344.645h.645a.323.323,0,1,0,0-.645h-.645a.323.323,0,0,0,0,.645Z"
                          transform="translate(-299.415 -314.769)"
                        />
                      </g>
                    </svg> */}
                    <p>Classes</p>
                  </Link>
                </li>
              ) : (
                ""
              )}
              {isPastor || isChurchPrincipal ? (
                <li className={classesGroupsMenuClassName}>
                  <Link to="/groups" className="nav-link">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.362"
                      height="19.362"
                      viewBox="0 0 19.362 19.362"
                    >
                      <defs>
                        <style
                          dangerouslySetInnerHTML={{
                            __html: ".a{fill:#b3d0fb;}",
                          }}
                        />
                      </defs>
                      <g transform="translate(-16 -16)">
                        <path
                          className="a"
                          d="M63.812,26.972A.969.969,0,0,0,64.78,26V16.968A.969.969,0,0,0,63.812,16H48.968a.969.969,0,0,0-.968.968V26a.969.969,0,0,0,.968.968Zm-.968-1.936h-8.39v-.323a.323.323,0,0,0-.323-.323H50.9a.323.323,0,0,0-.323.323v.323h-.645v-7.1H62.844ZM51.227,26.326V25.036h2.582v1.291ZM64.135,26a.323.323,0,0,1-.323.323H54.454v-.645h9.681ZM48.968,16.645H63.812a.323.323,0,0,1,.323.323v8.067h-.645V17.613a.323.323,0,0,0-.323-.323H49.613a.323.323,0,0,0-.323.323v7.422h-.645V16.968A.323.323,0,0,1,48.968,16.645ZM48.645,26v-.323h1.936v.645H48.968A.323.323,0,0,1,48.645,26Z"
                          transform="translate(-30.709)"
                        />
                        <path
                          className="a"
                          d="M35.039,312.323h-2.9a.323.323,0,0,0-.323-.323H26.649a.323.323,0,0,0-.323.323h-.968a.323.323,0,0,0-.323-.323H19.872a.323.323,0,0,0-.323.323H16.323a.323.323,0,0,0-.323.323v1.291a.323.323,0,0,0,.323.323h.645v4.84a.323.323,0,0,0,.323.323h1.291a.323.323,0,0,0,.323-.323v-4.84h.645v.968a.323.323,0,0,0,.323.323h.645v1.936a.969.969,0,0,0-.968.968v.645a.323.323,0,0,0,.323.323h5.163a.323.323,0,0,0,.323-.323v-.645a.969.969,0,0,0-.968-.968V315.55h.645a.323.323,0,0,0,.323-.323v-.968h.968v.968a.323.323,0,0,0,.323.323h.645v1.936a.969.969,0,0,0-.968.968v.645a.323.323,0,0,0,.323.323h5.163a.323.323,0,0,0,.323-.323v-.645a.969.969,0,0,0-.968-.968V315.55h.645a.323.323,0,0,0,.323-.323v-.968h.645v4.84a.323.323,0,0,0,.323.323h1.291a.323.323,0,0,0,.323-.323v-4.84h.323a.323.323,0,0,0,.323-.323v-1.291A.323.323,0,0,0,35.039,312.323Zm-16.78,6.454h-.645v-4.518h.645Zm-1.613-5.163v-.645h2.9v.645Zm8.067,4.84v.323H20.2v-.323a.323.323,0,0,1,.323-.323H24.39A.323.323,0,0,1,24.713,318.454Zm-.968-.968H21.163V315.55h2.582Zm.968-2.582H20.2v-2.259h4.518Zm.645-1.291v-.645h.968v.645Zm6.131,4.84v.323H26.972v-.323a.323.323,0,0,1,.323-.323h3.872A.323.323,0,0,1,31.489,318.454Zm-.968-.968H27.94V315.55h2.582Zm.968-2.582H26.972v-2.259h4.518Zm2.582,3.872h-.645v-4.518h.645Zm.645-5.163H32.135v-.645h2.582Z"
                          transform="translate(0 -284.06)"
                        />
                        <path
                          className="a"
                          d="M160.323,344.645h.645a.323.323,0,0,0,0-.645h-.645a.323.323,0,1,0,0,.645Z"
                          transform="translate(-138.191 -314.769)"
                        />
                        <path
                          className="a"
                          d="M328.323,344.645h.645a.323.323,0,1,0,0-.645h-.645a.323.323,0,0,0,0,.645Z"
                          transform="translate(-299.415 -314.769)"
                        />
                      </g>
                    </svg>
                     */}
                    <i className="fa fa-users" aria-hidden="true"></i>
                    <p>Groups</p>
                  </Link>
                </li>
              ) : (
                ""
              )}
              <li className={profileMenuClassName}>
                <Link to={`/profile/${currentUserId}`} className="nav-link">
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                  >
                    <defs>
                      <style
                        dangerouslySetInnerHTML={{
                          __html: ".a{fill:#b3d0fb;}",
                        }}
                      />
                    </defs>
                    <g transform="translate(0)">
                      <path
                        className="a"
                        d="M13.657,10.343a7.969,7.969,0,0,0-3.04-1.907,4.625,4.625,0,1,0-5.234,0A8.013,8.013,0,0,0,0,16H1.25a6.75,6.75,0,1,1,13.5,0H16A7.948,7.948,0,0,0,13.657,10.343ZM8,8a3.375,3.375,0,1,1,3.375-3.375A3.379,3.379,0,0,1,8,8Z"
                        transform="translate(0)"
                      />
                    </g>
                  </svg> */}
                  <i className="fa fa-user-o" aria-hidden="true"></i>
                  <p>Profile</p>
                </Link>
              </li>
              <li className={passwordResetMenuClassName}>
                <Link to={`/password-change`} className="nav-link">
                  <i className="fa fa-key" aria-hidden="true"></i>
                  <p>Change Password</p>
                </Link>
              </li>

              <li
                className="nav-item"
                id="link-logout"
                onClick={this.logoutButtonClicked}
              >
                <span className="nav-link">
                  <i className="fa fa-sign-out" aria-hidden="true"></i>
                  <p>Logout</p>
                </span>
              </li>
              {/* <li className="nav-item">
                <Link to="/activities" className="nav-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.98"
                    height="19.168"
                    viewBox="0 0 19.98 19.168"
                  >
                    <defs>
                      <style
                        dangerouslySetInnerHTML={{
                          __html:
                            ".a{fill:#b3d0fb;stroke:#b3d0fb;stroke-width:0.5px;}",
                        }}
                      />
                    </defs>
                    <g transform="translate(0.25 -0.75)">
                      <path
                        className="a"
                        d="M11.906,22.682a.406.406,0,0,1-.406-.406v-4.87a.406.406,0,0,1,.812,0v4.87A.406.406,0,0,1,11.906,22.682Z"
                        transform="translate(-2.166 -3.013)"
                      />
                      <path
                        className="a"
                        d="M15.558,23.247a.405.405,0,0,1-.225-.068l-3.652-2.435a.406.406,0,0,1,.45-.675L15.783,22.5a.406.406,0,0,1-.225.743Z"
                        transform="translate(-2.166 -3.578)"
                      />
                      <path
                        className="a"
                        d="M7.406,23.247A.406.406,0,0,1,7.18,22.5l3.652-2.435a.406.406,0,0,1,.45.675L7.63,23.178A.4.4,0,0,1,7.406,23.247Z"
                        transform="translate(-1.318 -3.578)"
                      />
                      <path
                        className="a"
                        d="M17.857,4.247H1.623A1.623,1.623,0,1,1,1.623,1H17.857a1.623,1.623,0,1,1,0,3.247ZM1.623,1.812a.812.812,0,0,0,0,1.623H17.857a.812.812,0,1,0,0-1.623Z"
                      />
                      <path
                        className="a"
                        d="M17.016,15.363H3.217A1.219,1.219,0,0,1,2,14.146V4.406A.406.406,0,0,1,2.406,4H17.827a.406.406,0,0,1,.406.406v9.74A1.219,1.219,0,0,1,17.016,15.363ZM2.812,4.812v9.334a.406.406,0,0,0,.406.406h13.8a.406.406,0,0,0,.406-.406V4.812Z"
                        transform="translate(-0.377 -0.565)"
                      />
                      <path
                        className="a"
                        d="M6.406,13.493a.405.405,0,0,1-.287-.692L9.365,9.554A.406.406,0,0,1,9.877,9.5L12,10.92l3.014-3.768a.406.406,0,1,1,.634.507L12.4,11.718a.405.405,0,0,1-.542.084L9.7,10.364,6.692,13.376a.407.407,0,0,1-.287.118Z"
                        transform="translate(-1.13 -1.13)"
                      />
                    </g>
                  </svg>
                  <p>Activites</p>
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  const { userProfileUpdated, updatedUserProfile } = state.updateUserProfile;
  return { userProfileUpdated, updatedUserProfile };
}

export default connect(mapStateToProps)(SideBar);
