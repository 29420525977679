import React from "react";
import FootPrintsImage from "../../assets/images/footprints-image.png";
import CountUp from "react-countup";

function TotalFootPrintsView(props) {
  return (
    <div className="total-footprints">
      <div className="graphic">
        <img src={FootPrintsImage} alt="footprint-icon" />
      </div>
      <div className="footprints">
        <small> Total Footprints </small>
        <span className="numbers">
          {" "}
          <CountUp duration={1} end={props.totalFootprints} />{" "}
        </span>
      </div>
    </div>
  );
}

export default TotalFootPrintsView;
