import React, { Component } from "react";
import LoaderView from "../common/loader-view";
import FootPrintCard from "../common/foot-print-card";
import utils from "../../helpers/utils";

class FootPrintsListView extends Component {
  // component lifecycle methods

  // helper methods

  renderFootPrints(
    footPrints,
    currentPage,
    isLoadingMore,
    isDeletingFootPrint,
    deletingFootPrintId
  ) {
    if (footPrints.length > 0) {
      const mappedFootPrints = footPrints.map((footPrint) => {
        return (
          <FootPrintCard
            key={footPrint.id}
            footPrint={footPrint}
            isDeletingFootPrint={isDeletingFootPrint}
            deletingFootPrintId={deletingFootPrintId}
            onEdit={(footPrint) => this.editFootPrintClicked(footPrint)}
            onDelete={(footPrint) => this.deleteFootPrintClicked(footPrint)}
          />
        );
      });
      if (isLoadingMore) {
        mappedFootPrints.push(
          <div key={utils.generateId()} className="loader-new-post">
            {" "}
            <LoaderView width={50} height={50} />
          </div>
        );
      }
      return mappedFootPrints;
    } else {
      if (currentPage === 1) {
        return <div> No footprint found </div>;
      }
    }
  }

  // buttons action methods

  editFootPrintClicked(footPrint) {
    if (this.props.onEdit) {
      this.props.onEdit(footPrint);
    }
  }

  deleteFootPrintClicked(footPrint) {
    if (this.props.onDelete) {
      this.props.onDelete(footPrint);
    }
  }

  // render

  render() {
    const {
      fetchingFootPrints,
      //footPrintsFetched,
      //footPrintsResponse,
      footPrints,
      failedToFetchFootPrints,
      currentPage,
      isLoadingMore,
      isDeletingFootPrint,
      deletingFootPrintId,
    } = this.props;

    return (
      <>
        {fetchingFootPrints ? (
          <div className="loader-container">
            <LoaderView width={100} height={100} />
          </div>
        ) : footPrints ? (
          this.renderFootPrints(
            footPrints,
            currentPage,
            isLoadingMore,
            isDeletingFootPrint,
            deletingFootPrintId
          )
        ) : failedToFetchFootPrints ? (
          "Failed to fetch foot prints"
        ) : (
          ""
        )}
      </>
    );
  }
}

export default FootPrintsListView;
