import React, { Component } from "react";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import NameInitialsAvatar from "./name-initials-avatar";
import utils from "../../helpers/utils";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import SnackbarView from "../../components/common/snackbar-view";
import CircularProgress from "@material-ui/core/CircularProgress";
import footPrintsApi from "../../apis/footprints-api";
import mixConstants from "../../constants/mix-constants";
import GDrivePicker from "./gdrive-picker";
import LoadingOverlay from "react-loading-overlay";
import LinearProgress from "@material-ui/core/LinearProgress";
import CreateFootPrintInfo from "../../components/common/create-footprint-info";
import Tooltip from "@material-ui/core/Tooltip";
import $ from "jquery";

class CreateStudentFootprintModalView extends Component {
  constructor() {
    super();

    this.state = {
      hashTag: "",
      hashTags: [],
      postDescription: "",

      files: [],

      importingGDriveFile: false,
      isShareable: false,

      showMessage: false,
      message: "",
      submitted: false,

      postCreated: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  // component lifecycle methods

  componentDidMount() {
    this.loadJQuery();

    const { footPrintToUpdate } = this.props;
    if (footPrintToUpdate) {
      this.loadFootPrintToUpdate(footPrintToUpdate);
    }
  }

  // helper methods

  loadJQuery() {
    $(".popup-trigger").click();
  }

  loadFootPrintToUpdate(footPrint) {
    let description = "";
    let hashTags = [];
    let isShareable = false;

    if (footPrint.description) {
      description = footPrint.description;
    }
    if (footPrint.shareable) {
      isShareable = footPrint.shareable;
    }
    if (footPrint.tags) {
      hashTags = footPrint.tags.map((tag) => {
        return {
          id: utils.generateId(),
          text: tag,
        };
      });
    }

    let files = [];
    if (footPrint.students && footPrint.students.length > 0) {
      files = footPrint.students.map((student) => {
        return student.footprints;
      });
      files = [].concat.apply([], files);
    }

    this.setState({
      hashTags: hashTags,
      postDescription: description,
      files: files,
      isShareable: isShareable,
    });
  }

  makeCustomFileObject(file) {
    if (file) {
      var ext = file.name.split(".").pop();
      const fileType = utils.fileType(ext);
      const fileIcon = utils.fileIcon(fileType);

      return {
        id: utils.generateId(),
        file: file,
        fileName: file.name,
        fileSize: file.size,
        filePreview: URL.createObjectURL(file),
        fileIcon: fileIcon,
        fileType: fileType,
        uploadPercentage: 0,
        uploaded: false,
        uploadedFileId: null,
        uploadedFileUrl: null,
      };
    }
    return null;
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      submitted: false,
      showMessage: false,
      message: "",
    });
  }

  handleKeyDown(e) {
    if (e.key === "Enter" && this.state.hashTag.trim() !== "") {
      this.addHashTagButtonClicked();
    }
  }

  handleLocalFileSelection(event) {
    const file = event.target.files[0];
    if (file === null || file === undefined) {
      return;
    }
    const ext = file.name.split(".").pop();
    const isSupportedFile = utils.isSupportedFile(ext);

    if (!isSupportedFile) {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UNSUPPORTED_FILE,
      });
      return;
    }

    const fileSize = utils.bytesToMB(file.size);
    if (fileSize > mixConstants.limitations.UPLOAD_FILE_SIZE) {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE,
      });
      return;
    }
    const customFileObject = this.makeCustomFileObject(file, true);

    let files = this.state.files;
    files.push(customFileObject);

    const userIndex = null;
    const fileId = customFileObject.id;

    this.setState({
      files: files,
      showMessage: false,
      message: "",
    });

    this.uploadFile(customFileObject, fileId, userIndex);
  }

  handleGDirveFilePickerEvent = (event, payload) => {
    if (event === "UNSUPPORTED_FILE") {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UNSUPPORTED_FILE,
        importingGDriveFile: false,
      });
      return;
    }
    if (event === "GOOGLE_DOCS_NOT_ALLOWED") {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.GOOGLE_DOCS_NOT_ALLOWED,
        importingGDriveFile: false,
      });
      return;
    }
    if (event === "UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE") {
      this.setState({
        showMessage: true,
        message: mixConstants.limitations.UPLOAD_FILE_SIZE_EXCEEDED_MESSAGE,
        importingGDriveFile: false,
      });
      return;
    }
    if (event === "START_REMOTE_PULL") {
      return void this.setState({ importingGDriveFile: true });
    }
    if (event === "SELECTED_FILES") {
      let files = [...this.state.files];

      if (payload.file && payload.fileInfo) {
        const fileName = payload.fileName;
        const fileMimeType = payload.file.type;
        const blob = payload.file;

        var file = new File([blob], fileName, {
          type: fileMimeType,
        });

        const customFileObject = this.makeCustomFileObject(file, false);
        files.push(customFileObject);

        const userIndex = null;
        const fileId = customFileObject.id;

        this.setState({
          importingGDriveFile: false,
          files: files,
          showMessage: false,
          message: "",
        });
        this.uploadFile(customFileObject, fileId, userIndex);
      }
    }
  };

  uploadFile(customFileObject, fileId, userIndex) {
    footPrintsApi.uploadFootprint(
      customFileObject,
      fileId,
      userIndex,
      (uploadResponse) => {
        const fileIndex = this.state.files.findIndex(
          (f) => f.id === uploadResponse.fileId,
        );

        if (uploadResponse.error) {
          console.log("Error: " + uploadResponse.error);
        }
        if (uploadResponse.uploadPercentage) {
          if (fileIndex >= 0) {
            this.setState((state, props) => {
              state.files[fileIndex].uploadPercentage =
                uploadResponse.uploadPercentage;
              return {
                files: state.files,
              };
            });
          }
        }
        if (uploadResponse.data) {
          if (fileIndex >= 0) {
            this.setState((state, props) => {
              state.files[fileIndex].uploaded = true;
              state.files[fileIndex].uploadedFileId = uploadResponse.data.id
                ? uploadResponse.data.id
                : null;
              state.files[fileIndex].uploadedFileUrl = uploadResponse.data
                .faith_footprint
                ? uploadResponse.data.faith_footprint
                : null;
              return {
                files: state.files,
              };
            });
          }
        }
      },
    );
  }

  addHashTagButtonClicked() {
    let hashTags = [...this.state.hashTags];
    const hashTag = {
      id: utils.generateId(),
      text: this.state.hashTag.replace(/\s/g, ""),
    };
    const index = hashTags.findIndex(
      (h) =>
        h.text.toLowerCase().trim() === hashTag.text.toLocaleLowerCase().trim(),
    );
    if (index < 0 && hashTag.text.trim() !== "") {
      hashTags.push(hashTag);

      this.setState({
        hashTags: hashTags,
        hashTag: "",
      });
    }
  }

  renderHashTagsChipViews(hashTags) {
    return hashTags.map((hashTag) => {
      return (
        <Chip
          size='small'
          key={hashTag.id}
          label={hashTag.text}
          onDelete={() => this.deleteHashTagButtonClicked(hashTag)}
          color='primary'
          disabled={this.state.submitted}
        />
      );
    });
  }

  renderUserFiles(files) {
    const mappedUserFiles = files.map((file, i) => {
      const fileId = file.id;
      let name = file.fileName;
      const uploadPercentage = file.uploadPercentage;
      let uploaded = file.uploaded;
      let fileIcon = file.fileIcon;

      if (file.faith_footprint) {
        // from edit footprint
        uploaded = true;
        name = utils.extractFileNameFromURL(file.faith_footprint); //file.faith_footprint.split("/").pop();
        const ext = file.faith_footprint.split(".").pop();
        const fileType = utils.fileType(ext);
        fileIcon = utils.fileIcon(fileType);
      }

      return (
        <li key={fileId}>
          <Chip
            key={fileId}
            clickable={false}
            icon={
              uploaded ? (
                fileIcon
              ) : (
                <CircularProgress value={uploadPercentage} variant='static' />
              )
            }
            label={name}
            color='primary'
            onDelete={() => this.deleteFileButtonClicked(file)}
            disabled={this.state.submitted}
            // deleteIcon={<DeleteOutlined />}
          />
        </li>
      );
    });
    return mappedUserFiles;
  }

  // buttons action methods

  closeModalViewButtonClicked() {
    if (this.props.onClose) {
      this.props.onClose();
      //$(".close").click();
    }
  }

  deleteHashTagButtonClicked(hashTag) {
    let hashTags = [...this.state.hashTags];
    const filteredHashTags = hashTags.filter((h) => {
      return h.id !== hashTag.id;
    });

    this.setState({
      hashTags: filteredHashTags,
    });
  }

  deleteFileButtonClicked(file) {
    const files = [...this.state.files];

    const filteredFiles = files.filter((f) => {
      return f.id !== file.id;
    });
    this.setState({
      files: filteredFiles,
    });
  }

  shareableCheckboxCliked() {
    this.setState({
      isShareable: !this.state.isShareable,
    });
  }

  hideMessage() {
    this.setState({
      showMessage: false,
      message: "",
    });
  }

  postButtonClicked() {
    const { hashTags, postDescription, files, isShareable } = this.state;
    const { footPrintToUpdate } = this.props;

    if (postDescription.trim() === "") {
      this.setState({
        showMessage: true,
        message: "Please enter the post description",
      });
      return;
    }
    this.setState({
      submitted: true,
      showMessage: false,
      message: "",
    });

    const fileIds = files.map((file) => {
      let file_id = file.uploadedFileId;
      if (file.faith_footprint) {
        file_id = file.id;
      }
      return file_id;
    });

    const studentsPost = [
      {
        student: loggedInUserHelper.id(),
        footprint: fileIds,
      },
    ];
    const post = {
      tags: hashTags.map((hashTag) => {
        return hashTag.text;
      }),
      description: postDescription,
      created_by: loggedInUserHelper.id(),
      student_post: studentsPost,
      shareable: isShareable,
    };

    if (footPrintToUpdate && footPrintToUpdate.id) {
      footPrintsApi.updateFootPrint(post, footPrintToUpdate.id).then(
        (updatedFootPrint) => {
          this.setState({
            submitted: false,
            postCreated: true,
            showMessage: false,
            message: "",
          });

          setTimeout(() => {
            if (this.props.onFootPrintUpdated) {
              $(".close").click();
              this.props.onFootPrintUpdated(updatedFootPrint);
            }
          }, 100);
        },
        (error) => {
          this.setState({
            submitted: false,
            postCreated: false,
            showMessage: true,
            message: "Failed to update foot print",
          });
        },
      );
    } else {
      footPrintsApi.createFootPrint(post).then(
        (createdFootPrint) => {
          this.setState({
            submitted: false,
            postCreated: true,
            showMessage: false,
            message: "",
          });

          setTimeout(() => {
            if (this.props.onFootPrintCreated) {
              $(".close").click();
              this.props.onFootPrintCreated(createdFootPrint);
            }
          }, 100);
        },
        (error) => {
          this.setState({
            submitted: false,
            postCreated: false,
            showMessage: true,
            message: "Failed to create foot print",
          });
        },
      );
    }
  }

  // render

  render() {
    const profilePicUrl = loggedInUserHelper.profilePicUrl();
    const profileBgColor = loggedInUserHelper.profileBackgroundColor();
    const fullName = loggedInUserHelper.fullName();
    const {
      files,
      showMessage,
      message,
      submitted,
      importingGDriveFile,
      isShareable,
    } = this.state;
    const { footPrintToUpdate } = this.props;

    return (
      <div>
        <button
          type='button'
          className='btn btn-primary popup-trigger d-none'
          data-toggle='modal'
          data-target='#creatFootPrintModel'
        >
          Launch demo modal
        </button>

        <div
          className='modal fade quick-upload-container std-footprint-mdl'
          id='creatFootPrintModel'
          tabIndex={-1}
          role='dialog'
          aria-labelledby='creatFootPrintModelLabel'
          aria-hidden='true'
          data-keyboard='false'
          data-backdrop='static'
        >
          <div className='modal-dialog modal-sm' role='document'>
            <div className='modal-content'>
              <LoadingOverlay
                active={importingGDriveFile}
                spinner
                className='overlay-main'
                text='Importing file...'
              ></LoadingOverlay>
              <div className='modal-header'>
                <h5 className='modal-title' id='creatFootPrintModelLabel'>
                  Upload
                </h5>
                <button
                  type='button'
                  className='close'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={() => this.closeModalViewButtonClicked()}
                  disabled={submitted}
                >
                  <span aria-hidden='true'>×</span>
                </button>
              </div>
              <div className='modal-body'>
                {submitted ? (
                  <div className='linear-spinner' style={{ marginRight: 0 }}>
                    <LinearProgress />
                  </div>
                ) : (
                  ""
                )}
                <div>
                  {/* <div className="row "> */}
                  <div className='col-12'>
                    <div className='new-post-container'>
                      <div className='userImage-name'>
                        <span className='user-avatar'>
                          {profilePicUrl ? (
                            <img
                              src={profilePicUrl}
                              className='img-circle'
                              alt='profile-pic'
                            />
                          ) : (
                            <NameInitialsAvatar
                              name={fullName}
                              backgroundColor={profileBgColor}
                            />
                          )}
                        </span>
                        <span className='user-details'>
                          <strong className='user-name'> {fullName} </strong>
                        </span>
                      </div>
                      <div className='new-post-area'>
                        {/* <button className="blue-button"> Add Hashtag </button> */}

                        {/* start  */}
                        <div className='form-group user-input-area'>
                          <TextField
                            id='outlined-multiline-static'
                            label='Hash Tag'
                            fullWidth
                            rows={5}
                            variant='outlined'
                            inputProps={{ maxLength: 30 }}
                            name='hashTag'
                            value={this.state.hashTag}
                            onChange={this.handleInputChange}
                            onKeyDown={this.handleKeyDown}
                            disabled={submitted}
                          />
                          <Fab
                            color='primary'
                            aria-label='add'
                            component='span'
                            onClick={() => this.addHashTagButtonClicked()}
                            disabled={submitted}
                          >
                            <AddIcon />
                          </Fab>
                        </div>

                        <div className='chip-box'>
                          {this.renderHashTagsChipViews(this.state.hashTags)}
                        </div>

                        {/* end  */}

                        <div className='form-group'>
                          <textarea
                            className='form-control full-height-txtarea mt-2'
                            id='postDescriptionTextArea'
                            rows='4'
                            placeholder='Hey! Write something here to post'
                            name='postDescription'
                            value={this.state.postDescription}
                            onChange={this.handleInputChange}
                            disabled={submitted}
                          ></textarea>
                          <>
                            <div
                              className='checkbox-row radio-container'
                              style={{ background: "none" }}
                            >
                              <label className='custom-checkbox'>
                                <input
                                  type='checkbox'
                                  checked={isShareable}
                                  onClick={() => this.shareableCheckboxCliked()}
                                />
                                <span className='checkmark' />
                                <p> Share with teacher </p>
                              </label>
                            </div>
                          </>
                        </div>
                        <div className='col-12 p-0'>
                          <ul className='uploaded-asstes scroll-div'>
                            {this.renderUserFiles(files)}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div className='modal-footer no-abs-footer'>
                {showMessage && message ? (
                  <SnackbarView
                    status='error'
                    msg={message}
                    duration={3000}
                    onClose={() => this.hideMessage()}
                  />
                ) : (
                  ""
                )}
                <div className='row'>
                  <div className='col-xs-5 col-sm-8 col-md-8 col-lg-8 p-0'>
                    <div className='footer-uploads'>
                      <small className='upload-files'>
                        {" "}
                        Upload Files <CreateFootPrintInfo />
                      </small>
                      <div className='btn-group' role='group'>
                        <button
                          type='button'
                          className='btn media-upload'
                          onClick={() => {
                            this.localFileUploadButtonRef.click();
                          }}
                          disabled={submitted}
                        >
                          <Tooltip
                            title='Photos / Videos'
                            placement='top'
                            arrow
                          >
                            <span className='icon'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width={17}
                                height='14.165'
                                viewBox='0 0 17 14.165'
                              >
                                <defs>
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        ".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}",
                                    }}
                                  />
                                </defs>
                                <g transform='translate(0.25 -43.729)'>
                                  <g transform='translate(0 43.979)'>
                                    <g transform='translate(0 0)'>
                                      <path
                                        className='a'
                                        d='M15.231,43.979H1.269A1.256,1.256,0,0,0,0,45.248V56.375a1.256,1.256,0,0,0,1.269,1.269H15.231A1.256,1.256,0,0,0,16.5,56.375V45.248A1.256,1.256,0,0,0,15.231,43.979Zm0,12.4H1.269L1.26,45.249H15.231V56.375Z'
                                        transform='translate(0 -43.979)'
                                      />
                                    </g>
                                  </g>
                                  <g transform='translate(0.259 47.787)'>
                                    <g transform='translate(0 0)'>
                                      <path
                                        className='a'
                                        d='M14.075,162.361a.635.635,0,0,0-.864-.106l-5.183,3.808.752,1.023,4.7-3.453,6.684,8.064.977-.81Z'
                                        transform='translate(-8.028 -162.132)'
                                      />
                                    </g>
                                  </g>
                                  <g transform='translate(8.616 48.443)'>
                                    <g transform='translate(0 0)'>
                                      <path
                                        className='a'
                                        d='M271.162,182.626a.635.635,0,0,0-.789,0l-3,2.39.79.993,2.61-2.077,3.455,2.734.787-1Z'
                                        transform='translate(-267.369 -182.489)'
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            </span>
                          </Tooltip>
                        </button>
                        <input
                          id='localFileInput'
                          accept={mixConstants.constantValues.ACCEPTABLE_FILES}
                          type='file'
                          style={{ display: "none" }}
                          ref={(ref) => (this.localFileUploadButtonRef = ref)}
                          onChange={(e) => this.handleLocalFileSelection(e)}
                          onClick={(e) => {
                            e.target.value = null;
                          }}
                        />
                        <GDrivePicker
                          clientId={mixConstants.gdrivePicker.CLIENT_ID}
                          apiKey={mixConstants.gdrivePicker.API_KEY}
                          allowSharedDrives={true}
                          exportAsBlobs={true}
                          multiSelect={true}
                          downloadSelectedFiles={false}
                          onEvent={this.handleGDirveFilePickerEvent}
                        >
                          <button
                            type='button'
                            className='btn google-drive'
                            disabled={submitted}
                          >
                            <Tooltip title='Google Drive' placement='top' arrow>
                              <span className='icon'>
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='16.333'
                                  height='14.291'
                                  viewBox='0 0 16.333 14.291'
                                >
                                  <defs>
                                    <style
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          ".a{fill:#4caf50;}.b{fill:#ffc107;}.c{fill:#2196f3;}",
                                      }}
                                    />
                                  </defs>
                                  <g transform='translate(0 -1.5)'>
                                    <path
                                      className='a'
                                      d='M8.211,6.012,5.6,1.5,0,11.277l2.571,4.514Z'
                                    />
                                    <path
                                      className='b'
                                      d='M8.222,1.5l5.655,9.777H18.96L13.364,1.5Z'
                                      transform='translate(-2.627)'
                                    />
                                    <path
                                      className='c'
                                      d='M6.381,15.867l-2.6,4.514H14.969l2.571-4.514Z'
                                      transform='translate(-1.207 -4.59)'
                                    />
                                  </g>
                                </svg>
                              </span>
                            </Tooltip>
                          </button>
                        </GDrivePicker>
                      </div>
                    </div>
                  </div>
                  <div className='col-xs-7 col-sm-4 col-md-4 col-lg-4 p-0 text-right'>
                    <div className='hidden-div'></div>
                    <button
                      type='button'
                      className='btn  blue-button mt-1 submit-btn'
                      onClick={() => this.postButtonClicked()}
                      disabled={submitted}
                    >
                      {footPrintToUpdate ? "Update" : "Post Now"}
                      {/* {submitted ? <Spinner /> : ""} */}
                    </button>
                  </div>
                </div>
                {/*   <div className="row">
                  <ul className="uploaded-asstes scroll-div">
                    {this.renderUserFiles(files)}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateStudentFootprintModalView;
