import React, { Component } from "react";
import { connect } from "react-redux";
import userActions from "../../redux/actions/user-actions";
import { Redirect } from "react-router-dom";
import footPrintsActions from "../../redux/actions/footprints-actions";
import jQueryHelper from "../../helpers/jquery-helper";
import FootPrintsListView from "../../components/common/footprints-list-view";
import mixConstants from "../../constants/mix-constants";
import footPrintsApi from "../../apis/footprints-api";
import CreateFootprintModalView from "../../components/common/create-footprint-modal-view";
import CreateStudentFootprintModalView from "../../components/common/create-student-footprint-modal-view";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";

class SearchedFootPrintsResultsPage extends Component {
  constructor() {
    super();

    this.footPrintsCurrentPage = 1;

    this.state = {
      fetchingCurrentUserContributors: null,
      currentUserContributorsFetched: null,
      currentUserContributors: null,
      failedToFetchCurrentUserContributors: null,

      allClasses: [],
      allGroups: [],
      children: [],

      footPrintsPageSize: mixConstants.constantValues.FOOT_PRINTS_PAGE_SIZE,

      fetchingSearchedFootPrints: null,
      searchedFootPrintsFetched: null,
      searchedFootPrintsResponse: null,
      searchedFootPrints: [],
      failedToSearchFootPrints: null,

      searchQuery: "",
      hasNextPage: true,

      isDeletingFootPrint: false,
      deletingFootPrintId: 0,

      footPrintToUpdate: null,
    };

    this.loadMoreFootprints = this.loadMoreFootprints.bind(this);
    window.addEventListener("scroll", this.loadMoreFootprints);
  }

  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingCurrentUserContributors,
      currentUserContributorsFetched,
      currentUserContributors,
      failedToFetchCurrentUserContributors,
    } = nextProps;

    let allClasses = prevState.allClasses;
    let allGroups = prevState.allGroups;
    let children = prevState.children;

    if (currentUserContributors) {
      if (
        currentUserContributors.school_classes &&
        currentUserContributors.school_classes.length > 0
      ) {
        allClasses = currentUserContributors.school_classes;
        allClasses =
          SearchedFootPrintsResultsPage.mappedClassesOnCustomObject(allClasses);
      } else if (
        currentUserContributors.church_classes &&
        currentUserContributors.church_classes.length > 0
      ) {
        allGroups = currentUserContributors.church_classes;
        allGroups =
          SearchedFootPrintsResultsPage.mappedGroupsOnCustomObject(allGroups);
      } else if (currentUserContributors && currentUserContributors.children) {
        children = currentUserContributors.children;
      }
    }

    return {
      fetchingCurrentUserContributors: fetchingCurrentUserContributors,
      currentUserContributorsFetched: currentUserContributorsFetched,
      currentUserContributors: currentUserContributors,
      failedToFetchCurrentUserContributors:
        failedToFetchCurrentUserContributors,

      allClasses: allClasses,
      allGroups: allGroups,
      children: children,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.searchQuery !== this.props.match.params.searchQuery
    ) {
      this.search();
    }
  }

  componentDidMount() {
    this.loadJQuery();
    this.fetchCurrentUserContributors();
    this.search();
  }

  componentWillUnmount() {
    this.footPrintsCurrentPage = 1;

    if (this.props.searchFootPrint) {
      this.props.searchFootPrint("");
    }
  }

  // helper methods

  loadJQuery() {
    jQueryHelper.loadSideBarArrowButtonHideShowJQuery();
    jQueryHelper.loadDashboardJQuery();
  }

  static mappedGroupsOnCustomObject(groups) {
    const mappedGroupsObjects = groups.map((groupObj) => {
      const group_id = groupObj.id;
      const group_name = groupObj.class_name;
      const students = groupObj.church_students;

      return {
        id: group_id,
        name: group_name,
        isChecked: false,
        students: students,
      };
    });
    return mappedGroupsObjects;
  }

  static mappedClassesOnCustomObject(classes) {
    const mappedClassesObjects = classes.map((classObj) => {
      const class_id = classObj.id;
      const class_name = classObj.class_name;
      const students = classObj.school_students;

      return {
        id: class_id,
        name: class_name,
        isChecked: false,
        students: students,
      };
    });
    return mappedClassesObjects;
  }

  fetchCurrentUserContributors() {
    if (this.props.fetchCurrentUserContributors) {
      this.props.fetchCurrentUserContributors();
    }
  }

  search() {
    const { match } = this.props;

    let searchQuery = "";
    if (match && match.params && match.params.searchQuery) {
      const { footPrintsPageSize } = this.state;
      searchQuery = match.params.searchQuery;

      if (searchQuery !== this.state.searchQuery) {
        const originalSearchQuery = searchQuery;
        searchQuery = searchQuery.replaceAll("#", " ");

        this.resetPagination();
        this.fetchSearchedFootPrints(
          searchQuery,
          originalSearchQuery,
          1,
          footPrintsPageSize,
        );
      }
    }
  }

  loadMoreFootprints() {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.scrollingElement.scrollHeight
    ) {
      if (!this.state.fetchingSearchedFootPrints && this.state.hasNextPage) {
        const page = this.footPrintsCurrentPage + 1;
        this.footPrintsCurrentPage = page;
        const pageSize = this.state.footPrintsPageSize;
        this.fetchSearchedFootPrints(this.state.searchQuery, page, pageSize);
      }
    }
  }

  fetchSearchedFootPrints(searchQuery, originalSearchQuery, page, pageSize) {
    this.setState({
      searchQuery: originalSearchQuery,
      fetchingSearchedFootPrints: true,
    });

    footPrintsApi.fetchSearchedFootPrints(searchQuery, page, pageSize).then(
      (response) => {
        let footPrints = this.state.searchedFootPrints;
        const hasNextPage = response.next ? true : false;

        if (
          JSON.stringify(this.state.searchedFootPrints) !==
          JSON.stringify(response.results)
        ) {
          footPrints = [].concat.apply(
            this.state.searchedFootPrints,
            response.results,
          );
        }

        this.setState({
          fetchingSearchedFootPrints: false,
          searchedFootPrintsFetched: true,
          searchedFootPrintsResponse: response,
          searchedFootPrints: footPrints,
          failedToSearchFootPrints: false,
          hasNextPage: hasNextPage,
        });
      },
      (error) => {
        this.setState({
          fetchingSearchedFootPrints: false,
          searchedFootPrintsFetched: false,
          failedToSearchFootPrints: true,
        });
      },
    );
  }

  resetPagination() {
    this.footPrintsCurrentPage = 1;

    this.setState({
      hasNextPage: true,
      searchedFootPrints: [],
    });
  }

  footPrintUpdated(footPrint) {
    const footPrints = [...this.state.searchedFootPrints];

    const index = footPrints.findIndex((fp) => {
      return fp.id === footPrint.id;
    });

    if (index >= 0) {
      footPrints[index] = {};
    }

    this.setState({
      footPrintToUpdate: null,
      searchedFootPrints: footPrints,
    });

    setTimeout(() => {
      footPrints[index] = footPrint;
      this.setState({
        footPrintToUpdate: null,
        searchedFootPrints: footPrints,
      });
    }, 100);
  }

  userDidCancelToUpdateFootPrint() {
    this.setState({
      footPrintToUpdate: null,
    });
  }

  // buttons action methods

  editFootPrintClicked(footPrint) {
    if (footPrint.id) {
      this.setState({
        footPrintToUpdate: footPrint,
      });
    }
  }

  deleteFootPrintClicked(footPrint) {
    if (footPrint.id) {
      this.setState({
        isDeletingFootPrint: true,
        deletingFootPrintId: footPrint.id,
      });
      footPrintsApi.deleteFootPrint(footPrint.id).then(
        (status) => {
          const { searchedFootPrints } = this.state;
          const filteredFootPrints = searchedFootPrints.filter(
            (footPrintObj) => {
              return footPrintObj.id !== footPrint.id;
            },
          );

          this.setState({
            searchedFootPrints: filteredFootPrints,
            isDeletingFootPrint: false,
            deletingFootPrintId: 0,
          });
          if (filteredFootPrints.length === 0 && this.props.searchFootPrint) {
            this.props.searchFootPrint("");
          }
        },
        (error) => {
          this.setState({
            isDeletingFootPrint: false,
            deletingFootPrintId: 0,
          });
        },
      );
    }
  }

  // render

  render() {
    const {
      fetchingSearchedFootPrints,
      searchedFootPrintsFetched,
      searchedFootPrints,
      failedToSearchFootPrints,

      isDeletingFootPrint,
      deletingFootPrintId,

      allClasses,
      allGroups,
      children,

      footPrintToUpdate,
    } = this.state;

    // if (!this.props.searchQuery) {
    //   return <Redirect to='/' />;
    // }

    const isStudent = loggedInUserHelper.isStudent();
    const isParent = loggedInUserHelper.isParents();
    const isPastor = loggedInUserHelper.isPastor();
    const isChurchPrincipal = loggedInUserHelper.isChurchPrincipal();
    const isTeacher = loggedInUserHelper.isTeacher();
    const isSchoolPrincipal = loggedInUserHelper.isSchoolPrincipal();

    return (
      <div className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-md-7 col-lg-7 col-xl-8'>
              <FootPrintsListView
                fetchingFootPrints={
                  searchedFootPrints.length >=
                  mixConstants.constantValues.FOOT_PRINTS_PAGE_SIZE
                    ? false
                    : fetchingSearchedFootPrints
                }
                isLoadingMore={
                  searchedFootPrints.length >=
                    mixConstants.constantValues.FOOT_PRINTS_PAGE_SIZE &&
                  fetchingSearchedFootPrints
                }
                footPrintsFetched={searchedFootPrintsFetched}
                footPrints={searchedFootPrints}
                failedToFetchFootPrints={failedToSearchFootPrints}
                currentPage={this.footPrintsCurrentPage}
                isDeletingFootPrint={isDeletingFootPrint}
                deletingFootPrintId={deletingFootPrintId}
                onEdit={(footPrint) => this.editFootPrintClicked(footPrint)}
                onDelete={(footPrint) => this.deleteFootPrintClicked(footPrint)}
              />
              {footPrintToUpdate ? (
                isStudent ? (
                  <CreateStudentFootprintModalView
                    onFootPrintUpdated={(footPrint) =>
                      this.footPrintUpdated(footPrint)
                    }
                    footPrintToUpdate={footPrintToUpdate}
                    onClose={() => this.userDidCancelToUpdateFootPrint()}
                  />
                ) : (
                  <CreateFootprintModalView
                    classesGroups={
                      isTeacher || isSchoolPrincipal
                        ? allClasses
                        : isPastor || isChurchPrincipal
                        ? allGroups
                        : null
                    }
                    children={isParent ? children : null}
                    onFootPrintUpdated={(footPrint) =>
                      this.footPrintUpdated(footPrint)
                    }
                    footPrintToUpdate={footPrintToUpdate}
                    onClose={() => this.userDidCancelToUpdateFootPrint()}
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    fetchingSearchedFootPrints,
    searchedFootPrintsFetched,
    searchedFootPrintsResponse,
    failedToSearchFootPrints,
  } = state.fetchSearchedFootPrints;

  const {
    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,
  } = state.fetchCurrentUserContributors;

  const { searchQuery } = state.searchFootPrint;

  return {
    fetchingSearchedFootPrints,
    searchedFootPrintsFetched,
    searchedFootPrintsResponse,
    failedToSearchFootPrints,

    searchQuery,

    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,
  };
}

const actionCreators = {
  fetchSearchedFootPrints: footPrintsActions.fetchSearchedFootPrints,
  searchFootPrint: footPrintsActions.searchFootPrint,
  fetchCurrentUserContributors: userActions.fetchCurrentUserContributors,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(SearchedFootPrintsResultsPage);
