import authConstants from "../constants/auth-constants";
import authApi from "../../apis/auth-api";
import history from "../../helpers/history";

const authActions = {
  login,
  clearLogin,
  logout,
  register,
  passwordReset,
  clearPasswordReset,
};

/**
 *  Login
 */

function login(email, password, redirectToPath) {
  return (dispatch) => {
    dispatch(request({ email }));
    authApi.login(email, password).then(
      (user) => {
        dispatch(success(user));
        history.push(redirectToPath);
        if (redirectToPath !== "/") {
          window.location.reload();
        }
      },
      (error) => {
        var errorString = "";
        if (error.detail && error.detail !== "") {
          errorString = error.detail;
        }
        if (error.non_field_errors && error.non_field_errors.length > 0) {
          errorString = errorString + "\n" + error.non_field_errors[0];
        }
        if (errorString === "") {
          errorString = "Failed to login";
        }
        dispatch(failure(errorString));
      }
    );
  };

  function request(user) {
    return { type: authConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

/**
 *  Clear Login
 */

function clearLogin() {
  return { type: authConstants.LOGIN_CLEAR };
}

/**
 *  Logout
 */

function logout() {
  authApi.logout();
  return { type: authConstants.LOGOUT };
}

/**
 *  Register
 */

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    authApi.register(user).then(
      (user) => {
        dispatch(success());
        history.push("/login");
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: authConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: authConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: authConstants.REGISTER_FAILURE, error };
  }
}

/**
 *  Password Reset
 */

function passwordReset(email) {
  return (dispatch) => {
    dispatch(request({ email }));

    authApi.passwordReset(email).then(
      (response) => {
        dispatch(success(email));
      },
      (error) => {
        var errorString = "";
        if (error.detail && error.detail !== "") {
          errorString = error.detail;
        }
        if (error.email && error.email.length > 0) {
          errorString = errorString + "\n" + error.email[0];
        }
        if (error.non_field_errors && error.non_field_errors.length > 0) {
          errorString = errorString + "\n" + error.non_field_errors[0];
        }
        if (errorString === "") {
          errorString = "Failed to send password reset link";
        }
        dispatch(failure(errorString));
      }
    );
  };

  function request(email) {
    return { type: authConstants.PASSWORD_RESET_REQUEST, email };
  }
  function success(email) {
    return { type: authConstants.PASSWORD_RESET_SUCCESS, email };
  }
  function failure(error) {
    return { type: authConstants.PASSWORD_RESET_FAILURE, error };
  }
}

/**
 *  Clear Password Reset
 */

function clearPasswordReset() {
  return { type: authConstants.PASSWORD_RESET_CLEAR };
}

export default authActions;
