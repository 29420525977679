import userConstants from "../constants/user-constants";

const initialState = { userProfile: {} };

export default function fetchUserProfileReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.FETCH_USER_PROFILE_REQUEST:
      return {
        fetchingUserProfile: true,
      };
    case userConstants.FETCH_USER_PROFILE_SUCCESS:
      return {
        userProfileFetched: true,
        userProfile: action.userProfile,
        failedToFetchUserProfile: false,
      };
    case userConstants.FETCH_USER_PROFILE_FAILURE:
      return {
        userProfileFetched: false,
        failedToFetchUserProfile: true,
      };
    case userConstants.FETCH_USER_PROFILE_CLEAR:
      return {};
    default:
      return state;
  }
}
