import footPrintsConstants from "../constants/footprints-constants";

const initialState = { currentUserFootPrints: [] };

export default function fetchCurrentUserFootPrintsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_REQUEST:
      return {
        fetchingCurrentUserFootPrints: true,
      };
    case footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_SUCCESS:
      return {
        currentUserFootPrintsFetched: true,
        currentUserFootPrintsResponse: action.response,
        failedToFetchCurrentUserFootPrints: false,
      };
    case footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_FAILURE:
      return {
        currentUserFootPrintsFetched: false,
        failedToFetchCurrentUserFootPrints: true,
      };
    case footPrintsConstants.FETCH_CURRENT_USER_FOOTPRINTS_CLEAR:
      return {};
    default:
      return state;
  }
}
