import config from "./api-config";
import authHeader from "../helpers/auth-header";
import authApi from "./auth-api";

const reflectionsApi = {
  fetchReflections,
  postReflection,
  editReflection,
  deleteReflection,
};

/**
 *  Fetch reflections
 */

async function fetchReflections(postId, studentId) {
  const header = authHeader();
  const requestOptions = {
    method: "GET",
    headers: header,
  };

  let url = `${config.BaseURL}/api/comment/?post_id=${postId}&student_id=${studentId}`;

  const response = await fetch(url, requestOptions);
  const footPrints = await handleResponse(response);
  return footPrints;
}

/**
 *  Post reflection
 */

async function postReflection(reflectionToPost) {
  const header = authHeader();

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(reflectionToPost),
  };

  const url = `${config.BaseURL}/api/comment/`;
  const response = await fetch(url, requestOptions);
  const reflection = await handleResponse(response);

  return reflection;
}

/**
 *  Edit reflection
 */

async function editReflection(updatedReflectionText, reflectionId) {
  const header = authHeader();

  const reflectionToUpdate = {
    reflection: updatedReflectionText,
  };
  const requestOptions = {
    method: "PATCH",
    headers: header,
    body: JSON.stringify(reflectionToUpdate),
  };

  const url = `${config.BaseURL}/api/comment/${reflectionId}/`;
  const response = await fetch(url, requestOptions);
  const updatedReflection = await handleResponse(response);

  return updatedReflection;
}

/**
 *  Delete reflection
 */

async function deleteReflection(reflectionId) {
  const header = authHeader();

  const requestOptions = {
    method: "DELETE",
    headers: header,
  };

  const url = `${config.BaseURL}/api/comment/${reflectionId}/`;
  const response = await fetch(url, requestOptions);
  const deletedReflection = await handleResponse(response);

  return deletedReflection;
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        authApi.logout();
      }
      // const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }
    return data;
  });
}

export default reflectionsApi;
