import userApi from "../../apis/user-api";
import userConstants from "../constants/user-constants";

const userActions = {
  fetchCurrentUserContributors,
  clearFetchCurrentUserContributors,
  fetchUserProfile,
  clearFetchUserProfile,
  updateUserProfile,
  clearUpdateUserProfile,
};

/**
 *  Fetch user foot prints
 */

function fetchCurrentUserContributors() {
  return (dispatch) => {
    dispatch(request("Fetching..."));
    userApi.fetchCurrentUserContributors().then(
      (currentUserContributors) => {
        dispatch(success(currentUserContributors));
      },
      (error) => {
        var errorString = "";
        if (error.detail && error.detail !== "") {
          errorString = error.detail;
        }
        if (error.non_field_errors && error.non_field_errors.length > 0) {
          errorString = errorString + "\n" + error.non_field_errors[0];
        }
        if (errorString === "") {
          errorString = "Failed to fetch current user contributors";
        }
        dispatch(failure(errorString));
      }
    );
  };

  function request(message) {
    return {
      type: userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_REQUEST,
      message,
    };
  }
  function success(currentUserContributors) {
    return {
      type: userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_SUCCESS,
      currentUserContributors,
    };
  }
  function failure(error) {
    return {
      type: userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_FAILURE,
      error,
    };
  }
}

/**
 *  Clear fetch user foot prints
 */

function clearFetchCurrentUserContributors() {
  return { type: userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_CLEAR };
}

/**
 *  Fetch user profile
 */

function fetchUserProfile(userId) {
  return (dispatch) => {
    dispatch(request("Fetching..."));
    userApi.fetchUserProfile(userId).then(
      (userProfile) => {
        if (userProfile && userProfile.Error) {
          dispatch(failure(userProfile.Error));
        } else {
          dispatch(success(userProfile));
        }
      },
      (error) => {
        var errorString = "";
        if (error.error && error.error !== "") {
          errorString = error.detail;
        }
        if (errorString === "") {
          errorString = "Failed to fetch user profile";
        }
        dispatch(failure(errorString));
      }
    );
  };

  function request(message) {
    return {
      type: userConstants.FETCH_USER_PROFILE_REQUEST,
      message,
    };
  }
  function success(userProfile) {
    return {
      type: userConstants.FETCH_USER_PROFILE_SUCCESS,
      userProfile,
    };
  }
  function failure(error) {
    return {
      type: userConstants.FETCH_USER_PROFILE_FAILURE,
      error,
    };
  }
}

/**
 *  Clear fetch user foot prints
 */

function clearFetchUserProfile() {
  return { type: userConstants.FETCH_USER_PROFILE_CLEAR };
}

/**
 *  Update User Profile
 */

function updateUserProfile(userId, profileToUpdate, profilePic) {
  return (dispatch) => {
    dispatch(request("Updating user profile..."));
    userApi.updateUserProfile(userId, profileToUpdate, profilePic).then(
      (updatedUserProfile) => {
        dispatch(success(updatedUserProfile));
      },
      (error) => {
        var errorString = "";
        if (error.detail && error.detail !== "") {
          errorString = error.detail;
        }
        if (error.non_field_errors && error.non_field_errors.length > 0) {
          errorString = errorString + "\n" + error.non_field_errors[0];
        }
        if (errorString === "") {
          errorString = "Failed to update user profile";
        }
        dispatch(failure(errorString));
      }
    );
  };

  function request(message) {
    return {
      type: userConstants.UPDATE_USER_PROFILE_REQUEST,
      message,
    };
  }
  function success(updatedUserProfile) {
    return {
      type: userConstants.UPDATE_USER_PROFILE_SUCCESS,
      updatedUserProfile,
    };
  }
  function failure(error) {
    return {
      type: userConstants.UPDATE_USER_PROFILE_FAILURE,
      error,
    };
  }
}

/**
 *  Clear Update User Profile
 */

function clearUpdateUserProfile() {
  return { type: userConstants.UPDATE_USER_PROFILE_CLEAR };
}

export default userActions;
