import React, { Component } from "react";
import NameInitialsAvatar from "./name-initials-avatar";
import FootPrintReflectionMenu from "./footprint-reflection-menu";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import utils from "../../helpers/utils";
// import Moment from "moment";

class FootPrintReflection extends Component {
  // component lifecycle methods

  // helper methods

  // buttons action methods

  editReflectionClicked(reflection) {
    if (this.props.onEdit) {
      this.props.onEdit(reflection);
    }
  }

  deleteReflectionClicked(reflection) {
    if (this.props.onDelete) {
      this.props.onDelete(reflection);
    }
  }

  // render

  render() {
    const { reflection, postingReflection } = this.props;

    let reflectionText = "";
    let reflectionCreatorName = "";
    let reflectionCreatorProfilePicUrl = null;
    let reflectionCreatedAt = "";
    let reflectionCreatorId = -1;
    const currentUserId = loggedInUserHelper.id();

    if (reflection.reflection) {
      reflectionText = reflection.reflection;
    }
    if (reflection.created_by_name) {
      reflectionCreatorName = reflection.created_by_name;
    }
    if (reflection.created_by_profile_picture) {
      reflectionCreatorProfilePicUrl = reflection.created_by_profile_picture;
    }
    // if (reflection.created_at) {
    //   reflectionCreatedAt = Moment(reflection.created_at)
    //     .startOf("day")
    //     .fromNow();
    // }
    if (reflection.updated_time) {
      reflectionCreatedAt = reflection.updated_time;
    }
    if (reflection.created_by) {
      reflectionCreatorId = reflection.created_by;
    }

    return (
      <div className="card comment-card">
        <div className="media">
          {reflectionCreatorProfilePicUrl ? (
            <img
              className="mr-3 rounded-circle sm-img"
              alt="Avatar Preview"
              src={reflectionCreatorProfilePicUrl}
            />
          ) : (
            <NameInitialsAvatar
              name={reflectionCreatorName}
              backgroundColor={
                reflection.created_by_bg_color
                  ? reflection.created_by_bg_color
                  : utils.userAvatarBackgroundColor()
              }
            />
          )}
          <div className="media-body">
            <div className="row">
              <div className="col-8 d-flex">
                <h5 className="mt-1">{reflectionCreatorName}</h5>
              </div>
              <div className="col-4">
                <div className="pull-right reply">
                  <span className="light-gray-txt comment-date">
                    {" "}
                    {reflectionCreatedAt}{" "}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-1">
                <div className="comment-buuble comment-buuble py-2 px-3">
                  <span style={{ whiteSpace: "pre-line" }}>
                    {reflectionText}
                    {/* <a href="" className="btn btn-link" onClick="">Show More</a> */}
                  </span>
                  <div className="pull-right ref-menu-btn">
                    {currentUserId === reflectionCreatorId ? (
                      <FootPrintReflectionMenu
                        reflection={reflection}
                        postingReflection={postingReflection}
                        onEdit={(reflection) =>
                          this.editReflectionClicked(reflection)
                        }
                        onDelete={(reflection) =>
                          this.deleteReflectionClicked(reflection)
                        }
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FootPrintReflection;
