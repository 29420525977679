import userConstants from "../constants/user-constants";

const initialState = { updatedUserProfile: {} };

export default function updateUserProfileReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.UPDATE_USER_PROFILE_REQUEST:
      return {
        updatingUserProfile: true,
      };
    case userConstants.UPDATE_USER_PROFILE_SUCCESS:
      return {
        userProfileUpdated: true,
        updatedUserProfile: action.updatedUserProfile,
        updateUserProfileResponseMessage: "User updated successfully",
      };
    case userConstants.UPDATE_USER_PROFILE_FAILURE:
      return {
        userProfileUpdated: false,
        updateUserProfileResponseMessage: action.error,
      };
    case userConstants.UPDATE_USER_PROFILE_CLEAR:
      return initialState;
    default:
      return state;
  }
}
