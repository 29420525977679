import config from "./api-config";
import authHeader from "../helpers/auth-header";
import authApi from "./auth-api";

const userApi = {
  fetchCurrentUserContributors,
  fetchUserProfile,
  updateUserProfile,
};

/**
 *  Get contributors
 */

async function fetchCurrentUserContributors() {
  const header = authHeader();
  const requestOptions = {
    method: "GET",
    headers: header,
  };

  const url = `${config.BaseURL}/api/contributors`;

  const response = await fetch(url, requestOptions);
  const contributors = await handleResponse(response);
  return contributors;
}

/**
 *  Get user profile
 */

async function fetchUserProfile(userId) {
  const header = authHeader();
  const requestOptions = {
    method: "GET",
    headers: header,
  };

  const url = `${config.BaseURL}/api/user-profile/${userId}/`;

  const response = await fetch(url, requestOptions);
  const userProfile = await handleResponse(response);
  return userProfile;
}

/**
 *  Update User Profile
 */

async function updateUserProfile(userId, profileToUpdate, profilePic) {
  const formData = new FormData();

  for (var key in profileToUpdate) {
    if (key === "hobbies" || key === "activities") {
      formData.append(key, JSON.stringify(profileToUpdate[key]));
    } else {
      formData.append(key, profileToUpdate[key]);
    }
  }
  if (profilePic !== "") {
    formData.append("profile_picture", profilePic, profilePic.name);
  }
  const header = authHeader();
  const requestOptions = {
    method: "PATCH",
    headers: header,
    body: formData,
  };
  delete requestOptions.headers["Content-Type"];

  const url = `${config.BaseURL}/api/user-profile/${userId}/`;

  const response = await fetch(url, requestOptions);
  const updatedProfile = await handleResponse(response);
  return updatedProfile;
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        authApi.logout();
      }
      // const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }
    return data;
  });
}

export default userApi;
