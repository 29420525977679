const footPrintsConstants = {
  FETCH_CURRENT_USER_FOOTPRINTS_REQUEST:
    "FETCH_CURRENT_USER_FOOTPRINTS_REQUEST",
  FETCH_CURRENT_USER_FOOTPRINTS_SUCCESS:
    "FETCH_CURRENT_USER_FOOTPRINTS_SUCCESS",
  FETCH_CURRENT_USER_FOOTPRINTS_FAILURE:
    "FETCH_CURRENT_USER_FOOTPRINTS_FAILURE",
  FETCH_CURRENT_USER_FOOTPRINTS_CLEAR: "FETCH_CURRENT_USER_FOOTPRINTS_CLEAR",

  FETCH_SEARCHED_FOOTPRINTS_REQUEST: "FETCH_SEARCHED_FOOTPRINTS_REQUEST",
  FETCH_SEARCHED_FOOTPRINTS_SUCCESS: "FETCH_SEARCHED_FOOTPRINTS_SUCCESS",
  FETCH_SEARCHED_FOOTPRINTS_FAILURE: "FETCH_SEARCHED_FOOTPRINTS_FAILURE",
  FETCH_SEARCHED_FOOTPRINTS_CLEAR: "FETCH_SEARCHED_FOOTPRINTS_CLEAR",

  SEARCH_FOOTPRINT: "SEARCH_FOOTPRINT",
};

export default footPrintsConstants;
