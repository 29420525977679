import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import persistence from "../../helpers/persistence";
import userActions from "../../redux/actions/user-actions";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";
import jQueryHelper from "../../helpers/jquery-helper";
import utils from "../../helpers/utils";
import history from "../../helpers/history";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import SnackbarView from "../../components/common/snackbar-view";
import Fade from "@material-ui/core/Fade";
import mixConstants from "../../constants/mix-constants";
import LinearProgress from "@material-ui/core/LinearProgress";

class EditUserProfilePage extends Component {
  constructor() {
    super();

    this.originalProfileData = null;

    this.state = {
      dataAlreadyPopulated: false,
      userId: "",
      profilePic: "",
      profilePicUrl: "",
      profilePicFile: "",
      about: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      address: "",
      city: "",
      state: "",
      country: "",

      hobby: "",
      activity: "",
      hobbies: [],
      activities: [],

      submitted: false,
      showMessage: false,
      message: "",
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleProfilePicSelection = this.handleProfilePicSelection.bind(this);
  }

  // component lifecycle methods

  componentDidMount() {
    this.loadJQuery();

    if (persistence.getCurrentUserProfileDataToEdit()) {
      this.originalProfileData = persistence.getCurrentUserProfileDataToEdit();
      this.renderOriginalProfileData();
    }
  }

  componentWillUnmount() {
    if (this.props.clearUpdateUserProfile) {
      this.props.clearUpdateUserProfile();
    }
  }

  // helper methods

  loadJQuery() {
    jQueryHelper.loadSideBarArrowButtonHideShowJQuery();
  }

  renderOriginalProfileData() {
    this.setState({
      userId: this.originalProfileData.userId,
      profilePicUrl: this.originalProfileData.profilePicUrl,
      profilePicFile: "",
      profilePic: "",
      about: this.originalProfileData.about,
      firstName: this.originalProfileData.firstName,
      lastName: this.originalProfileData.lastName,
      phoneNumber: this.originalProfileData.phoneNumber,
      address: this.originalProfileData.address,
      city: this.originalProfileData.city,
      state: this.originalProfileData.state,
      country: this.originalProfileData.country,
      hobbies: this.originalProfileData.hobbies,
      activities: this.originalProfileData.activities,
      hobby: "",
      activity: "",
      submitted: false,
    });
  }

  renderHobbiesChipViews(hobbies) {
    return hobbies.map((hobby) => {
      return (
        <Chip
          size="small"
          key={hobby.id}
          label={hobby.text}
          onDelete={() => this.deleteHobbyButtonClicked(hobby)}
          disabled={this.props.updatingUserProfile}
          color="primary"
        />
      );
    });
  }

  renderActivitiesChipViews(activities) {
    return activities.map((activity) => {
      return (
        <Chip
          size="small"
          key={activity.id}
          label={activity.text}
          onDelete={() => this.deleteActivityButtonClicked(activity)}
          disabled={this.props.updatingUserProfile}
          color="primary"
        />
      );
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, submitted: false });
  }

  handleProfilePicSelection(e) {
    const file = e.target.files[0];

    if (file) {
      this.setState({
        profilePic: URL.createObjectURL(file),
        profilePicUrl: "",
        profilePicFile: file,
        submitted: false,
      });
    }
  }

  updateUserProfileInLocalStorage(updatedProfile) {
    let user = loggedInUserHelper.user();

    if (user && updatedProfile.basic_info.profile) {
      user.first_name = this.state.firstName;
      user.last_name = this.state.lastName;

      if (updatedProfile.basic_info.profile.profile_picture) {
        user.profile.profile_picture =
          updatedProfile.basic_info.profile.profile_picture;
      }
      user.profile.about = this.state.about;
      user.profile.fullname = this.state.firstName + " " + this.state.lastName;
      user.profile.phone_number = this.state.phoneNumber;
      user.profile.address = this.state.address;
      user.profile.city = this.state.city;
      user.profile.state = this.state.state;
      user.profile.country = this.state.country;
      user.profile.hobbies = this.state.hobbies;
      user.profile.activities = this.state.activities;

      persistence.setUserData(user);
      history.goBack();
    }
  }

  canUserSaveProfile() {
    if (this.state.firstName.trim() === "") {
      return false;
    }
    return true;
  }

  // buttons action methods

  handleKeyDown(e) {
    if (e.key === "Enter" && this.state.hobby.trim() !== "") {
      this.addHobbyButtonClicked();
    } else if (e.key === "Enter" && this.state.activity.trim() !== "") {
      this.addActivityButtonClicked();
    }
  }

  backButtonClicked() {
    history.goBack();
  }

  addHobbyButtonClicked() {
    let hobbies = [...this.state.hobbies];
    const hobby = {
      id: utils.generateId(),
      text: this.state.hobby,
    };
    const index = hobbies.findIndex(
      (h) =>
        h.text.toLowerCase().trim() === hobby.text.toLocaleLowerCase().trim()
    );
    if (index < 0 && hobby.text.trim() !== "") {
      hobbies.push(hobby);

      this.setState({
        hobbies: hobbies,
        hobby: "",
      });
    }
  }

  addActivityButtonClicked() {
    let activities = [...this.state.activities];
    const activity = {
      id: utils.generateId(),
      text: this.state.activity,
    };
    const index = activities.findIndex(
      (a) =>
        a.text.toLowerCase().trim() === activity.text.toLocaleLowerCase().trim()
    );
    if (index < 0 && activity.text.trim() !== "") {
      activities.push(activity);

      this.setState({
        activities: activities,
        activity: "",
      });
    }
  }

  deleteHobbyButtonClicked(hobby) {
    let hobbies = [...this.state.hobbies];
    const filteredHobbies = hobbies.filter((h) => {
      return h.id !== hobby.id;
    });

    this.setState({
      hobbies: filteredHobbies,
    });
  }

  deleteActivityButtonClicked(activity) {
    let activities = [...this.state.activities];
    const filteredActivities = activities.filter((a) => {
      return a.id !== activity.id;
    });

    this.setState({
      activities: filteredActivities,
    });
  }

  saveButtonClicked() {
    if (!this.canUserSaveProfile()) {
      return;
    }

    const hobbies = this.state.hobbies.map((hobby) => {
      return hobby.text;
    });
    const activities = this.state.activities.map((activity) => {
      return activity.text;
    });
    const profileToUpdate = {
      about: this.state.about,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      phone_number: this.state.phoneNumber,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      hobbies: hobbies,
      activities: activities,
    };
    const userId = loggedInUserHelper.id();

    if (this.props.updateUserProfile) {
      this.props.updateUserProfile(
        userId,
        profileToUpdate,
        this.state.profilePicFile
      );
    }
  }

  resetButtonClicked() {
    this.renderOriginalProfileData();
  }

  //render

  render() {
    const {
      updatingUserProfile,
      userProfileUpdated,
      updatedUserProfile,
      updateUserProfileResponseMessage,
    } = this.props;
    const { profilePic, profilePicUrl } = this.state;

    //const isStudent = loggedInUserHelper.isStudent();
    const isParent = loggedInUserHelper.isParents();

    if (userProfileUpdated) {
      this.updateUserProfileInLocalStorage(updatedUserProfile);
    }

    if (!persistence.getCurrentUserProfileDataToEdit()) {
      return <Redirect to="/login" />;
    }

    return (
      <>
        <div className="back-button">
          <svg
            onClick={() => this.backButtonClicked()}
            xmlns="http://www.w3.org/2000/svg"
            width="15.6"
            height={13}
            viewBox="0 0 15.6 13"
          >
            <defs>
              <style dangerouslySetInnerHTML={{ __html: ".a{fill:#fff;}" }} />
            </defs>
            <path
              className="a"
              d="M22.95,17.85H9.983l4.965-4.729a.65.65,0,1,0-.9-.942l-5.671,5.4a1.3,1.3,0,0,0,.011,1.85l5.659,5.39a.65.65,0,1,0,.9-.941L9.962,19.15H22.95a.65.65,0,0,0,0-1.3Z"
              transform="translate(-8 -12)"
            />
          </svg>{" "}
        </div>
        <div className="content">
          <Fade
            in={true}
            timeout={mixConstants.constantValues.TRANSITION_DURATION}
          >
            <div className="edit-profile-content">
              <div className="edit-form">
                <section className="user-fields">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="edit-about-user input-group-prepend">
                          <div className="user-avatar">
                            {profilePic ? (
                              <img src={profilePic} alt="profile-pic" />
                            ) : profilePicUrl ? (
                              <img src={profilePicUrl} alt="profile-pic" />
                            ) : (
                              <NameInitialsAvatar
                                name={
                                  this.state.firstName +
                                  " " +
                                  this.state.lastName
                                }
                                backgroundColor={loggedInUserHelper.profileBackgroundColor()}
                              />
                            )}

                            <label
                              htmlFor="browse"
                              className="edit-avater-icon"
                            >
                              <input
                                type="file"
                                id="browse"
                                accept="image/x-png,image/png,image/jpeg,image/jpg"
                                name="profilePicFile"
                                onChange={this.handleProfilePicSelection}
                                disabled={updatingUserProfile}
                                onClick={(e) => {
                                  e.target.value = null;
                                }}
                              />{" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14.122"
                                height="14.698"
                                viewBox="0 0 14.122 14.698"
                              >
                                <defs>
                                  <style
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        ".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}",
                                    }}
                                  />
                                </defs>
                                <g transform="translate(-7.588 0.25)">
                                  <g transform="translate(7.838 0)">
                                    <path
                                      className="a"
                                      d="M26.359,11.359h.077l3.7-.806a.364.364,0,0,0,.192-.1L36.6,4.183a2.418,2.418,0,0,0,.71-1.727A2.494,2.494,0,0,0,36.6.71,2.417,2.417,0,0,0,34.878,0,2.36,2.36,0,0,0,33.151.73L26.9,7a.345.345,0,0,0-.115.173l-.806,3.7a.422.422,0,0,0,.115.365A.384.384,0,0,0,26.359,11.359ZM34.878.768a1.669,1.669,0,0,1,1.669,1.669q0,.01,0,.019a1.612,1.612,0,0,1-.48,1.17l-2.36-2.379A1.65,1.65,0,0,1,34.878.768ZM33.17,1.8l2.36,2.36L30.062,9.613,27.7,7.272ZM27.395,8.04l1.9,1.9-2.437.537Z"
                                      transform="translate(-25.304 0)"
                                    />
                                    <path
                                      className="a"
                                      d="M21.076,365.717H8.222a.384.384,0,1,0,0,.767H21.076a.384.384,0,1,0,0-.767Z"
                                      transform="translate(-7.838 -352.287)"
                                    />
                                  </g>
                                </g>
                              </svg>{" "}
                            </label>
                          </div>
                          <div className="user-text-area">
                            <TextField
                              // id="outlined-multiline-static"
                              label="About Information"
                              multiline
                              fullWidth
                              rows={5}
                              variant="outlined"
                              inputProps={{ maxLength: 500 }}
                              name="about"
                              value={this.state.about}
                              onChange={this.handleInputChange}
                              disabled={updatingUserProfile}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="first-role-fields role-fields">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group user-input-area">
                        <TextField
                          // id="outlined-multiline-static"
                          label="First Name"
                          fullWidth
                          rows={5}
                          variant="outlined"
                          inputProps={{ maxLength: 15 }}
                          name="firstName"
                          value={this.state.firstName}
                          onChange={this.handleInputChange}
                          disabled={updatingUserProfile}
                          error={this.state.firstName.trim() === ""}
                          helperText={
                            this.state.firstName.trim() === ""
                              ? "First name is required"
                              : ""
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group user-input-area">
                        <TextField
                          // id="outlined-multiline-static"
                          label="Last Name"
                          fullWidth
                          rows={5}
                          variant="outlined"
                          inputProps={{ maxLength: 15 }}
                          name="lastName"
                          value={this.state.lastName}
                          onChange={this.handleInputChange}
                          disabled={updatingUserProfile}
                        />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group user-input-area">
                        <TextField
                          // id="outlined-multiline-static"
                          label="Cell Phone Number"
                          fullWidth
                          rows={5}
                          variant="outlined"
                          inputProps={{ maxLength: 20 }}
                          name="phoneNumber"
                          value={this.state.phoneNumber}
                          onChange={this.handleInputChange}
                          disabled={updatingUserProfile}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-md-12">
                      <div className="form-group user-input-area">
                        <TextField
                          // id="outlined-multiline-static"
                          label="Address"
                          fullWidth
                          rows={5}
                          variant="outlined"
                          inputProps={{ maxLength: 250 }}
                          name="address"
                          value={this.state.address}
                          onChange={this.handleInputChange}
                          disabled={updatingUserProfile}
                        />
                      </div>
                    </div>
                    {!isStudent && !isParent ? (
                      <>
                        <div className="col-md-2">
                          <div className="form-group user-input-area">
                            <TextField
                              // id="outlined-multiline-static"
                              label="City"
                              fullWidth
                              rows={5}
                              variant="outlined"
                              inputProps={{ maxLength: 25 }}
                              name="city"
                              value={this.state.city}
                              onChange={this.handleInputChange}
                              disabled={updatingUserProfile}
                            />
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="form-group user-input-area">
                            <TextField
                              // id="outlined-multiline-static"
                              label="State"
                              fullWidth
                              rows={5}
                              variant="outlined"
                              inputProps={{ maxLength: 25 }}
                              name="state"
                              value={this.state.state}
                              onChange={this.handleInputChange}
                              disabled={updatingUserProfile}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group user-input-area">
                            <TextField
                              // id="outlined-multiline-static"
                              label="Country"
                              fullWidth
                              rows={5}
                              variant="outlined"
                              inputProps={{ maxLength: 30 }}
                              name="country"
                              value={this.state.country}
                              onChange={this.handleInputChange}
                              disabled={updatingUserProfile}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div> */}
                  {!isParent ? (
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group user-input-area">
                            <TextField
                              // id="outlined-multiline-static"
                              label="Hobbies"
                              fullWidth
                              rows={5}
                              variant="outlined"
                              inputProps={{ maxLength: 30 }}
                              name="hobby"
                              value={this.state.hobby}
                              onChange={this.handleInputChange}
                              onKeyDown={this.handleKeyDown}
                              disabled={updatingUserProfile}
                            />
                            <Fab
                              color="primary"
                              aria-label="add"
                              component="span"
                              onClick={() => this.addHobbyButtonClicked()}
                              disabled={updatingUserProfile}
                            >
                              <AddIcon />
                            </Fab>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group user-input-area">
                            <TextField
                              // id="outlined-multiline-static"
                              label="Activities"
                              fullWidth
                              rows={5}
                              variant="outlined"
                              inputProps={{ maxLength: 30 }}
                              name="activity"
                              value={this.state.activity}
                              onChange={this.handleInputChange}
                              onKeyDown={this.handleKeyDown}
                              disabled={updatingUserProfile}
                            />
                            <Fab
                              color="primary"
                              aria-label="add"
                              component="span"
                              onClick={() => this.addActivityButtonClicked()}
                              disabled={updatingUserProfile}
                            >
                              <AddIcon />
                            </Fab>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="chip-box">
                            {this.renderHobbiesChipViews(this.state.hobbies)}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="chip-box">
                            {this.renderActivitiesChipViews(
                              this.state.activities
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </section>

                {updatingUserProfile ? <LinearProgress /> : ""}

                <div className="input-group-append form-footer">
                  {!userProfileUpdated && updateUserProfileResponseMessage ? (
                    <SnackbarView
                      status="error"
                      msg={updateUserProfileResponseMessage}
                      duration={5000}
                    />
                  ) : (
                    ""
                  )}

                  <button
                    type="submit"
                    className="btn blue-button btn-shadow"
                    // type="button"
                    disabled={updatingUserProfile}
                    onClick={() => this.saveButtonClicked()}
                  >
                    Save
                  </button>
                  <button
                    className="btn gray-button btn-shadow"
                    type="button"
                    disabled={updatingUserProfile}
                    onClick={() => this.resetButtonClicked()}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    updatingUserProfile,
    userProfileUpdated,
    updatedUserProfile,
    updateUserProfileResponseMessage,
  } = state.updateUserProfile;

  return {
    updatingUserProfile,
    userProfileUpdated,
    updatedUserProfile,
    updateUserProfileResponseMessage,
  };
}

const actionCreators = {
  updateUserProfile: userActions.updateUserProfile,
  clearUpdateUserProfile: userActions.clearUpdateUserProfile,
};

export default connect(mapStateToProps, actionCreators)(EditUserProfilePage);
