import React from "react";
import StudentOverviewPage from "./student-overview-page";
import TeacherOverviewPage from "./teacher-overview-page";
import PastorOverviewPage from "./pastor-overview-page";
import ParentsOverviewPage from "./parents-overview-page";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";

function DashboardPage() {
  const isSchoolPrincipal = loggedInUserHelper.isSchoolPrincipal();
  const isChurchPrincipal = loggedInUserHelper.isChurchPrincipal();
  const isStudent = loggedInUserHelper.isStudent();
  const isTeacher = loggedInUserHelper.isTeacher();
  const isPastor = loggedInUserHelper.isPastor();
  const isParent = loggedInUserHelper.isParents();

  if (isStudent) {
    return <StudentOverviewPage />;
  } else if (isTeacher || isSchoolPrincipal) {
    return <TeacherOverviewPage />;
  } else if (isPastor || isChurchPrincipal) {
    return <PastorOverviewPage />;
  } else if (isParent) {
    return <ParentsOverviewPage />;
  } else {
    return "";
  }
}

export default DashboardPage;
