import userConstants from "../constants/user-constants";

const initialState = { currentUserContributors: {} };

export default function fetchCurrentUserContributorsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_REQUEST:
      return {
        fetchingCurrentUserContributors: true,
      };
    case userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_SUCCESS:
      return {
        currentUserContributorsFetched: true,
        currentUserContributors: action.currentUserContributors,
        failedToFetchCurrentUserContributors: false,
      };
    case userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_FAILURE:
      return {
        currentUserContributorsFetched: false,
        failedToFetchCurrentUserContributors: true,
      };
    case userConstants.FETCH_CURRENT_USER_CONTRIBUTORS_CLEAR:
      return {};
    default:
      return state;
  }
}
