import React, { Component } from "react";
import CreateFootprintModalView from "./create-footprint-modal-view";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import NameInitialsAvatar from "./name-initials-avatar";
import CreateStudentFootprintModalView from "./create-student-footprint-modal-view";

class CreateFootprintView extends Component {
  constructor() {
    super();

    this.state = {
      createFootprintClicked: false,
    };
  }

  // component lifecycle methods

  componentDidMount() {}

  // helper methods

  createFootPrintModalViewDidClose() {
    this.setState((state) => {
      return {
        createFootprintClicked: !state.createFootprintClicked,
      };
    });
  }

  // buttons action methods

  createFootprintClicked() {
    this.setState((state) => {
      return {
        createFootprintClicked: !state.createFootprintClicked,
      };
    });
  }

  // render

  render() {
    const { classesGroups, children, onFootPrintCreated } = this.props;
    const profilePicUrl = loggedInUserHelper.profilePicUrl();
    const profileBgColor = loggedInUserHelper.profileBackgroundColor();
    const fullName = loggedInUserHelper.fullName();

    return (
      <div className="post-grid">
        <div
          className="footprint-creator card"
          onClick={() => this.createFootprintClicked()}
        >
          <form className="post-form create-footprint-form">
            <div className="input-group mr-sm-2">
              <div className="user-image">
                {profilePicUrl ? (
                  <img src={profilePicUrl} alt="profile-pic" />
                ) : (
                  <NameInitialsAvatar
                    name={fullName}
                    backgroundColor={profileBgColor}
                  />
                )}
              </div>
              <input
                type="text"
                className="form-control ml-2 no-focus"
                id={7}
                placeholder="Create a footprint"
              />

              <div className="input-group-prepend">
                <div className="input-group-text">
                  <div className="btn-group upload-btns p-0 mb-0" role="group">
                    <button type="button" className="btn media-upload">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={17}
                        height="14.165"
                        viewBox="0 0 17 14.165"
                      >
                        <defs>
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                ".a{fill:#fff;stroke:#fff;stroke-width:0.5px;}",
                            }}
                          />
                        </defs>
                        <g transform="translate(0.25 -43.729)">
                          <g transform="translate(0 43.979)">
                            <g transform="translate(0 0)">
                              <path
                                className="a"
                                d="M15.231,43.979H1.269A1.256,1.256,0,0,0,0,45.248V56.375a1.256,1.256,0,0,0,1.269,1.269H15.231A1.256,1.256,0,0,0,16.5,56.375V45.248A1.256,1.256,0,0,0,15.231,43.979Zm0,12.4H1.269L1.26,45.249H15.231V56.375Z"
                                transform="translate(0 -43.979)"
                              />
                            </g>
                          </g>
                          <g transform="translate(0.259 47.787)">
                            <g transform="translate(0 0)">
                              <path
                                className="a"
                                d="M14.075,162.361a.635.635,0,0,0-.864-.106l-5.183,3.808.752,1.023,4.7-3.453,6.684,8.064.977-.81Z"
                                transform="translate(-8.028 -162.132)"
                              />
                            </g>
                          </g>
                          <g transform="translate(8.616 48.443)">
                            <g transform="translate(0 0)">
                              <path
                                className="a"
                                d="M271.162,182.626a.635.635,0,0,0-.789,0l-3,2.39.79.993,2.61-2.077,3.455,2.734.787-1Z"
                                transform="translate(-267.369 -182.489)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </button>
                    <button type="button" className="btn google-drive">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.333"
                        height="14.291"
                        viewBox="0 0 16.333 14.291"
                      >
                        <defs>
                          <style
                            dangerouslySetInnerHTML={{
                              __html:
                                ".a{fill:#4caf50;}.b{fill:#ffc107;}.c{fill:#2196f3;}",
                            }}
                          />
                        </defs>
                        <g transform="translate(0 -1.5)">
                          <path
                            className="a"
                            d="M8.211,6.012,5.6,1.5,0,11.277l2.571,4.514Z"
                          />
                          <path
                            className="b"
                            d="M8.222,1.5l5.655,9.777H18.96L13.364,1.5Z"
                            transform="translate(-2.627)"
                          />
                          <path
                            className="c"
                            d="M6.381,15.867l-2.6,4.514H14.969l2.571-4.514Z"
                            transform="translate(-1.207 -4.59)"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {this.state.createFootprintClicked ? (
          loggedInUserHelper.isStudent() ? (
            <CreateStudentFootprintModalView
              onClose={() => this.createFootPrintModalViewDidClose()}
              onFootPrintCreated={onFootPrintCreated}
            />
          ) : (
            <CreateFootprintModalView
              classesGroups={classesGroups}
              children={children}
              onClose={() => this.createFootPrintModalViewDidClose()}
              onFootPrintCreated={onFootPrintCreated}
            />
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CreateFootprintView;
