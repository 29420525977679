import config from "./api-config";
import persistence from "../helpers/persistence";
import history from "../helpers/history";
import authHeader from "../helpers/auth-header";
import loggedInUserHelper from "../helpers/logged-in-user-helper";

const authApi = {
  login,
  logout,
  logoutWithOutRefresh,
  register,
  passwordReset,
  passwordChange
};

/**
 *  Login
 */

async function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };
  const url = `${config.BaseURL}/api/auth/login/`;
  const response = await fetch(url, requestOptions);
  const user = await handleResponse(response);

  if (user.token) {
    persistence.setUserToken(user.token);
  }
  if (user.user) {
    persistence.setUserData(user.user);
    return user.user;
  }
  return user;
}

/**
 *  Logout
 */

function logout() {
  persistence.removeUserToken();
  persistence.removeUserData();
  persistence.removefootPrintDraft();
  history.push("/login");
  window.location.reload();
}

function logoutWithOutRefresh() {
  persistence.removeUserToken();
  persistence.removeUserData();
  persistence.removefootPrintDraft();
}

/**
 *  Register
 */

async function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  const url = `${config.BaseURL}/api/user/register`;
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}

/**
 *  Password Reset
 */

async function passwordReset(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };
  const url = `${config.BaseURL}/api/auth/password/reset/`;
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}

/**
 *  Password Change
 */

async function passwordChange(oldPassword, newPassword, confirmNewPassword) {
  const header = authHeader();
  const body = {
    "old_password": oldPassword,
    "new_password": newPassword,
    "confirm_password": confirmNewPassword
  }

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(body),
  };

  const userId = loggedInUserHelper.id();
  const url = `${config.BaseURL}/api/users/${userId}/set_password/`;
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}

/**
 *  Response Handler
 */

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
      }
      // const error = (data && data.message) || response.statusText;
      return Promise.reject(data);
    }
    return data;
  });
}

export default authApi;
