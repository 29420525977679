import footPrintsConstants from "../constants/footprints-constants";

const initialState = { currentUserFootPrints: [] };

export default function fetchSearchedFootPrintsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_REQUEST:
      return {
        fetchingSearchedFootPrints: true,
      };
    case footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_SUCCESS:
      return {
        searchedFootPrintsFetched: true,
        searchedFootPrintsResponse: action.response,
        failedToSearchFootPrints: false,
      };
    case footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_FAILURE:
      return {
        searchedFootPrintsFetched: false,
        failedToSearchFootPrints: true,
      };
    case footPrintsConstants.FETCH_SEARCHED_FOOTPRINTS_CLEAR:
      return {};
    default:
      return state;
  }
}
