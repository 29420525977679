const authConstants = {
  REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USER_REGISTER_FAILURE",
  REGISTER_CLEAR: "REGISTER_CLEAR",

  LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  LOGIN_CLEAR: "LOGIN_CLEAR",

  PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
  PASSWORD_RESET_CLEAR: "PASSWORD_RESET_CLEAR",

  LOGOUT: "USER_LOGOUT",
};

export default authConstants;
