import { Chip, CircularProgress } from "@material-ui/core";
import { useDrag, useDrop } from "react-dnd";
import utils from "../../helpers/utils";

const ItemType = "FILE"; // Define the type of draggable item

const FileItem = ({
  file,
  index,
  moveFile,
  onDelete,
  disabled,
  userIndexInUsersArray,
}) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index }, // Pass the index of the file being dragged
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveFile(draggedItem.index, index,userIndexInUsersArray); // Reorder when hovered
        draggedItem.index = index;
      }
    },
  });
  const fileId = file.id;
  let name = file.fileName;
  const uploadPercentage = file.uploadPercentage;
  let uploaded = file.uploaded;
  let fileIcon = file.fileIcon;

  if (file.faith_footprint) {
    // from edit footprint
    uploaded = true;
    name = utils.extractFileNameFromURL(file.faith_footprint); //file.faith_footprint.split("/").pop();
    const ext = file.faith_footprint.split(".").pop();
    const fileType = utils.fileType(ext);
    fileIcon = utils.fileIcon(fileType);
  }
  return (
    <li ref={(node) => ref(drop(node))} key={fileId}>
      <Chip
        key={fileId}
        clickable={false}
        icon={
          uploaded ? (
            fileIcon
          ) : (
            <CircularProgress value={uploadPercentage} variant="static" />
          )
        }
        label={name}
        color="primary"
        onDelete={onDelete}
        disabled={disabled}
      />
    </li>
  );
};
export default FileItem;
