import React, { Component } from "react";
import LoaderView from "../common/loader-view";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";
import utils from "../../helpers/utils";

class ChildrensListView extends Component {
  // component lifecycle methods

  // helper methods

  renderChildrens(childrens) {
    if (childrens.length > 0) {
      const mappedChildrens = childrens.map((children) => {
        const name = children.name ? children.name : "";
        const roleName = utils.capitalize(children.role[0]);
        const footprintsCount = children.footprints;
        let profilePicture = "";
        if (children.profile_picture) {
          //profilePicture = apiConfig.BaseURL + children.profile_picture;
          profilePicture = children.profile_picture;
        }

        return (
          <li key={utils.generateId()}>
            <div className="userImage-name">
              <span className="user-avatar">
                {profilePicture !== "" ? (
                  <img src={profilePicture} alt="profile-pic" />
                ) : (
                  <NameInitialsAvatar
                    name={name}
                    backgroundColor={
                      children.bg_color
                        ? children.bg_color
                        : utils.userAvatarBackgroundColor()
                    }
                  />
                )}
              </span>
              <span className="user-details">
                <strong className="user-name"> {name} </strong>
                <small className="user-role"> {roleName} </small>
              </span>
            </div>
            <div className="footprints">
              <span className="footprints-text">footprints</span>
              <span className="footprints-numbers">{footprintsCount}</span>
            </div>
          </li>
        );
      });
      return mappedChildrens;
    } else {
      return <div> No children found </div>;
    }
  }

  // buttons action methods

  // render

  render() {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
    } = this.props;

    let allChildrens = [];

    if (contributors && contributors.children) {
      allChildrens = contributors.children;
    }

    return (
      <div className="users-container">
        <h1 className="container-title"> Children </h1>
        <ul className="users-info">
          {fetchingContributors ? (
            <div className="loader-new-post">
              <LoaderView width={50} height={50} />
            </div>
          ) : contributors && contributorsFetched ? (
            this.renderChildrens(allChildrens)
          ) : failedToFetchContributors ? (
            "Failed to fetch children"
          ) : (
            ""
          )}
        </ul>
      </div>
    );
  }
}

export default ChildrensListView;
