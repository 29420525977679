import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import mixConstants from "../../constants/mix-constants";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import { Icon } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
    width: 30,
    height: 30,
    minHeight: 30,
  },
}));

// const HtmlTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }))(Tooltip);

export default function CreateFootPrintInfo() {
  const classes = useStyles();
  const longText =
    "Supported file types are: " +
    mixConstants.constantValues.ACCEPTABLE_FILES_List;
  return (
    <div className="info-btn">
      <Tooltip disableFocusListener title={longText} arrow>
        <Fab
          color="primary"
          className={classes.fab}
          style={{ background: "#fff", color: '#0080c1' }}
        >
          <Icon className="fa fa-info" style={{ fontSize: 14 }}></Icon>
        </Fab>
      </Tooltip>
    </div>
  );
}
