import React, { Component } from "react";
import LoaderView from "../common/loader-view";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";
import utils from "../../helpers/utils";
import Fade from "@material-ui/core/Fade";
import mixConstants from "../../constants/mix-constants";

class ContributorsListView extends Component {
  // component lifecycle methods

  // helper methods

  renderContributors(contributors) {
    if (contributors.length > 0) {
      const mappedContributors = contributors.map((contributor) => {
        const name = contributor.name ? contributor.name : "";
        const roleName = utils.capitalize(contributor.role[0]);
        const footprintsCount = contributor.footprints;
        let profilePicture = "";
        if (contributor.profile_picture) {
          //profilePicture = apiConfig.BaseURL + contributor.profile_picture;
          profilePicture = contributor.profile_picture;
        }

        return (
          <Fade
            key={utils.generateId()}
            in={true}
            timeout={mixConstants.constantValues.TRANSITION_DURATION}
          >
            <li>
              <div className="userImage-name">
                <span className="user-avatar">
                  {profilePicture !== "" ? (
                    <img src={profilePicture} alt="profile-pic" />
                  ) : (
                    <NameInitialsAvatar
                      name={name}
                      backgroundColor={
                        contributor.bg_color
                          ? contributor.bg_color
                          : utils.userAvatarBackgroundColor()
                      }
                    />
                  )}
                </span>
                <span className="user-details">
                  <strong className="user-name"> {name} </strong>
                  <small className="user-role"> {roleName} </small>
                </span>
              </div>
              <div className="footprints">
                <span className="footprints-text">footprints</span>
                <span className="footprints-numbers">{footprintsCount}</span>
              </div>
            </li>
          </Fade>
        );
      });
      return mappedContributors;
    } else {
      return <div> No contributors found </div>;
    }
  }

  // buttons action methods

  // render

  render() {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
    } = this.props;

    let allContributors = [];
    if (contributors && contributors.church_pastors) {
      contributors.church_pastors.forEach((contributor) => {
        allContributors.push(contributor);
      });
    }
    if (contributors && contributors.parents) {
      contributors.parents.forEach((contributor) => {
        allContributors.push(contributor);
      });
    }
    if (contributors && contributors.school_teachers) {
      contributors.school_teachers.forEach((contributor) => {
        allContributors.push(contributor);
      });
    }

    return (
      <div className="users-container contains-role">
        <h1 className="container-title"> Contributors </h1>
        <ul className="users-info">
          {fetchingContributors ? (
            <div className="loader-new-post">
              <LoaderView width={50} height={50} />
            </div>
          ) : contributors && contributorsFetched ? (
            this.renderContributors(allContributors)
          ) : failedToFetchContributors ? (
            "Failed to fetch contributors"
          ) : (
            ""
          )}
        </ul>
      </div>
    );
  }
}

export default ContributorsListView;
