import footPrintsConstants from "../constants/footprints-constants";

const initialState = {};

export default function searchFootPrintReducer(state = initialState, action) {
  switch (action.type) {
    case footPrintsConstants.SEARCH_FOOTPRINT:
      return {
        searchQuery: action.searchQuery,
      };

    default:
      return state;
  }
}
