import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/images/app-logo.svg";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import $ from "jquery";
import footPrintsActions from "../../redux/actions/footprints-actions";
// import authApi from "../../apis/auth-api";

class DashboardHeader extends Component {
  constructor() {
    super();

    this.state = {
      searchQuery: "",
      update: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  // component lifecycle methods

  componentDidMount() {
    const { location } = this.props;
    const searchQuery = location.pathname.split("/")[2];

    if (searchQuery) {
      this.setState({ searchQuery });
    }

    this.loadJQuery();
  }

  // helper methods

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (this.props.searchFootPrint) {
      this.props.searchFootPrint(value);
    }
  }

  clearSearchInput() {
    this.setState({
      searchQuery: "",
    });
  }

  // buttons action methods

  // logoutButtonClicked() {
  //   authApi.logout();
  // }

  logoClicked() {
    window.location.reload();
  }

  // render

  loadJQuery() {
    $(".mobile-toggle").click(function () {
      $(".main-sidebar").toggleClass("sidebar-open");
      if ($(".mobile-toggle").find("i").hasClass("fa-bars")) {
        $(this).find("i").addClass("fa-times");
        $(this).find("i").removeClass("fa-bars");
      } else if ($(".mobile-toggle").find("i").hasClass("fa-times")) {
        $(this).find("i").addClass("fa-bars");
        $(this).find("i").removeClass("fa-times");
      }
    });
    $(".sidebar-navigation .nav-item .nav-link").click(function () {
      var sideOpen = $(".main-sidebar").hasClass("sidebar-open");
      if (sideOpen) {
        $(".mobile-toggle")
          .find("i")
          .addClass("fa-bars")
          .removeClass("fa-times");
      }
    });
  }

  // buttons action

  searchButtonClicked() {
    if (this.props.searchFootPrint) {
      this.props.searchFootPrint(this.state.searchQuery);
    }
  }

  // render

  render() {
    const { searchQuery } = this.props;

    let fullName = loggedInUserHelper.fullName();
    if (fullName === " ") {
      fullName = loggedInUserHelper.userName();
    }

    let searchString = this.state.searchQuery;

    if (searchQuery === "") {
      searchString = searchQuery;
    }

    return (
      <header className='app-header'>
        <nav className='main-header navbar navbar-expand navbar-white navbar-light'>
          <ul className='navbar-nav mobile-toggle d-none'>
            <li className='nav-item'>
              <a
                className='nav-link'
                data-widget='pushmenu'
                href='#menu'
                role='button'
              >
                <i className='fa fa-bars'></i>
              </a>
            </li>
          </ul>
          <div className='app-logo'>
            <Link to='/' onClick={() => this.logoClicked()}>
              <Logo />
            </Link>
          </div>
          <div className='search-plus-menu'>
            <div className='form-inline ml-1 app-search-field'>
              <div className='input-group'>
                <input
                  type='search'
                  className='form-control form-control-navbar'
                  placeholder='Search footprints'
                  aria-label='Search'
                  aria-describedby='basic-addon2'
                  name='searchQuery'
                  value={searchString}
                  onChange={this.handleInputChange}
                />
                <div
                  className='input-group-append'
                  onClick={() => this.searchButtonClicked()}
                >
                  <Link
                    to={{
                      pathname: `/search/${this.state.searchQuery}`,
                    }}
                    className='btn input-group-text btn-navbar'
                  >
                    <i className='fa fa-search'></i>{" "}
                  </Link>
                </div>
              </div>
            </div>
            {/* <ul className="nav navbar-nav app-options">
            <li className="dropdown open">
              <a
                href="#dropdown"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={UserImage} className="user-avatar" alt="User" />
                <span className="label-text"> {fullName} </span>
              </a>
              <ul className="dropdown-menu">
                <li key={utils.generateId()}>
                  <a
                    href="#signout"
                    className="sub-menu-text"
                    onClick={() => this.logoutButtonClicked()}
                  >
                    Log Out
                  </a>
                </li>
              </ul>
            </li>
          </ul> */}
          </div>
        </nav>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { userProfileUpdated, updatedUserProfile } = state.updateUserProfile;

  const { searchQuery } = state.searchFootPrint;

  return { userProfileUpdated, updatedUserProfile, searchQuery };
}

const actionCreators = {
  searchFootPrint: footPrintsActions.searchFootPrint,
};

export default connect(
  mapStateToProps,
  actionCreators,
)(withRouter(DashboardHeader));
