import $ from "jquery";

const jQueryHelper = {
  loadSideBarArrowButtonHideShowJQuery,
  loadDashboardJQuery,
};

/**
 *  User Token
 */

function loadSideBarArrowButtonHideShowJQuery() {
  // var rightSidebarWidth = $(".right-sidebar").width();
  // $(".sidebar-content").css("width", rightSidebarWidth);

  // $(window).on("resize", function () {
  //   var rightSidebarWidth = $(".right-sidebar").width();
  //   $(".sidebar-content").css("width", rightSidebarWidth);
  // });

  if ($(window).width() > 1024) {
    var windowHeight = $(window).height() - 100;
    $(".sidebar-content").css("height", windowHeight);
  }
}

function loadDashboardJQuery() {
  if ($(window).width() < 767) {
    $(".right-sidebar").insertBefore(".post-container");
  }
  $(window).on("resize", function () {
    if ($(window).width() < 767) {
      $(".right-sidebar").insertBefore(".post-container");
    } else {
      $(".right-sidebar").insertAfter(".post-container");
    }
  });

  if (window.location.href.indexOf("login") === -1) {
    $("body").addClass("user-logged-in");
  }
}

export default jQueryHelper;
