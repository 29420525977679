import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import authActions from "../redux/actions/auth-actions";
import authApi from "../apis/auth-api";
import Spinner from "../components/common/spinner";
import loggedInUserHelper from "../helpers/logged-in-user-helper";
import persistence from "../helpers/persistence";

class PasswordChangePage extends Component {
  constructor() {
    super();

    const email = loggedInUserHelper.email();

    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",

      changingPassword: false,
      passwordChanged: false,
      errorMessage: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // component lifecycle methods

  componentWillUnmount() {
    if (this.props.clearPasswordReset) {
      this.props.clearPasswordReset();
    }
  }

  // helper methods

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value, changingPassword: false });
  }

  refreshPage() {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  changePassword(oldPassword, newPassword, confirmNewPassword) {
    this.setState({
      changingPassword: true,
      errorMessage: "",
    });

    authApi.passwordChange(oldPassword, newPassword, confirmNewPassword).then(
      (response) => {
        if (response && response.status) {
          const username = loggedInUserHelper.userName();
          this.login(username, newPassword);
        } else {
          this.setState({
            changingPassword: false,
            passwordChanged: false,
            errorMessage: "Failed to change password",
          });
        }
      },
      (error) => {
        var errorString = "";

        if (error && error.old_password && error.old_password.length > 0) {
          error.old_password.forEach((error) => {
            errorString = errorString + "\n" + error;
          });
        }
        if (error && error.new_password && error.new_password.length > 0) {
          error.new_password.forEach((error) => {
            errorString = errorString + "\n" + error;
          });
        }
        if (errorString === "") {
          errorString = "Failed to change password";
        }
        this.setState({
          changingPassword: false,
          errorMessage: errorString,
          passwordChanged: false,
        });
      }
    );
  }

  login(username, newPassword) {
    authApi.login(username, newPassword).then(
      (user) => {
        if (user) {
          this.setState({
            changingPassword: false,
            errorMessage: "",
            passwordChanged: true,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // buttons action methods

  handleSubmit(e) {
    e.preventDefault();

    const { oldPassword, newPassword, confirmNewPassword } = this.state;

    if (!oldPassword) {
      this.setState({
        errorMessage: "Please enter password.",
      });
      return;
    }
    if (!newPassword) {
      this.setState({
        errorMessage: "Please enter your new password.",
      });
      return;
    }
    if (!confirmNewPassword) {
      this.setState({
        errorMessage: "Please confirm your new password.",
      });
      return;
    }
    if (newPassword !== confirmNewPassword) {
      this.setState({
        errorMessage: "New password and confirm new password must be same.",
      });
      return;
    }

    this.changePassword(oldPassword, newPassword, confirmNewPassword);
  }

  // render

  render() {
    const {
      oldPassword,
      newPassword,
      confirmNewPassword,
      changingPassword,
      passwordChanged,
      errorMessage,
    } = this.state;

    return (
      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-md-12">
            <div
              className="main-theme "
              style={{ paddingLeft: "15px", paddingRight: "15px" }}
            >
              <div className="forgot-pass-wrap card">
                {passwordChanged ? (
                  <div className="return-to-login">
                    <div className="alert alert-success" role="alert">
                      <i className="fa fa-check" aria-hidden="true"></i>
                      You have successfully changed your password.
                    </div>
                    <Link to="/">
                      <div
                        className="form-group action-button pt-4"
                        onClick={() => this.refreshPage()}
                      >
                        <button className="btn btn-success">
                          Return to home
                        </button>
                      </div>
                    </Link>
                  </div>
                ) : (
                  <div className="request-form">
                    <h1> Change Password </h1>
                    <form className="user-form" onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label className="user-input-label" htmlFor="email">
                          Password
                        </label>
                        <input
                          type="password"
                          className="form-control user-input-field"
                          name="oldPassword"
                          value={oldPassword}
                          placeholder="Enter password"
                          disabled={changingPassword}
                          onChange={this.handleChange}
                        />
                        <span className="field-icon">
                          <i className="fa fa-unlock" aria-hidden="true"></i>
                        </span>
                      </div>

                      <div className="form-group">
                        <label className="user-input-label" htmlFor="email">
                          New Password
                        </label>
                        <input
                          type="password"
                          className="form-control user-input-field"
                          name="newPassword"
                          value={newPassword}
                          placeholder="Enter new password"
                          disabled={changingPassword}
                          onChange={this.handleChange}
                        />
                        <span className="field-icon">
                          <i className="fa fa-key" aria-hidden="true"></i>
                        </span>
                      </div>

                      <div className="form-group">
                        <label className="user-input-label" htmlFor="email">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          className="form-control user-input-field"
                          name="confirmNewPassword"
                          value={confirmNewPassword}
                          placeholder="Enter password to confirm"
                          disabled={changingPassword}
                          onChange={this.handleChange}
                        />
                        <span className="field-icon">
                          <i className="fa fa-key" aria-hidden="true"></i>
                        </span>
                      </div>
                      {errorMessage ? (
                        <div className="form-error">
                          <p style={{ whiteSpace: "pre" }}>{errorMessage}</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-group action-button pt-4">
                        <button
                          className="btn btn-success"
                          disabled={changingPassword}
                        >
                          Change Password
                        </button>
                        {changingPassword ? <Spinner /> : ""}
                      </div>

                      <div>
                        {/* <h6>Note: </h6> */}
                        <p style={{ fontSize: "13px" }}>
                          {" "}
                          * New password should be alpha numeric and must be 8
                          characters long.{" "}
                        </p>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    passwordResetInProgress,
    passwordResetLinkSent,
    passwordResetFailedMessage,
  } = state.passwordReset;

  return {
    passwordResetInProgress,
    passwordResetLinkSent,
    passwordResetFailedMessage,
  };
}

const actionCreators = {
  passwordReset: authActions.passwordReset,
  clearPasswordReset: authActions.clearPasswordReset,
};

export default connect(mapStateToProps, actionCreators)(PasswordChangePage);
