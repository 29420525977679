import React, { Component } from "react";
import { connect } from "react-redux";
import userActions from "../../redux/actions/user-actions";
import jQueryHelper from "../../helpers/jquery-helper";
import FootPrintsListView from "../../components/common/footprints-list-view";
import footPrintsApi from "../../apis/footprints-api";
import CreateFootprintModalView from "../../components/common/create-footprint-modal-view";
import CreateStudentFootprintModalView from "../../components/common/create-student-footprint-modal-view";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";

class FootPrintPage extends Component {
  constructor() {
    super();

    this.state = {
      fetchingCurrentUserContributors: null,
      currentUserContributorsFetched: null,
      currentUserContributors: null,
      failedToFetchCurrentUserContributors: null,

      allClasses: [],
      allGroups: [],
      children: [],

      fetchingFootPrint: null,
      footPrintFetched: null,
      footPrint: null,
      failedToFetchFootPrint: null,

      footprintId: 0,

      isDeletingFootPrint: false,
      deletingFootPrintId: 0,

      footPrintToUpdate: null,
    };
  }

  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingCurrentUserContributors,
      currentUserContributorsFetched,
      currentUserContributors,
      failedToFetchCurrentUserContributors,
    } = nextProps;

    let allClasses = prevState.allClasses;
    let allGroups = prevState.allGroups;
    let children = prevState.children;

    if (currentUserContributors) {
      if (
        currentUserContributors.school_classes &&
        currentUserContributors.school_classes.length > 0
      ) {
        allClasses = currentUserContributors.school_classes;
        allClasses = FootPrintPage.mappedClassesOnCustomObject(allClasses);
      } else if (
        currentUserContributors.church_classes &&
        currentUserContributors.church_classes.length > 0
      ) {
        allGroups = currentUserContributors.church_classes;
        allGroups = FootPrintPage.mappedGroupsOnCustomObject(allGroups);
      } else if (currentUserContributors && currentUserContributors.children) {
        children = currentUserContributors.children;
      }
    }

    return {
      fetchingCurrentUserContributors: fetchingCurrentUserContributors,
      currentUserContributorsFetched: currentUserContributorsFetched,
      currentUserContributors: currentUserContributors,
      failedToFetchCurrentUserContributors:
        failedToFetchCurrentUserContributors,

      allClasses: allClasses,
      allGroups: allGroups,
      children: children,
    };
  }

  componentDidMount() {
    this.loadJQuery();
    this.fetchCurrentUserContributors();
    this.fetch();
  }

  // helper methods

  loadJQuery() {
    jQueryHelper.loadSideBarArrowButtonHideShowJQuery();
    jQueryHelper.loadDashboardJQuery();
  }

  static mappedGroupsOnCustomObject(groups) {
    const mappedGroupsObjects = groups.map((groupObj) => {
      const group_id = groupObj.id;
      const group_name = groupObj.class_name;
      const students = groupObj.church_students;

      return {
        id: group_id,
        name: group_name,
        isChecked: false,
        students: students,
      };
    });
    return mappedGroupsObjects;
  }

  static mappedClassesOnCustomObject(classes) {
    const mappedClassesObjects = classes.map((classObj) => {
      const class_id = classObj.id;
      const class_name = classObj.class_name;
      const students = classObj.school_students;

      return {
        id: class_id,
        name: class_name,
        isChecked: false,
        students: students,
      };
    });
    return mappedClassesObjects;
  }

  fetchCurrentUserContributors() {
    if (this.props.fetchCurrentUserContributors) {
      this.props.fetchCurrentUserContributors();
    }
  }

  fetch() {
    const { match } = this.props;

    let footprintId = 0;

    if (match && match.params && match.params.footprintId) {
      footprintId = parseInt(match.params.footprintId, 10);

      if (footprintId !== this.state.footprintId) {
        this.fetchFootPrint(footprintId);
      }
    }
  }

  fetchFootPrint(footprintId) {
    this.setState({
      fetchingFootPrint: true,
      footprintId: footprintId,
    });

    footPrintsApi.fetchFootPrint(footprintId).then(
      (response) => {
        this.setState({
          fetchingFootPrint: false,
          footPrintFetched: true,
          footPrint: response,
          failedToFetchFootPrint: false,
        });
      },
      (error) => {
        this.setState({
          fetchingFootPrint: false,
          footPrintFetched: false,
          failedToFetchFootPrint: true,
        });
      },
    );
  }

  footPrintUpdated(footPrint) {
    this.setState({
      footPrintToUpdate: null,
      footPrint: {},
    });

    setTimeout(() => {
      this.setState({
        footPrintToUpdate: null,
        footPrint: footPrint,
      });
    }, 100);
  }

  userDidCancelToUpdateFootPrint() {
    this.setState({
      footPrintToUpdate: null,
    });
  }

  // buttons action methods

  editFootPrintClicked(footPrint) {
    if (footPrint.id) {
      this.setState({
        footPrintToUpdate: footPrint,
      });
    }
  }

  deleteFootPrintClicked(footPrint) {
    if (footPrint.id) {
      this.setState({
        isDeletingFootPrint: true,
        deletingFootPrintId: footPrint.id,
      });
      footPrintsApi.deleteFootPrint(footPrint.id).then(
        (status) => {
          this.setState({
            footPrint: null,
            isDeletingFootPrint: false,
            deletingFootPrintId: 0,
          });
        },
        (error) => {
          this.setState({
            isDeletingFootPrint: false,
            deletingFootPrintId: 0,
          });
        },
      );
    }
  }

  // render

  render() {
    const {
      fetchingFootPrint,
      footPrintFetched,
      footPrint,
      failedToFetchFootPrint,

      isDeletingFootPrint,
      deletingFootPrintId,

      allClasses,
      allGroups,
      children,

      footPrintToUpdate,
    } = this.state;

    let footPrints = [];
    if (footPrints.length === 0 && footPrint) {
      footPrints.push(footPrint);
    }

    const isStudent = loggedInUserHelper.isStudent();
    const isParent = loggedInUserHelper.isParents();
    const isPastor = loggedInUserHelper.isPastor();
    const isChurchPrincipal = loggedInUserHelper.isChurchPrincipal();
    const isTeacher = loggedInUserHelper.isTeacher();
    const isSchoolPrincipal = loggedInUserHelper.isSchoolPrincipal();

    return (
      <div className='content'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-md-7 col-lg-7 col-xl-8'>
              <FootPrintsListView
                fetchingFootPrints={fetchingFootPrint}
                isLoadingMore={false}
                footPrintsFetched={footPrintFetched}
                footPrints={footPrints}
                failedToFetchFootPrints={failedToFetchFootPrint}
                currentPage={1}
                isDeletingFootPrint={isDeletingFootPrint}
                deletingFootPrintId={deletingFootPrintId}
                onEdit={(footPrint) => this.editFootPrintClicked(footPrint)}
                onDelete={(footPrint) => this.deleteFootPrintClicked(footPrint)}
              />
              {footPrintToUpdate ? (
                isStudent ? (
                  <CreateStudentFootprintModalView
                    onFootPrintUpdated={(footPrint) =>
                      this.footPrintUpdated(footPrint)
                    }
                    footPrintToUpdate={footPrintToUpdate}
                    onClose={() => this.userDidCancelToUpdateFootPrint()}
                  />
                ) : (
                  <CreateFootprintModalView
                    classesGroups={
                      isTeacher || isSchoolPrincipal
                        ? allClasses
                        : isPastor || isChurchPrincipal
                        ? allGroups
                        : null
                    }
                    children={isParent ? children : null}
                    onFootPrintUpdated={(footPrint) =>
                      this.footPrintUpdated(footPrint)
                    }
                    footPrintToUpdate={footPrintToUpdate}
                    onClose={() => this.userDidCancelToUpdateFootPrint()}
                  />
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,
  } = state.fetchCurrentUserContributors;

  return {
    fetchingCurrentUserContributors,
    currentUserContributorsFetched,
    currentUserContributors,
    failedToFetchCurrentUserContributors,
  };
}

const actionCreators = {
  fetchCurrentUserContributors: userActions.fetchCurrentUserContributors,
};

export default connect(mapStateToProps, actionCreators)(FootPrintPage);
