import authConstants from "../constants/auth-constants";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";

let user = loggedInUserHelper.user();
const initialState = user ? { loggedIn: true, user } : {};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        loginResponseMessage: "Login successfully",
      };
    case authConstants.LOGIN_FAILURE:
      return {
        loginResponseMessage: action.error,
      };
    case authConstants.LOGOUT:
      return {};
    case authConstants.LOGIN_CLEAR:
      return initialState;
    default:
      return state;
  }
}
