import persistence from "./persistence";
import mixConstants from "../constants/mix-constants";

const loggedInUserHelper = {
  isLoggedIn,
  user,
  id,
  token,
  profilePicUrl,
  profileBackgroundColor,
  firstName,
  lastName,
  fullName,
  userName,
  email,
  isActiveUser,
  role,
  isFaithAdmin,
  isSchoolAdmin,
  isChurchAdmin,
  isSchoolPrincipal,
  isChurchPrincipal,
  isPastor,
  isParents,
  isTeacher,
  isStudent,
};

/**
 *  Get user login status
 */

function isLoggedIn() {
  let token = persistence.getUserToken();

  if (token) {
    return true;
  }
  return false;
}

/**
 *  Get current user
 */

function user() {
  let currentUser = persistence.getUserData();

  if (currentUser) {
    return currentUser;
  }
  return null;
}

/**
 *  Get current user id
 */

function id() {
  let currentUser = this.user();

  if (currentUser && currentUser.id) {
    return currentUser.id;
  }
  return null;
}

/**
 *  Get current user token
 */

function token() {
  let token = persistence.getUserToken();

  return token;
}

/**
 *  Get user profile pic
 */

function profilePicUrl() {
  let currentUser = this.user();

  if (currentUser && currentUser.profile) {
    let profile = currentUser.profile;

    if (profile.profile_picture) {
      return profile.profile_picture;
    }
  }
  return null;
}

/**
 *  Get user profile pic
 */

function profileBackgroundColor() {
  let currentUser = this.user();

  if (currentUser && currentUser.profile && currentUser.profile.bg_color) {
    let color = currentUser.profile.bg_color;

    return color;
  }
  return this.isStudent() ? "229DA5" : "196F50";
}

/**
 *  Get current user first name
 */

function firstName() {
  let currentUser = this.user();

  if (currentUser && currentUser.first_name) {
    return currentUser.first_name;
  }
  return "";
}

/**
 *  Get current user last name
 */

function lastName() {
  let currentUser = this.user();

  if (currentUser && currentUser.last_name) {
    return currentUser.last_name;
  }
  return "";
}

/**
 *  Get current user full name
 */

function fullName() {
  let fName = this.firstName();
  let lName = this.lastName();

  return fName + " " + lName;
}

/**
 *  Get current username
 */

function userName() {
  let currentUser = this.user();

  if (currentUser && currentUser.username) {
    return currentUser.username;
  }
  return "";
}

/**
 *  Get current email
 */

function email() {
  let currentUser = this.user();

  if (currentUser && currentUser.email) {
    return currentUser.email;
  }
  return "";
}

/**
 *  Is active user
 */

function isActiveUser() {
  let currentUser = this.user();

  if (currentUser && currentUser.is_active) {
    return currentUser.is_active;
  }
  return false;
}

/**
 *  User role
 */

function role() {
  let currentUser = this.user();

  if (currentUser && currentUser.role && currentUser.role.length > 0) {
    const currentUserRole = currentUser.role[0];
    return currentUserRole;
  }
  return null;
}

/**
 *  Is user Faith Admin
 */

function isFaithAdmin() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.FAITH_ADMIN
  );
}

/**
 *  Is user School Admin
 */

function isSchoolAdmin() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.SCHOOL_ADMIN
  );
}

/**
 *  Is user Church Admin
 */

function isChurchAdmin() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.CHURCH_ADMIN
  );
}

/**
 *  Is user school principal
 */

function isSchoolPrincipal() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.SCHOOL_PRINCIPAL
  );
}

/**
 *  Is user church principal
 */

function isChurchPrincipal() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.CHURCH_PRINCIPAL
  );
}

/**
 *  Is user Pastor
 */

function isPastor() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.PASTOR
  );
}

/**
 *  Is user Parents
 */

function isParents() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.PARENTS
  );
}

/**
 *  Is user Teacher
 */

function isTeacher() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.TEACHER
  );
}

/**
 *  Is user Student
 */

function isStudent() {
  let currentUserRole = this.role();

  return (
    currentUserRole &&
    currentUserRole.role_name.toLowerCase() ===
      mixConstants.usersRoleName.STUDENT
  );
}

export default loggedInUserHelper;
