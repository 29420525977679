import React, { Component } from "react";
import LoaderView from "../common/loader-view";
import Fade from "@material-ui/core/Fade";
import mixConstants from "../../constants/mix-constants";

class PastorGroupsCheckboxListView extends Component {
  constructor() {
    super();

    this.state = {
      fetchingContributors: null,
      contributorsFetched: null,
      contributors: null,
      failedToFetchContributors: null,

      groupsList: [],
    };
  }

  // component lifecycle methods

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
      allGroups,
    } = nextProps;

    let groupsList = prevState.groupsList;

    if (contributorsFetched && allGroups && prevState.groupsList.length === 0) {
      groupsList = allGroups;
    }

    return {
      fetchingContributors: fetchingContributors,
      contributorsFetched: contributorsFetched,
      contributors: contributors,
      failedToFetchContributors: failedToFetchContributors,

      groupsList: groupsList,
    };
  }

  // helper methods

  handleAllChecked = (event) => {
    let groupsList = this.state.groupsList;
    groupsList.forEach(
      (groupObj) => (groupObj.isChecked = event.target.checked),
    );
    this.setState({ groupsList: groupsList });
  };

  classCheckboxSelectionDidChange = (i) => (event) => {
    this.setState((state, props) => {
      state.groupsList[i].isChecked = !state.groupsList[i].isChecked;
      return {
        groupsList: state.groupsList,
      };
    });
  };

  isAllChecked() {
    let selectedGroups = this.getSelectedGroups();
    if (selectedGroups.length > 0) {
      return selectedGroups.length === this.state.groupsList.length;
    }
    return false;
  }

  getSelectedGroups() {
    if (this.state.groupsList) {
      let selectedGroups = this.state.groupsList.filter((groupObj) => {
        return groupObj.isChecked === true;
      });
      return selectedGroups;
    }
    return [];
  }

  renderGroups(groups) {
    if (groups.length > 0) {
      const mappedGroups = groups.map((groupObj, i) => {
        const group_id = groupObj.id;
        const group_name = groupObj.name;
        const isChecked = groupObj.isChecked;
        const cssClass = isChecked
          ? "custom-checkbox active-checkbox"
          : "custom-checkbox";

        return (
          <Fade
            in={true}
            timeout={mixConstants.constantValues.TRANSITION_DURATION}
          >
            <li key={group_id}>
              <label className={cssClass}>
                <input
                  type='checkbox'
                  value={group_name}
                  checked={isChecked}
                  onChange={this.classCheckboxSelectionDidChange(i)}
                />
                <span className='checkmark'></span>
                {group_name}
              </label>
            </li>
          </Fade>
        );
      });
      return mappedGroups;
    } else {
      return <div> No groups found </div>;
    }
  }

  // buttons action methods

  applyFilterButtonClicked() {
    if (this.props.onApplyFilter) {
      const selectedGroups = this.getSelectedGroups();
      this.props.onApplyFilter(selectedGroups);
    }
  }

  // render

  render() {
    const {
      fetchingContributors,
      contributorsFetched,
      contributors,
      failedToFetchContributors,
      groupsList,
    } = this.state;

    return (
      <div className='radio-container'>
        <div className='container-title'>
          <label className='custom-checkbox'>
            <input
              type='checkbox'
              onClick={this.handleAllChecked}
              value='checkedAll'
              checked={this.isAllChecked()}
            />
            <span className='checkmark'></span>
            Groups
          </label>
          {groupsList && groupsList.length > 0 ? (
            <button
              className='btn blue-button btn-shadow pull-right btn-md btn-mini'
              type='button'
              onClick={() => this.applyFilterButtonClicked()}
            >
              Apply
            </button>
          ) : (
            ""
          )}
        </div>
        <ul className='radio-container-list'>
          {fetchingContributors ? (
            <div className='loader-new-post'>
              <LoaderView width={50} height={50} />
            </div>
          ) : contributors && contributorsFetched ? (
            this.renderGroups(groupsList ? groupsList : [])
          ) : failedToFetchContributors ? (
            "Failed to fetch groups"
          ) : (
            ""
          )}
        </ul>
      </div>
    );
  }
}

export default PastorGroupsCheckboxListView;
