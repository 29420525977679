import $ from "jquery";
import Moment from "moment";
import "swiper/swiper.min.css";
import Spinner from "./spinner";
import "swiper/swiper-bundle.min.css";
import utils from "../../helpers/utils";
import React, { Component } from "react";
import Fade from "@material-ui/core/Fade";
import FootPrintMenu from "./footprint-menu";
import ShowMoreText from "react-show-more-text";
import { Swiper, SwiperSlide } from "swiper/react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import mixConstants from "../../constants/mix-constants";
import FootprintMediaCarousel from "./footprint-media-carousel";
import loggedInUserHelper from "../../helpers/logged-in-user-helper";
import FootprintReflectionsSection from "./footprint-reflections-section";
import NameInitialsAvatar from "../../components/common/name-initials-avatar";

class FootPrintCard extends Component {
  constructor() {
    super();

    this.state = {
      mobileItem: 12,
      isDragable: false,

      footPrint: {},
      footPrintCreatorProfilePicture: "",
      footPrintCreatorName: "",
      footPrintDescription: "",
      footPrintDescriptionToShow: "",
      footPrintTags: [],
      footPrintCreatedAt: "",
      footPrintCreatorId: "",

      students: [],
      selectedStudent: null,
      selectedStudentFootPrints: [],

      update: false,
    };

    this.handleResize = this.handleResize.bind(this);
    window.addEventListener("resize", this.handleResize);
  }

  //component lifecycle methods

  componentDidMount() {
    this.handleResize();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { footPrint } = nextProps;

    let footPrintCreatorProfilePicture =
      prevState.footPrintCreatorProfilePicture;
    let footPrintCreatorId = prevState.footPrintCreatorId;
    let footPrintCreatorName = prevState.footPrintCreatorName;
    let footPrintDescription = prevState.footPrintDescription;
    let footPrintTags = prevState.footPrintTags;
    let footPrintCreatedAt = prevState.footPrintCreatedAt;
    let students = prevState.students;
    let selectedStudent = prevState.selectedStudent;
    let selectedStudentFootPrints = prevState.selectedStudentFootPrints;

    if (footPrint.created_by_profile_picture) {
      footPrintCreatorProfilePicture = footPrint.created_by_profile_picture;
      //apiConfig.BaseURL + footPrint.created_by_profile_picture;
    }
    if (footPrint.created_by) {
      footPrintCreatorId = footPrint.created_by;
    }
    if (footPrint.created_by_name) {
      footPrintCreatorName = footPrint.created_by_name;
    }
    if (footPrint.description) {
      footPrintDescription = footPrint.description;
    }
    if (footPrint.tags) {
      footPrintTags = footPrint.tags;
    }
    if (footPrint.created_at) {
      footPrintCreatedAt = Moment(footPrint.created_at).format("LL");
    }
    if (footPrint.students) {
      students = footPrint.students;
    }
    if (prevState.selectedStudent === null && students.length > 0) {
      selectedStudent = students[0];
    }
    if (selectedStudent && selectedStudent.footprints) {
      selectedStudentFootPrints = selectedStudent.footprints;
    }

    return {
      footPrint: footPrint,
      footPrintCreatorId: footPrintCreatorId,
      footPrintCreatorName: footPrintCreatorName,
      footPrintDescription: footPrintDescription,
      footPrintTags: footPrintTags,
      footPrintCreatedAt: footPrintCreatedAt,
      students: students,
      selectedStudent: selectedStudent,
      selectedStudentFootPrints: selectedStudentFootPrints,
      footPrintCreatorProfilePicture: footPrintCreatorProfilePicture,
    };
  }

  // helper methods

  renderTags(tags) {
    if (tags && tags.length > 0) {
      return tags.map((tag) => {
        return (
          <span className='tag' key={utils.generateId()}>
            {tag.startsWith("#") ? "" : "#"}
            {tag}
          </span>
        );
      });
    }
    return "";
  }

  selectStudent(student) {
    if (
      student &&
      this.state.selectedStudent &&
      student.student_id &&
      this.state.selectedStudent.student_id &&
      student.student_id !== this.state.selectedStudent.student_id
    ) {
      this.setState({
        selectedStudent: student,
      });
      setTimeout(() => {
        this.setState({
          update: !this.state.update,
        });
      }, 10);
    }
  }

  handleResize() {
    var winWidth = $(window).width();

    var mobileItem = this.state.mobileItem;
    if (winWidth > 1900) {
      mobileItem = 12;
    } else if (winWidth < 1900 && winWidth > 1350) {
      mobileItem = 12;
    } else if (winWidth < 1350 && winWidth > 1202) {
      mobileItem = 11;
    } else if (winWidth < 1202 && winWidth > 786) {
      mobileItem = 8;
    } else if (winWidth < 786 && winWidth > 767) {
      mobileItem = 7;
    } else if (winWidth < 767 && winWidth > 600) {
      mobileItem = 10;
    } else if (winWidth < 600 && winWidth > 500) {
      mobileItem = 8;
    } else if (winWidth < 500 && winWidth > 418) {
      mobileItem = 6;
    } else if (winWidth < 418 && winWidth > 350) {
      mobileItem = 5;
    } else {
      mobileItem = 4;
    }
    this.setState({
      mobileItem: mobileItem,
      isDragable: this.state.students.length > mobileItem,
    });
  }

  // buttons action methods

  editFootPrintClicked(footPrint) {
    if (this.props.onEdit) {
      this.props.onEdit(footPrint);
    }
  }

  deleteFootPrintClicked(footPrint) {
    if (this.props.onDelete) {
      this.props.onDelete(footPrint);
    }
  }

  // render

  render() {
    const {
      footPrint,
      footPrintCreatorId,
      footPrintCreatorProfilePicture,
      footPrintCreatorName,
      footPrintDescription,
      footPrintTags,
      footPrintCreatedAt,
      students,

      selectedStudent,
      selectedStudentFootPrints,
    } = this.state;

    const { isDeletingFootPrint, deletingFootPrintId } = this.props;

    const isCurrentUserCreatorOfThisFootPrint =
      loggedInUserHelper.id() === footPrintCreatorId;

    return (
      <Fade in={true} timeout={mixConstants.constantValues.TRANSITION_DURATION}>
        <div className='post-grid'>
          <div className='card'>
            <div className='card-header post-container-head'>
              {footPrintCreatorProfilePicture !== "" ? (
                <img
                  src={footPrintCreatorProfilePicture}
                  className='user-avatar'
                  alt='profile-pic'
                />
              ) : (
                <NameInitialsAvatar
                  name={footPrintCreatorName}
                  backgroundColor={
                    footPrint && footPrint.created_by_bg_color
                      ? footPrint.created_by_bg_color
                      : utils.userAvatarBackgroundColor()
                  }
                />
              )}
              <span className='label-text'> {footPrintCreatorName} </span>
              <div className='footprint-menu-rgt'>
                {isCurrentUserCreatorOfThisFootPrint ? (
                  isDeletingFootPrint &&
                  deletingFootPrintId &&
                  deletingFootPrintId === footPrint.id ? (
                    <div className='right-spinner'>
                      <Spinner />
                    </div>
                  ) : (
                    <FootPrintMenu
                      footPrint={footPrint}
                      onEdit={(footPrint) =>
                        this.editFootPrintClicked(footPrint)
                      }
                      onDelete={(footPrint) =>
                        this.deleteFootPrintClicked(footPrint)
                      }
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className='card-body footprint-card-body'>
              <span className='post-date'>
                {" "}
                {footPrintCreatedAt ? "on " + footPrintCreatedAt : ""}{" "}
              </span>
              <div className='post-description'>
                <div className='paragraph' style={{ whiteSpace: "pre-line" }}>
                  <ShowMoreText
                    lines={3}
                    more='Show more'
                    less='Show less'
                    anchorClass=''
                    expanded={false}
                    keepNewLines={true}
                  >
                    {footPrintDescription}
                  </ShowMoreText>
                </div>
                {this.renderTags(footPrintTags)}
              </div>

              {!loggedInUserHelper.isStudent() ? (
                <Swiper
                  spaceBetween={15}
                  slidesPerView={12}
                  breakpoints={{
                    320: {
                      slidesPerView: 8,
                      spaceBetween: 6,
                    },
                    640: {
                      slidesPerView: 6,
                      spaceBetween: 9,
                    },
                    768: {
                      slidesPerView: 8,
                      spaceBetween: 12,
                    },

                    1200: {
                      slidesPerView: 12,
                      spaceBetween: 15,
                    },
                  }}
                >
                  {students &&
                    students.map((student) => {
                      const profilePic = student.profile_picture;
                      const fullName = student.fullname;
                      let isSelected = false;
                      let cssClass = "item";

                      if (this.state.selectedStudent) {
                        isSelected =
                          student.student_id ===
                          this.state.selectedStudent.student_id;
                      }
                      if (isSelected) {
                        cssClass = "active item";
                      }

                      return (
                        <SwiperSlide
                          key={student.student_id}
                          onClick={() => this.selectStudent(student)}
                        >
                          <div className={cssClass}>
                            {profilePic ? (
                              <div className='user_img_container'>
                                <img
                                  className='user-img'
                                  alt='pic'
                                  src={profilePic}
                                />
                              </div>
                            ) : (
                              <NameInitialsAvatar
                                name={fullName}
                                backgroundColor={
                                  student.bg_color
                                    ? student.bg_color
                                    : utils.userAvatarBackgroundColor()
                                }
                              />
                            )}
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              ) : (
                ""
              )}

              {selectedStudentFootPrints.length > 0 ? (
                <Fade in={true}>
                  <div
                    style={{ marginLeft: "-1.35rem", marginRight: "-1.35rem" }}
                  >
                    <div className='post-image'>
                      <FootprintMediaCarousel
                        footPrints={selectedStudentFootPrints}
                        student={selectedStudent}
                      />
                    </div>
                  </div>
                </Fade>
              ) : (
                ""
              )}
            </div>

            <div className='card-footer'>
              <FootprintReflectionsSection
                footPrint={footPrint}
                student={selectedStudent}
              />
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default FootPrintCard;
